<template>
  <div
    class="ds-notification"
    :class="{
      'tw-items-center': !$slots['supporting-text'],
      'tw-w-full': full,
    }"
    :data-testid="testId"
    role="alert"
    @click.stop
  >
    <div v-if="$slots.icon || icon" class="ds-notification-icon">
      <slot name="icon">
        <DsSvgIcon theme="halo-light" :color="type" :icon="icon" />
      </slot>
    </div>
    <section class="ds-notification-body">
      <h2 v-if="$slots.text" class="tw-ds-text-sm--semibold tw-text-secondary-dark">
        <slot name="text" />
      </h2>
      <p
        v-if="$slots['supporting-text']"
        class="tw-mt-1 tw-ds-text-sm tw-text-cool-grey-600 tw-whitespace-pre-line"
      >
        <slot name="supporting-text" />
      </p>
      <footer v-if="$slots.actions" class="ds-notification-actions">
        <slot name="actions" />
      </footer>
    </section>
    <CloseButton
      v-if="displayCloseButton"
      class="ds-notification-close-button"
      color="grey"
      size="sm"
      @click="emit('close-request')"
    />
  </div>
</template>

<script setup lang="ts">
import { SvgIconType } from '@/assets/generated/svgIcons/_types';

defineProps({
  icon: {
    type: String as PropType<SvgIconType>,
    default: '',
  },
  type: {
    type: String as PropType<'blue' | 'success' | 'warning' | 'attention'>,
    default: 'success',
  },
  displayCloseButton: {
    type: Boolean,
    default: true,
  },
  full: {
    type: Boolean,
    default: false,
  },
  testId: {
    type: String,
    default: '',
  },
});

const emit = defineEmits<{
  (ev: 'close-request'): void;
}>();
</script>

<style scoped>
.ds-notification {
  @apply tw-flex tw-items-start tw-gap-4 tw-w-[400px];
  @apply tw-rounded-sm tw-border tw-border-solid tw-border-secondary-light tw-p-4 tw-bg-white;
}

.ds-notification-icon {
  @apply tw-flex tw-items-center -tw-mt-1 -tw-mb-1 -tw-ml-2 -tw-mr-2;
}

.ds-notification-body {
  @apply tw-flex tw-flex-col tw-grow;
}

.ds-notification-actions {
  @apply tw-flex tw-gap-3 tw-mt-2.5;
}

.ds-notification-close-button {
  @apply tw-flex-none -tw-mt-2 -tw-mr-2;
}
</style>
