import { OverlayPosition } from './overlay';

export const OVERLAY_MARGIN = 4;
export const MAX_BOUNDS = OVERLAY_MARGIN;
const FPS = 60;
const FRAMES_TO_APPEAR = 4;
export const OVERLAY_APPEARANCE_TIMEOUT = (1000 / FPS) * FRAMES_TO_APPEAR;
const OVERLAY_BOTTOM_MIN_HEIGHT = 320;

function overlayWouldOverlapBottomEdge(topReference: number, overlayRect: DOMRect | Rect) {
  return topReference + overlayRect.height > window.innerHeight; // tooltip does not start below but right, check to adapt this formula with a prop accordingly
}

function hasLessThanMinHeight(topReference: number, minHeight: number) {
  return window.innerHeight - topReference < minHeight;
}

export interface Rect {
  left: number;
  top: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
}

function getTopPositionTopAndBottom(triggerRect: DOMRect | Rect, overlayRect: DOMRect | Rect) {
  const overlayOverflowsTrigger = MAX_BOUNDS + overlayRect.height > triggerRect.top;
  const bottom = overlayOverflowsTrigger
    ? window.innerHeight - triggerRect.top + OVERLAY_MARGIN
    : undefined;

  return {
    top: triggerRect.top - overlayRect.height - OVERLAY_MARGIN,
    bottom,
  };
}

function getPositionTop(triggerRect: DOMRect | Rect, overlayRect: DOMRect | Rect) {
  return {
    left: triggerRect.left + (triggerRect.width - overlayRect.width) / 2,
    ...getTopPositionTopAndBottom(triggerRect, overlayRect),
  };
}

function getPositionTopRight(triggerRect: DOMRect | Rect, overlayRect: DOMRect | Rect) {
  return {
    left: triggerRect.right - overlayRect.width,
    ...getTopPositionTopAndBottom(triggerRect, overlayRect),
  };
}

function getPositionTopLeft(triggerRect: DOMRect | Rect, overlayRect: DOMRect | Rect) {
  return {
    left: triggerRect.left,
    ...getTopPositionTopAndBottom(triggerRect, overlayRect),
  };
}

function getPositionTopContain(triggerRect: DOMRect | Rect, overlayRect: DOMRect | Rect) {
  return {
    left: triggerRect.left,
    ...getTopPositionTopAndBottom(triggerRect, overlayRect),
    width: triggerRect.width,
  };
}

function getPositionBottom(
  triggerRect: DOMRect | Rect,
  overlayRect: DOMRect | Rect,
  minHeight: number = OVERLAY_BOTTOM_MIN_HEIGHT,
) {
  const desiredTop = triggerRect.bottom + OVERLAY_MARGIN;
  const getPosition = () => ({
    left: triggerRect.left + (triggerRect.width - overlayRect.width) / 2,
    top: triggerRect.bottom + OVERLAY_MARGIN,
    bottom: undefined,
  });
  if (!overlayWouldOverlapBottomEdge(desiredTop, overlayRect)) {
    return getPosition();
  } else if (hasLessThanMinHeight(triggerRect.top, minHeight)) {
    return getPositionTop(triggerRect, overlayRect);
  } else {
    return { ...getPosition(), bottom: OVERLAY_MARGIN };
  }
}

function getPositionBottomRight(
  triggerRect: DOMRect | Rect,
  overlayRect: DOMRect | Rect,
  minHeight: number = OVERLAY_BOTTOM_MIN_HEIGHT,
) {
  const desiredTop = triggerRect.bottom + OVERLAY_MARGIN;
  const getPosition = () => ({
    left: triggerRect.right - overlayRect.width,
    top: triggerRect.bottom + OVERLAY_MARGIN,
    bottom: undefined,
  });
  if (!overlayWouldOverlapBottomEdge(desiredTop, overlayRect)) {
    return getPosition();
  } else if (hasLessThanMinHeight(triggerRect.top, minHeight)) {
    return getPositionTopRight(triggerRect, overlayRect);
  } else {
    return { ...getPosition(), bottom: OVERLAY_MARGIN };
  }
}

function getPositionBottomLeft(
  triggerRect: DOMRect | Rect,
  overlayRect: DOMRect | Rect,
  minHeight: number = OVERLAY_BOTTOM_MIN_HEIGHT,
) {
  const desiredTop = triggerRect.bottom + OVERLAY_MARGIN;
  const getPosition = () => ({
    left: triggerRect.left,
    top: triggerRect.bottom + OVERLAY_MARGIN,
    bottom: undefined,
  });
  if (!overlayWouldOverlapBottomEdge(desiredTop, overlayRect)) {
    return getPosition();
  } else if (hasLessThanMinHeight(triggerRect.top, minHeight)) {
    return getPositionTopLeft(triggerRect, overlayRect);
  } else {
    return { ...getPosition(), bottom: OVERLAY_MARGIN };
  }
}

function getPositionBottomContain(
  triggerRect: DOMRect | Rect,
  overlayRect: DOMRect | Rect,
  minHeight: number = OVERLAY_BOTTOM_MIN_HEIGHT,
) {
  const desiredTop = triggerRect.bottom + OVERLAY_MARGIN;
  const getPosition = () => ({
    left: triggerRect.left,
    top: triggerRect.bottom + OVERLAY_MARGIN,
    bottom: undefined,
    width: triggerRect.width,
  });
  if (!overlayWouldOverlapBottomEdge(desiredTop, overlayRect)) {
    return getPosition();
  } else if (hasLessThanMinHeight(triggerRect.top, minHeight)) {
    return getPositionTopContain(triggerRect, overlayRect);
  } else {
    return { ...getPosition(), bottom: OVERLAY_MARGIN };
  }
}

function getPositionLeft(triggerRect: DOMRect | Rect, overlayRect: DOMRect | Rect) {
  let top: number | undefined;
  let bottom: number | undefined;

  const left = triggerRect.left - overlayRect.width - OVERLAY_MARGIN;
  const desiredTop = triggerRect.top + (triggerRect.height - overlayRect.height) / 2;
  if (overlayWouldOverlapBottomEdge(desiredTop, overlayRect)) {
    top = window.innerHeight - overlayRect.height - OVERLAY_MARGIN;
    bottom = OVERLAY_MARGIN;
  } else {
    top = desiredTop;
  }

  return {
    left,
    top,
    bottom,
  };
}

function getPositionRight(triggerRect: DOMRect | Rect, overlayRect: DOMRect | Rect) {
  let top: number | undefined;
  let bottom: number | undefined;

  const left = triggerRect.right + OVERLAY_MARGIN;
  const desiredTop = triggerRect.top + (triggerRect.height - overlayRect.height) / 2;
  if (overlayWouldOverlapBottomEdge(desiredTop, overlayRect)) {
    top = window.innerHeight - overlayRect.height - OVERLAY_MARGIN;
    bottom = OVERLAY_MARGIN;
  } else {
    top = desiredTop;
  }
  return {
    left,
    top,
    bottom,
  };
}

function getPositionUpperEdgeLeft(triggerRect: DOMRect | Rect, overlayRect: DOMRect | Rect) {
  let top: number | undefined;
  let bottom: number | undefined;

  const left = triggerRect.left - overlayRect.width - OVERLAY_MARGIN;
  const desiredTop = triggerRect.top;
  if (overlayWouldOverlapBottomEdge(desiredTop, overlayRect)) {
    top = window.innerHeight - overlayRect.height - OVERLAY_MARGIN;
    bottom = OVERLAY_MARGIN;
  } else {
    top = desiredTop;
  }
  return {
    left,
    top,
    bottom,
  };
}

function getPositionUpperEdgeRight(triggerRect: DOMRect | Rect, overlayRect: DOMRect | Rect) {
  let top: number | undefined;
  let bottom: number | undefined;

  const left = triggerRect.right + OVERLAY_MARGIN;
  const desiredTop = triggerRect.top;
  if (overlayWouldOverlapBottomEdge(desiredTop, overlayRect)) {
    top = window.innerHeight - overlayRect.height - OVERLAY_MARGIN;
    bottom = OVERLAY_MARGIN;
  } else {
    top = desiredTop;
  }
  return {
    left,
    top,
    bottom,
  };
}

function getPositionLowerEdgeLeft(triggerRect: DOMRect | Rect, overlayRect: DOMRect | Rect) {
  return {
    left: triggerRect.left - overlayRect.width - OVERLAY_MARGIN,
    top: triggerRect.top + triggerRect.height - overlayRect.height,
  };
}

function getPositionLowerEdgeRight(triggerRect: DOMRect | Rect, overlayRect: DOMRect | Rect) {
  return {
    left: triggerRect.right + OVERLAY_MARGIN,
    top: triggerRect.top + triggerRect.height - overlayRect.height,
  };
}

export function getStylesFunction(
  position: OverlayPosition,
): (
  triggerRect: DOMRect | Rect,
  overlayRect: DOMRect | Rect,
  minHeight?: number,
) => { left: number; top: number; width?: number; bottom?: number } {
  switch (position) {
    case 'top':
      return getPositionTop;
    case 'top-right':
      return getPositionTopRight;
    case 'top-left':
      return getPositionTopLeft;
    case 'top-contain':
      return getPositionTopContain;
    case 'bottom':
      return getPositionBottom;
    case 'bottom-right':
      return getPositionBottomRight;
    case 'bottom-left':
      return getPositionBottomLeft;
    case 'bottom-contain':
      return getPositionBottomContain;
    case 'left':
      return getPositionLeft;
    case 'right':
      return getPositionRight;
    case 'upper-edge-left':
      return getPositionUpperEdgeLeft;
    case 'upper-edge-right':
      return getPositionUpperEdgeRight;
    case 'lower-edge-left':
      return getPositionLowerEdgeLeft;
    case 'lower-edge-right':
      return getPositionLowerEdgeRight;
    default:
      return getPositionTop;
  }
}
