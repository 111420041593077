import {
  CustomScheduleViewContentInput,
  CustomScheduleViewFragment,
} from '@/graphql/__generated__/graphql';
import { KeyboardEventCode } from '@/helpers/utils/os';

const keyMapping: Record<number, KeyboardEventCode> = {
  1: KeyboardEventCode.ONE,
  2: KeyboardEventCode.TWO,
  3: KeyboardEventCode.THREE,
  4: KeyboardEventCode.FOUR,
  5: KeyboardEventCode.FIVE,
  6: KeyboardEventCode.SIX,
  7: KeyboardEventCode.SEVEN,
  8: KeyboardEventCode.EIGHT,
  9: KeyboardEventCode.NINE,
  10: KeyboardEventCode.ZERO,
};

type ScheduleViewType = 'CUSTOM' | 'MILESTONE' | 'DEFAULT';

interface ScheduleViewShortcut {
  type: ScheduleViewType;
  content?: CustomScheduleViewContentInput;
}

export type ScheduleViewShortcuts = Record<KeyboardEventCode, ScheduleViewShortcut | null>;

const shortcuts: Ref<ScheduleViewShortcuts> = ref({
  [KeyboardEventCode.ONE]: null,
  [KeyboardEventCode.TWO]: null,
  [KeyboardEventCode.THREE]: null,
  [KeyboardEventCode.FOUR]: null,
  [KeyboardEventCode.FIVE]: null,
  [KeyboardEventCode.SIX]: null,
  [KeyboardEventCode.SEVEN]: null,
  [KeyboardEventCode.EIGHT]: null,
  [KeyboardEventCode.NINE]: null,
  [KeyboardEventCode.ZERO]: null,
});

export function useScheduleViewShortcuts() {
  function assignShortcuts(scheduleViews: CustomScheduleViewFragment[]) {
    // Clean all the shortcuts first
    Object.keys(shortcuts.value).forEach((key) => {
      shortcuts.value[key as KeyboardEventCode] = null;
    });

    // Assign the new shortcuts
    scheduleViews.forEach((view) => {
      const key = keyMapping[view.position];
      if (key) {
        shortcuts.value[key] = { type: view.type as ScheduleViewType, content: view.content };
      }
    });
  }

  return { assignShortcuts, shortcuts };
}
