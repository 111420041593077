import { defineStore } from 'pinia';

import { useGlobalStore } from '@/common/globalStore';
import { ownContributorGroupMembershipsQuery } from '@/features/projectContributors/store/projectContributorGql';
import {
  DisplayableTenantMembershipFragment,
  DisplayableTenantMembershipsQuery,
  MembershipsOfUserQuery,
  MembershipWithTenantFragment,
  OwnProjectContributorGroupMembershipFragment,
  ProjectContributorGroupType,
} from '@/graphql/__generated__/graphql';
import MembershipsOwnQuery from '@/graphql/membership/AllOwn.gql';
import { useApolloClient } from '@/plugins/apollo';
import { ProjectPermissionType } from '@/plugins/segment';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';
import { HIDDEN_MEMBERSHIP_EMAILS } from '@/utils/config';

import { useProjectStore } from '../projects';
import { displayableTenantMembershipsQuery } from '../tenantMemberships/tenantMembershipsGql';

export type OwnProjectContributorGroupMembership = OwnProjectContributorGroupMembershipFragment & {
  isProjectMembership: boolean;
  projectId: string;
};

export type MembershipStore = ReturnType<typeof useMembershipStore>;

export const useMembershipStore = defineStore('membership-store', () => {
  const groupMembershipsState = useQueryState<OwnProjectContributorGroupMembership[]>(
    [],
    fetchContributorGroupMemberships,
  );

  const tenantMembershipsState = useQueryState<MembershipWithTenantFragment[]>(
    [],
    fetchTenantMemberships,
  );

  const displayableTenantMembershipsState = useQueryState<DisplayableTenantMembershipFragment[]>(
    [],
    fetchDisplayableTenantMemberships,
  );

  const fetchProjectAuthorization = async (args: { projectId: string; tenantId: string }) => {
    const projectMembershipPromise = groupMembershipsState.fetch(false).then((data) => {
      return {
        projectMembership: data.find(
          (m) => m.projectId === args.projectId && m.isProjectMembership,
        ),
        groupMemberships: data.filter(
          (m) => m.projectId === args.projectId && !m.isProjectMembership,
        ),
      };
    });
    const tenantMembershipPromise = tenantMembershipsState.fetch(false).then((data) => {
      return data.find((m) => m.tenant.id === args.tenantId);
    });

    const [{ projectMembership, groupMemberships }, tenantMembership] = await Promise.all([
      projectMembershipPromise,
      tenantMembershipPromise,
    ]);

    return {
      projectMembership,
      tenantMembership,
      groupMemberships,
    };
  };

  const { data: ownContributorGroupMemberships } = groupMembershipsState;

  const { isInitializing: tenantMembershipsLoading, data: tenantMemberships } =
    tenantMembershipsState;

  const { data: displayableTenantMemberships } = displayableTenantMembershipsState;

  const isCurrentTenantAdmin = computed(() => {
    if (tenantMembershipsLoading.value) return false;

    const globalStore = useGlobalStore();
    return !!tenantMemberships.value.find(
      (membership) => membership.tenant.id === globalStore.currentTenantId,
    )?.isAdmin;
  });

  const contributorGroupBelongsToOwnMemberships = (contributorGroupId: string | undefined) => {
    return computed(
      () =>
        !!contributorGroupId &&
        !!ownContributorGroupMemberships.value.find((membership) => {
          return membership.contributorGroup.id === contributorGroupId;
        }),
    );
  };

  return {
    isCurrentTenantAdmin,
    tenantMemberships,
    tenantMembershipsLoading,
    displayableTenantMemberships,
    ownContributorGroupMemberships,
    addOwnContributorGroupMembership: (membership: OwnProjectContributorGroupMembership) => {
      ownContributorGroupMemberships.value = [...ownContributorGroupMemberships.value, membership];
    },
    clearOwnContributorGroupProjectMemberships: (projectId: string) => {
      ownContributorGroupMemberships.value = ownContributorGroupMemberships.value.filter(
        (membership) => membership.projectId !== projectId,
      );
    },
    externalGroupMemberships: computed(() => {
      return Array.from(ownContributorGroupMemberships.value).filter(
        (membership) => membership.contributorGroup.type === ProjectContributorGroupType.External,
      );
    }),
    getProjectMembership: (projectId: string) => {
      return computed(() => {
        return ownContributorGroupMemberships.value.find(
          (group) => group.isProjectMembership && group.projectId === projectId,
        );
      });
    },
    getProjectContributorMemberships: (projectId: string) => {
      return computed(() => {
        return ownContributorGroupMemberships.value.filter(
          (group) => group.projectId === projectId,
        );
      });
    },
    getExternalMembership: (projectId: string) => {
      return computed(() => {
        return ownContributorGroupMemberships.value.find(
          (group) =>
            group.contributorGroup.type === ProjectContributorGroupType.External &&
            group.projectId === projectId,
        );
      });
    },
    externalProjectMemberships: computed(() => {
      return ownContributorGroupMemberships.value.filter(
        (group) => group.contributorGroup.type === ProjectContributorGroupType.External,
      );
    }),
    loading: computed(() => {
      return groupMembershipsState.isInitializing || tenantMembershipsState.isInitializing;
    }),
    fetchProjectAuthorization,
    fetchTenantMemberships: () => {
      return tenantMembershipsState.fetch();
    },
    fetchContributorGroupMemberships: () => {
      return groupMembershipsState.fetch();
    },
    fetchDisplayableTenantMemberships: (reload?: boolean) => {
      return displayableTenantMembershipsState.fetch(reload);
    },
    getProjectPermissionType: (project: {
      id: string;
      tenant: { id: string };
    }): Promise<ProjectPermissionType | undefined> => {
      return fetchProjectAuthorization({ projectId: project.id, tenantId: project.tenant.id }).then(
        ({ tenantMembership, projectMembership, groupMemberships }) => {
          if (tenantMembership && tenantMembership.isAdmin) return ProjectPermissionType.Admin;

          if (projectMembership) return ProjectPermissionType.ProjectManagement;

          return groupMemberships.some(
            (groupMembership) =>
              groupMembership.contributorGroup.type === ProjectContributorGroupType.Internal,
          )
            ? ProjectPermissionType.Internal
            : ProjectPermissionType.External;
        },
      );
    },
    contributorGroupBelongsToOwnMemberships,
  };
});

const fetchTenantMemberships = async () => {
  return useApolloClient()
    .query<MembershipsOfUserQuery>({
      query: MembershipsOwnQuery,
      fetchPolicy: 'no-cache',
    })
    .then((result) => {
      const memberships = result.data.memberships
        ? flattenNodeConnection(result.data.memberships)
        : [];

      return memberships;
    });
};

const fetchDisplayableTenantMemberships = async () => {
  const globalStore = useGlobalStore();
  const projectStore = useProjectStore();

  if (globalStore.currentTenantId === null) {
    return [];
  }
  // if you are logged in as an external contributor, you are not allowed to fetch the users
  if (globalStore.currentTenantId !== projectStore.currentProject?.tenant?.id) {
    return [];
  }

  return useApolloClient()
    .query<DisplayableTenantMembershipsQuery>({
      variables: { tenant: globalStore.currentTenantId },
      query: displayableTenantMembershipsQuery,
      fetchPolicy: 'no-cache',
    })
    .then((result) => {
      const memberships = result.data.memberships
        ? flattenNodeConnection(result.data.memberships)
        : [];

      return memberships.filter(
        (membership) => !HIDDEN_MEMBERSHIP_EMAILS.has(membership.user.email),
      );
    });
};

const fetchContributorGroupMemberships = async () => {
  return useApolloClient()
    .query({
      query: ownContributorGroupMembershipsQuery,
      fetchPolicy: 'no-cache',
    })
    .then((result) => {
      const memberships = result.data.ownProjectContributorGroupMemberships
        ? flattenNodeConnection(result.data.ownProjectContributorGroupMemberships)
        : [];

      return memberships.map<OwnProjectContributorGroupMembership>((m) => ({
        ...m,
        projectId: m.contributorGroup.project.id,
        isProjectMembership: m.contributorGroup.isTenantOwnerGroup,
      }));
    });
};

function useQueryState<T>(initial: T, load: () => Promise<T>) {
  const isLoading = ref(false);
  const data = ref<T>(initial);
  const isInitialized = ref(false);
  const current = ref<Promise<T> | undefined>(undefined);
  const isInitializing = computed(() => {
    return !isInitialized.value && isLoading.value;
  });

  return {
    isLoading,
    isInitializing,
    data,
    current,
    fetch: async (reload = true): Promise<T> => {
      if (!reload && isInitialized.value) {
        return data.value;
      }

      if (current.value) {
        return current.value;
      }

      current.value = (async () => {
        try {
          isLoading.value = true;

          const result = await load();
          isInitialized.value = true;
          data.value = result;
          return result;
        } finally {
          isLoading.value = false;
        }
      })();

      const result = current.value;

      current.value = undefined;
      return result;
    },
  };
}
