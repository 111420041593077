<template>
  <DsAlert icon="alert-circle" color="attention" class="tw-mb-6" :title="errorTitle ?? ''">
    <template #default>
      <p>
        {{ errorMessage }}
      </p>
    </template>
    <template v-if="helpLink" #actions>
      <DsButton is-link hierarchy="link" :to="helpLink" class="tw-text-attention-600">
        {{ $t('components.DsError.moreInfo') }}
      </DsButton>
    </template>
  </DsAlert>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

const props = defineProps({
  /** The error to be displayed */
  error: {
    type: [String, Error] as PropType<Error | string | undefined | null>,
    default: undefined,
  },
  /** The error translation function that should be used */
  translateError: {
    type: Function as PropType<((error: Error) => string) | undefined>,
    default: undefined,
  },
  /** The error translation function that should be used */
  translateErrorTitle: {
    type: Function as PropType<((error: Error) => string) | undefined>,
    default: undefined,
  },
  helpLink: {
    type: String as PropType<string | null>,
    default: null,
  },
});

const errorMessage = computed(() => {
  return props.translateError && typeof props.error !== 'string'
    ? props.translateError(props.error!)
    : String(props.error);
});

const errorTitle = computed(() => {
  return props.translateErrorTitle && typeof props.error !== 'string'
    ? props.translateErrorTitle(props.error!)
    : null;
});
</script>
