<template>
  <slot
    v-bind="{
      ...$attrs,
      datatooltipid: id,
      class: `${disabled || informative ? '' : 'tw-cursor-pointer'}`,
      onMouseenter: showTooltip,
      onMouseleave: hideTooltipWithDelay,
    }"
  />
  <Teleport to="body">
    <DsAppearTransition>
      <div
        v-if="isTooltipShown"
        ref="tooltipRef"
        :style="style"
        class="ds-tooltip"
        :class="{ '--light': light, 'tw-pointer-events-none': !props.persistOnContentHover }"
        @mouseover="mouseOverTooltip"
        @mouseleave="mouseLeaveTooltip"
      >
        <p v-if="title || $slots.title" class="ds-tooltip-title tw-break-words">
          <slot name="title">
            {{ title }}
          </slot>
        </p>
        <p v-if="text || $slots.content" class="ds-tooltip-text">
          <slot name="content">
            {{ text }}
          </slot>
          <span v-if="shortcutText" class="tooltip-shortcut">{{ shortcutText }}</span>
        </p>
      </div>
    </DsAppearTransition>
  </Teleport>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

import { OverlayPosition, useGloballyPositionedOverlay } from '@/ds-components/overlay';
import DsAppearTransition from '@/ds-components/transitions/DsAppearTransition.vue';

const props = defineProps({
  title: { type: String, default: undefined },
  text: { type: String, default: undefined },
  disabled: { type: Boolean, required: false, default: false },
  informative: { type: Boolean, required: false, default: false },
  delayMs: { type: Number, required: false, default: null },
  persistOnContentHover: { type: Boolean, required: false, default: false },
  position: { type: String as PropType<OverlayPosition>, default: 'top' },
  shortcutText: { type: String, default: '' },
  light: { type: Boolean, default: false },
  manualElementSelector: { type: String, default: '' },
});

const emit = defineEmits<{
  (event: 'show'): void;
  (event: 'hide'): void;
}>();

const id = Math.random();

let timeoutId = 0;
const tooltipRef = ref<HTMLElement | null>(null);
const element = ref<HTMLElement | null>(null);
onMounted(() => {
  element.value = props.manualElementSelector
    ? document.querySelector(props.manualElementSelector)
    : document.querySelector(`[datatooltipid="${id}"]`);
});

/**
 * Manually show or hide the tooltip by using v-model instead of an element in the slot.
 */
const manualShowValue = defineModel<boolean | null>({ default: null });
watch(manualShowValue, (value) => {
  if (value) {
    showOverlay();
  } else if (value === false) {
    hideOverlay();
  }
});

watch(
  () => props.disabled,
  () => {
    if (props.disabled) {
      hideOverlay();
    }
  },
);

const { style, isShown, showOverlay, hideOverlay } = useGloballyPositionedOverlay(
  element,
  tooltipRef,
  reactive(props),
);

const slots = useSlots();

const isTooltipShown = computed(() => {
  return (
    (props.text || slots.content || props.title || slots.title) && isShown.value && !props.disabled
  );
});

const isMouseOverTooltip = ref(false);

const showTooltip = () => {
  if (timeoutId) {
    window.clearTimeout(timeoutId);
  }
  showOverlay();
  emit('show');
};

const hideTooltipWithDelay = () => {
  emit('hide');
  timeoutId = window.setTimeout(
    () => {
      if (!isMouseOverTooltip.value) {
        hideOverlay();
      }
    },
    props.persistOnContentHover ? 200 : 0,
  );
};

const mouseOverTooltip = () => {
  isMouseOverTooltip.value = props.persistOnContentHover;
};

const mouseLeaveTooltip = () => {
  isMouseOverTooltip.value = false;
  hideTooltipWithDelay();
};
</script>

<script lang="ts">
export default { inheritAttrs: false };
</script>

<style>
.ds-tooltip {
  @apply tw-fixed tw-flex tw-flex-col tw-gap-1 tw-max-w-sm tw-w-max tw-py-2 tw-px-3 tw-rounded-xs tw-shadow-lg;
  @apply tw-ds-text-xs tw-bg-cool-grey-800 tw-text-white;
}
.ds-tooltip.--light {
  @apply tw-bg-white tw-text-secondary;
}
.ds-tooltip-text {
  @apply tw-break-words tw-whitespace-pre-line;
}
.ds-tooltip-title {
  @apply tw-ds-text-xs--semibold;
}
</style>
<style scoped>
.tooltip-shortcut {
  @apply tw-inline-block tw-rounded-2xs tw-ml-4 tw-px-2.5;
  @apply tw-bg-[rgba(255,255,255,0.16)];
}
</style>
