import { PartialEntity, SchedulingContext } from '@koppla-tech/scheduling-engine';

import {
  InteractiveEntityChanges,
  InteractiveEntityStores,
  MilestoneEntity,
  OrderDependencyEntity,
  OrderEntity,
} from '@/common/types';
import {
  CollisionCausedEvent,
  CollisionResolvedEvent,
} from '@/features/collisions/collisionAnalytics';
import { dependencyCollisionIsHidden } from '@/features/collisions/collisionUtils';
import { StatusReport } from '@/helpers/orders/status';
import { LoggingService } from '@/interfaces/services';

export function trackCollisionChanges(
  loggingService: LoggingService,
  context: SchedulingContext,
  changes: InteractiveEntityChanges,
  entityStores: InteractiveEntityStores,
): void {
  // NOTE: Apparently, mocking a Map is not easily possibly with jest, so to make the tests succeed,
  // we check if the .values() function is accessible here, and if not, we just return an empty array, since tracking is not tested anyways.
  const openCollisions = (
    typeof entityStores.orderDependencyStore().dependencies.values !== 'undefined'
      ? [...entityStores.orderDependencyStore().dependencies.values()]
      : ([] as OrderDependencyEntity[])
  ).filter((dep) => dep.isViolated);

  const newCollisions = [
    ...(changes.add?.dependencies?.filter((dep) => dep.isViolated) ?? []),
    ...(changes.update?.dependencies?.filter((dep) => dep.isViolated) ?? []),
  ];

  trackCollisionsCaused(loggingService, entityStores, newCollisions, openCollisions.length);

  trackCollisionsResolved(
    loggingService,
    changes,
    entityStores,
    openCollisions,
    openCollisions.length + newCollisions.length,
  );
}

function trackCollisionsCaused(
  loggingService: LoggingService,
  entityStores: InteractiveEntityStores,
  newCollisions: PartialEntity<OrderDependencyEntity>[],
  openCollisionCount: number,
): void {
  let idx = 0;
  newCollisions.forEach((dependency) => {
    const oldDependency = entityStores.orderDependencyStore().dependencies.get(dependency.id);

    if (!oldDependency) {
      return;
    }

    const newDependency = {
      ...oldDependency,
      ...dependency,
    };
    const toIsMilestone = entityStores.milestoneStore().milestones.has(newDependency.to?.id ?? '');
    const toIsFixed = toIsMilestone
      ? entityStores.milestoneStore().milestones?.get(newDependency.to?.id ?? '')?.isFixed
      : entityStores.orderStore().orders?.get(newDependency.to?.id ?? '')?.isFixed;
    const collisionIsHidden = dependencyCollisionIsHidden(newDependency, entityStores);

    loggingService.trackEvent(
      new CollisionCausedEvent({
        type: toIsMilestone ? 'milestone' : 'order',
        isVisible: !collisionIsHidden,
        openCollisionCount: openCollisionCount + idx + 1,
        successorIsFixed: toIsFixed ?? false,
      }),
    );
    idx += 1;
  });
}

function trackCollisionsResolved(
  loggingService: LoggingService,
  changes: InteractiveEntityChanges,
  entityStores: InteractiveEntityStores,
  openCollisions: PartialEntity<OrderDependencyEntity>[],
  openCollisionCount: number,
): void {
  const changedElementsMap = new Map(
    [...(changes.update?.milestones ?? []), ...(changes.update?.orders ?? [])].map((d) => [
      d.id,
      d,
    ]),
  );
  const changedDependenciesMap = new Map(
    [...(changes.update?.dependencies ?? [])].map((d) => [d.id, d]),
  );
  const deletedElementIds = new Set(
    [...(changes.delete?.milestones ?? []), ...(changes.delete?.orders ?? [])].map((d) => d.id),
  );
  const deletedDependencyIds = new Set([...(changes.delete?.dependencies ?? [])].map((d) => d.id));

  let idx = 0;
  openCollisions.forEach((dependency) => {
    const toIsMilestone = entityStores.milestoneStore().milestones.has(dependency.to?.id ?? '');
    const toIsFixed = toIsMilestone
      ? entityStores.milestoneStore().milestones?.get(dependency.to?.id ?? '')?.isFixed
      : entityStores.orderStore().orders?.get(dependency.to?.id ?? '')?.isFixed;

    const method = getCollisionResolutionMethod(
      dependency,
      changedElementsMap,
      deletedElementIds,
      changedDependenciesMap,
      deletedDependencyIds,
      toIsMilestone,
    );

    if (!method) {
      return;
    }

    loggingService.trackEvent(
      new CollisionResolvedEvent({
        type: toIsMilestone ? 'milestone' : 'order',
        method,
        openCollisionCount:
          // the mark done method is only locally hiding collisons, so we don't count it here as removing the collision
          method === 'successor_mark_done' ? openCollisionCount : openCollisionCount - (idx + 1),
        isOnlyHidden: method === 'successor_mark_done',
        successorIsFixed: toIsFixed ?? false,
      }),
    );
    if (method !== 'successor_mark_done') {
      idx += 1;
    }
  });
}

// eslint-disable-next-line complexity
function getCollisionResolutionMethod(
  dependency: PartialEntity<OrderDependencyEntity>,
  changedElementIds: Map<string, PartialEntity<OrderEntity> | PartialEntity<MilestoneEntity>>,
  deletedElementIds: Set<string>,
  changedDependencyIds: Map<string, PartialEntity<OrderDependencyEntity>>,
  deletedDependencyIds: Set<string>,
  toIsMilestone: boolean,
): CollisionResolvedEvent['properties']['method'] | null {
  let method: CollisionResolvedEvent['properties']['method'] | null = null;

  // in these cases the dependency is actually not violated anymore (so collision is resolved)
  const changedDependency = changedDependencyIds.get(dependency.id);
  if (changedDependency && !changedDependency?.isViolated) {
    if (changedDependency.lagInMinutes !== undefined) {
      method = 'dependency_update';
    } else if (changedElementIds.has(dependency.from?.id ?? '')) {
      method = 'predecessor_move';
    } else if (changedElementIds.has(dependency.to?.id ?? '')) {
      method = 'successor_move';
    }
  }

  // in these cases the dependency is still violated but the collision will be filtered out (so it's resolved)
  if (deletedDependencyIds.has(dependency.id)) {
    method = 'dependency_delete';
  } else if (deletedElementIds.has(dependency.from?.id ?? '')) {
    method = 'predecessor_delete';
  } else if (deletedElementIds.has(dependency.to?.id ?? '')) {
    method = 'successor_delete';
  } else {
    if (toIsMilestone) {
      const change = changedElementIds.get(
        dependency.to?.id ?? '',
      ) as PartialEntity<MilestoneEntity>;
      if (change?.completedAt) {
        method = 'successor_mark_done';
      }
    } else {
      const change = changedElementIds.get(dependency.to?.id ?? '') as PartialEntity<OrderEntity>;
      if (change?.status === StatusReport.DONE) {
        method = 'successor_mark_done';
      }
    }
  }

  return method;
}
