<template>
  <DsTooltip
    v-slot="tooltipProps"
    :text="valueCopied ? $t('components.CopyButton.copied') : $t('components.CopyButton.copy')"
    position="right"
  >
    <DsButton v-bind="tooltipProps" hierarchy="link" @click="onClick">
      <DsSvgIcon v-if="!valueCopied" size="sm" class="copy-icon ds-input-icon" icon="copy-01" />
      <DsSvgIcon v-else color="success" size="sm" class="copied-icon" icon="check" />
    </DsButton>
  </DsTooltip>
</template>

<script setup lang="ts">
const props = defineProps({
  valueToCopy: {
    type: [String, Number],
    required: true,
  },
});

const valueCopied = ref(false);
let timeoutId: number | null = null;
const COPY_NOTIFICATION_DURATION = 2000;

const onClick = () => {
  try {
    navigator.clipboard.writeText(props.valueToCopy.toString());
    showCopied();
  } catch {} // No permission to copy, so we just ignore the error
};

const showCopied = () => {
  valueCopied.value = true;
  clearExistingTimeout();
  timeoutId = window.setTimeout(resetCopied, COPY_NOTIFICATION_DURATION);
};

const resetCopied = () => {
  valueCopied.value = false;
};

const clearExistingTimeout = () => {
  if (timeoutId) {
    window.clearTimeout(timeoutId);
    timeoutId = null;
  }
};

watch(
  () => props.valueToCopy,
  () => {
    clearExistingTimeout();
    resetCopied();
  },
);
</script>

<style scoped>
.copy-icon {
  @apply tw-cursor-pointer tw-text-cool-grey-600;
}
.copied-icon {
  @apply tw-cursor-pointer;
}
.ds-button:focus-visible {
  @apply focus-visible:tw-ring focus:tw-ring-secondary-bg-dark;
}
</style>
