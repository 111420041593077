import { DinPaperFormat, PageOrientation } from '@/features/scheduleExport/pdfExport/types';
import { DateRangeObject } from '@/helpers/utils/dates';

export enum ExportTab {
  EXCEL = 'excel',
  GANTT = 'gantt',
  PDF = 'pdf',
}

export enum ExportKey {
  EXCEL = 'excel',
  MS_PROJECT = 'msproject',
  POWER_PROJECT = 'powerproject',
  PRIMAVERA_P6 = 'primaverap6',
  PDF = 'pdf',
}

export enum GanttExportKey {
  MS_PROJECT = 'msproject',
  POWER_PROJECT = 'powerproject',
  PRIMAVERA_P6 = 'primaverap6',
}

export function mapGanttExportKeyToExportKey(key: GanttExportKey): ExportKey {
  switch (key) {
    case GanttExportKey.MS_PROJECT:
      return ExportKey.MS_PROJECT;
    case GanttExportKey.POWER_PROJECT:
      return ExportKey.POWER_PROJECT;
    case GanttExportKey.PRIMAVERA_P6:
      return ExportKey.PRIMAVERA_P6;
  }
}

/** Desired export type (form of exported pdf pages) */
export enum PdfExportType {
  DIGITAL = 'digital',
  PRINT = 'print',
  PLOT = 'plot',
}

export interface PdfExportFormat {
  type: PdfExportType;
  orientation: PageOrientation;
  paperFormat: DinPaperFormat | string;
  isMultiPage: boolean;
}

export type PdfCustomExportConfig = PdfExportFormat & {
  currentZoomPreset: string;
  projectName: string | undefined;
  tenantLogo: string | null | undefined;
  exportRange: DateRangeObject;
};

export function getInitialPdfExportFormat(): PdfExportFormat {
  return {
    type: PdfExportType.PRINT,
    orientation: PageOrientation.LANDSCAPE,
    paperFormat: DinPaperFormat.A4,
    isMultiPage: false,
  };
}
