import { StorageLike } from 'pinia-plugin-persistedstate';

/**
 * Checks whether current window context is an iframe.
 */
export function checkHostIsIFrame() {
  try {
    // Attempt to compare the current window with its parent
    return window.self !== window.top;
  } catch {
    // If an exception occurs, it likely means we're in a cross-origin iframe
    return true;
  }
}

/**
 * Read only storage to share data between
 * parent and child frame without child
 * overwriting parent data.
 */
const iframePiniaStorage: StorageLike = {
  getItem(key) {
    return localStorage.getItem(key);
  },
  setItem(key, value) {
    if (checkHostIsIFrame()) return;
    return localStorage.setItem(key, value);
  },
};

export function getReadonlyPiniaChildStorage() {
  return iframePiniaStorage;
}
