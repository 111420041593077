import { ResourceModel, SchedulerPro } from '@bryntum/schedulerpro';

import { ScheduleConstants } from '@/common/bryntum/constants';
import { FeatureAccessStore } from '@/common/featureAccessStore';
import { PartialEntity, WbsSectionEntity } from '@/common/types';
import {
  getFlatSortedSections,
  getSectionAnalyticsType,
  getSectionAncestors,
  getSectionChildren,
  getSectionDescendantsByIndentation,
  getSectionIndentation,
  getSectionRoot,
  getSectionSiblings,
  getSectionSiblingsByParentId,
  isValidSection,
  sanitizeSections,
  SectionArrangementEdited,
  useWbsSectionStore,
  WbsSectionStore,
} from '@/features/projectStructure';
import {
  getSectionDescendants,
  getSectionsMap,
  SectionsInput,
} from '@/features/projectStructure/utils/sectionTreeUtils';
import { useScheduleViewStore } from '@/features/scheduleViews/scheduleViewStore';
import { copy } from '@/helpers/utils/objects';
import { LoggingService } from '@/interfaces/services';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { getEmptyResourceId, getMainResourceId } from '@/services/store/schedule/parsers/base';
import { SchedulerResource } from '@/services/store/schedule/types';
import eventBus from '@/utils/eventBus';

import {
  collapseResource,
  scrollToResource,
} from '../../../../../features/schedule/bryntum/schedulerInteractions';

interface RowReorderListeners {
  gridRowBeforeDragStart: (event: { context: { records: SchedulerResource[] } }) => void;
  gridRowDrag: (event: {
    context: {
      records: SchedulerResource[];
      insertBefore: SchedulerResource | null;
      valid: boolean;
      parent: SchedulerResource;
    };
  }) => void;
  gridRowDrop: (event: {
    context: {
      records: SchedulerResource[];
      insertBefore: SchedulerResource | null;
      valid: boolean;
      parent: SchedulerResource;
    };
  }) => void;
  gridRowAbort: () => void;
}

export function getRowReorderListeners(
  store: ScheduleStore,
  loggingService: LoggingService,
  featureAccessStore: FeatureAccessStore,
): RowReorderListeners {
  let lastParentId = '';

  const removeLastParentHighlighting = () => {
    if (lastParentId) {
      const scheduler = getScheduler()!;
      const lastParent = scheduler.resourceStore.getById(lastParentId) as ResourceModel;
      if (lastParent) {
        lastParent.cls = lastParent.cls?.replace('highlight-parent-row', '');
      }
    }
  };

  return {
    gridRowBeforeDragStart: ({ context }) => {
      const [record] = context.records;

      if (!featureAccessStore.hasWriteAccessToSections) return false;

      const isDraggable = isRowDraggable(store, record);
      const scheduler = getScheduler();
      if (!scheduler) return;

      if (isDraggable && record.isTopLevelRow) {
        const sectionStore = useWbsSectionStore();
        const scheduleViewStore = useScheduleViewStore();
        const draggedSection = sectionStore.wbsSections.get(record.id);
        if (!draggedSection) return isDraggable;

        const relatedSectionIds = getSectionSiblings(sectionStore.wbsSections, draggedSection).map(
          (s) => s.id,
        );
        scheduleViewStore.collapseStateForRows(relatedSectionIds);
        relatedSectionIds.forEach((id) => {
          const resourceModel = scheduler.resourceStore.getById(id) as ResourceModel | undefined;
          if (!resourceModel) return;
          collapseResource(scheduler, id);
          resourceModel.set({ expanded: false });
          store.entities.resources.set(resourceModel.id.toString(), {
            ...store.entities.resources.get(resourceModel.id.toString())!,
            expanded: false,
          });
        });
        // NOTE: we scroll to the top after collapsing, since many rows are collapsed now, which might mess up the scroll position
        scrollToResource(scheduler, getMainResourceId(), 'start');
      }

      return isDraggable;
    },
    gridRowDrag: ({ context }) => {
      const { records, parent, insertBefore } = context;
      const [record] = records;
      const scheduler = getScheduler();
      if (!scheduler) return;

      store.utils.showDragCursor = true;

      const sectionStore = useWbsSectionStore();
      const oldSections = copy(getFlatSortedSections(sectionStore.wbsSections));
      // NOTE: No sanitize here, cause it's okay if new rows remain invalid here
      const newSections = getUpdatedSections(
        scheduler,
        copy(oldSections),
        record.id,
        parent?.id ?? '',
        insertBefore?.id ?? null,
      );
      const newDraggedSection = newSections.find((s) => s.id === record.id);

      const newParent = scheduler.resourceStore.getById(
        newDraggedSection?.parentId ?? '',
      ) as SchedulerResource;

      if (lastParentId !== newParent?.id) {
        removeLastParentHighlighting();
        if (newParent && !newParent?.cls?.includes('highlight-parent-row')) {
          newParent.cls = `${newParent.cls} highlight-parent-row`;
          lastParentId = newParent.id;
        }
      }

      const { isValid, reason } = isValidResourceUpdate(
        scheduler,
        {
          allSections: sectionStore.wbsSections,
          parent,
          record,
          insertBefore,
        },
        { mainResourceId: getMainResourceId() },
      );
      context.valid = isValid;
      if (reason === 'DIFFERENT_ROOT') {
        const i18n = store.i18n;
        eventBus.emit(
          'new-notification',
          i18n.t('objects.section.reorderDifferentPhaseHint'),
          undefined,
          undefined,
          undefined,
          false,
        );
      }
    },
    gridRowDrop: ({ context }) => {
      const { records, parent, insertBefore } = context;
      const [record] = records;
      store.utils.showDragCursor = false;
      removeLastParentHighlighting();

      if (!context.valid) return;
      moveSection(record, parent, insertBefore, loggingService);
    },
    gridRowAbort: () => {
      store.utils.showDragCursor = false;
      removeLastParentHighlighting();
    },
  };
}

const moveSection = (
  draggedRecord: SchedulerResource,
  parentOfInsertBefore: SchedulerResource,
  insertBefore: SchedulerResource | null,
  loggingService: LoggingService,
) => {
  const scheduler = getScheduler();
  const sectionStore = useWbsSectionStore();

  const draggedSectionId = draggedRecord.id;
  const draggedSection = sectionStore.wbsSections.get(draggedSectionId);

  if (!draggedSection) return;

  const parentOfInsertBeforeSectionId = parentOfInsertBefore.id;
  const insertBeforeSectionId = insertBefore?.id ?? null;

  const sortedSectionCopy = copy(getFlatSortedSections(sectionStore.wbsSections));
  const movedSections = getUpdatedSections(
    scheduler,
    sortedSectionCopy,
    draggedSectionId,
    parentOfInsertBeforeSectionId,
    insertBeforeSectionId,
  );

  const newSections = sanitizeSections(movedSections);
  const newDraggedSection = newSections.find((section) => draggedRecord.id === section.id)!;
  const newDraggedSectionParentId = newDraggedSection.parentId;
  const newDraggedSectionSiblings = getSectionSiblingsByParentId(
    newSections,
    newDraggedSectionParentId,
  );
  const newInsertBeforeSection = newDraggedSectionSiblings.find(
    (_, idx, siblings) => siblings[idx - 1]?.id === newDraggedSection.id,
  );
  const newInsertBeforeSectionId = newInsertBeforeSection?.id ?? null;

  const isSameParent = newDraggedSectionParentId === draggedSection.parentId;

  const newPosition = findLowestPossiblePositionForMovedSection({
    dropTargetParentId: newDraggedSectionParentId,
    insertBeforeSectionId: newInsertBeforeSectionId,
    formerPosition: draggedSection.position,
    isSameParent,
    sectionStore,
  });

  const partiallyUpdatedSection: PartialEntity<WbsSectionEntity> = {
    id: draggedSection.id,
    ...(draggedSection.position !== newPosition
      ? {
          position: newPosition,
        }
      : {}),
    ...(!isSameParent ? { parentId: newDraggedSectionParentId, position: newPosition } : {}),
  };

  const trackingAnalyticsType = getSectionAnalyticsType(sectionStore.wbsSections, draggedSection);

  loggingService.trackEvent(
    new SectionArrangementEdited({
      action: isSameParent ? 'sort' : 'move',
      type: trackingAnalyticsType,
    }),
  );

  sectionStore.update([partiallyUpdatedSection]);
};

const findLowestPossiblePositionForMovedSection = ({
  dropTargetParentId,
  insertBeforeSectionId,
  formerPosition,
  isSameParent,
  sectionStore,
}: {
  dropTargetParentId: string | null;
  insertBeforeSectionId: string | null;
  formerPosition: number;
  isSameParent: boolean;
  sectionStore: WbsSectionStore;
}) => {
  const sortedSiblings = getSectionSiblingsByParentId(
    sectionStore.wbsSections,
    dropTargetParentId,
  ).sort((a, b) => a.position - b.position);

  if (sortedSiblings[0]?.id === insertBeforeSectionId) return 0;
  if (!insertBeforeSectionId)
    return sortedSiblings[sortedSiblings.length - 1]
      ? sortedSiblings[sortedSiblings.length - 1].position + 1
      : 0;

  let newPosition = 0;
  for (let i = 0; i < sortedSiblings.length; i++) {
    const sibling = sortedSiblings[i];
    if (sibling.id !== insertBeforeSectionId) continue;

    const previousSibling = sortedSiblings[i - 1];

    newPosition = previousSibling ? previousSibling.position + 1 : 0;
    break;
  }

  const willBeMovedUpInTheSameParent = isSameParent && newPosition > formerPosition;

  // when moving the section higher in the same parent, we subtract 1 from conflicting sections
  // so we need to subtract 1 again from the newPosition
  return willBeMovedUpInTheSameParent ? --newPosition : newPosition;
};

export const getUpdatedSections = (
  scheduler: SchedulerPro | undefined,
  oldSections: WbsSectionEntity[],
  draggedSectionId: string,
  parentOfInsertBeforeSectionId: string,
  insertBeforeSectionId: string | null,
) => {
  if (!scheduler) return oldSections;
  // we filter the actual visible sections here, as the reorder varies if some sections are hidden
  const oldFilteredSections = oldSections.filter((s) => scheduler.resourceStore.isAvailable(s.id));
  const draggedSection = oldFilteredSections.find((s) => s.id === draggedSectionId);
  const insertBeforeSection = oldFilteredSections.find((s) => s.id === insertBeforeSectionId);
  const parentOfInsertBeforeSection = oldFilteredSections.find(
    (s) => s.id === parentOfInsertBeforeSectionId,
  );

  if (!draggedSection) {
    return oldSections;
  }

  /**
   * The new order of the sections is calculated by iterating over the old sections and inserting the dragged section at the correct location,
   * plus updating the parent in a correct manner.
   * The parentOfInsertBeforeSection specifies the parent of the section before which the dragged section should be inserted.
   * The insertBeforeSection specifies the section before which the dragged section should be inserted.
   * Whenever the section is dragged after the last child of a parent, the insertBeforeSection is null, which requires the use of
   * the parentOfInsertBeforeSection for these cases.
   */

  const result: WbsSectionEntity[] = [];
  let prevSection: WbsSectionEntity | null = null;
  const lastSection = oldFilteredSections[oldFilteredSections.length - 1];
  // eslint-disable-next-line complexity -- TODO: KOP-2380
  oldSections.forEach((currentSection) => {
    // no changes if section is not visible
    if (!scheduler.resourceStore.isAvailable(currentSection.id)) {
      result.push(currentSection);
      return;
    }

    // we decide later what to do with the dragged section and skip it first
    if (currentSection.id === draggedSectionId && insertBeforeSection?.id !== draggedSectionId) {
      return;
    }

    // if the dragged section was already added, we can just continue and add the rest
    if (result.find((s) => s.id === draggedSectionId)) {
      result.push(currentSection);
      return;
    }

    const droppedOnParentDirectly =
      parentOfInsertBeforeSection &&
      insertBeforeSection &&
      parentOfInsertBeforeSection.id === insertBeforeSection.id;

    const assignAsFirstChild = () => {
      draggedSection.parentId = currentSection.id;
      result.push(draggedSection);
    };

    const assignAsSiblingConditionally = (section: WbsSectionEntity) => {
      // if dragged section had a parent and is now dragged to top-level, we keep it nested
      if (draggedSection.parentId && !section.parentId) {
        draggedSection.parentId = section.id;
      }
      // top-levels always remain top-levels
      else if (!draggedSection.parentId) {
        draggedSection.parentId = null;
      }
      // bottom-levels always remain bottom-levels assigned as sibling to the given section
      else if (!getSectionChildren(oldSections, draggedSection).length) {
        draggedSection.parentId = section?.parentId ?? null;
      }
      // for mid-levels, we assign as sibling to the parent that preserves the same indentation level, otherwise we would nest the mid-level again
      else {
        const oldIndentation = getSectionIndentation(oldSections, draggedSection);
        const insertIndentation = getSectionIndentation(oldSections, section);
        let indentationDiff = insertIndentation - oldIndentation;
        let newParentId = section?.parentId;

        const findNewParent = (parentId: string | undefined) => {
          return oldFilteredSections.find((s) => s.id === parentId);
        };

        while (indentationDiff > 0 && newParentId) {
          const parent = findNewParent(newParentId);
          if (parent?.parentId) {
            newParentId = parent.parentId;
          }
          indentationDiff--;
        }
        draggedSection.parentId = newParentId ?? null;
      }
    };

    const assignAsSiblingOfLastSection = () => {
      // if the dragged section had a parent before, we add it as a sibling of the last section, if not it remains top-level
      if (draggedSection.parentId) {
        assignAsSiblingConditionally(lastSection);
      }
      result.push(currentSection);
      result.push(draggedSection);
    };

    if (droppedOnParentDirectly) {
      if (currentSection.id === insertBeforeSection.id) {
        assignAsFirstChild();
      }
    }
    // CASE: section is dragged to very top, very bottom or on top of a parent
    else if (!parentOfInsertBeforeSection) {
      // if no insertBefore exists, it is dragged to the very bottom
      if (!insertBeforeSection && currentSection.id === lastSection.id) {
        assignAsSiblingOfLastSection();
      }
      if (insertBeforeSection && currentSection.id === insertBeforeSection.id) {
        // if no prev section, it is dragged to the very top, and we create a top-level
        if (!prevSection) {
          draggedSection.parentId = null;
        }
        // else, it is dragged on top of other parent
        else {
          assignAsSiblingConditionally(prevSection);
        }
        result.push(draggedSection);
      }
    }
    // CASE: section is dragged to the very end of a parent
    else if (!insertBeforeSection) {
      const descendants = getSectionDescendants(oldFilteredSections, parentOfInsertBeforeSection);
      const lastDescendant = descendants[descendants.length - 1];

      // if we arrived at the next section that comes after the last descendant of the parent, we
      // can insert the dragged section here and assign as sibling of this last descendant
      if (prevSection && prevSection?.id === lastDescendant.id) {
        assignAsSiblingConditionally(prevSection);
        result.push(draggedSection);
      }
      // if the current section is already the last one, there won't be another one to insert before, so we assign as sibling to the last section
      else if (currentSection.id === lastSection.id) {
        assignAsSiblingOfLastSection();
      }
    }
    // CASE: section is dragged before another child of a specific parent, we assign as sibling
    // in this case, no special handling between top-, mid-, and bottom-level is required, as the action explicitly
    // forces a new nesting (because you drop something in-between other children)
    else if (currentSection.id === insertBeforeSection.id) {
      draggedSection.parentId = currentSection.parentId;
      result.push(draggedSection);
    }

    if (!result.includes(currentSection)) {
      result.push(currentSection);
    }
    prevSection = currentSection;
  });

  return result;
};

function isOnlyChild(sectionId: string): boolean {
  const sectionStore = useWbsSectionStore();
  const section = sectionStore.wbsSections.get(sectionId)!;
  return getSectionSiblings(sectionStore.wbsSections, section).length === 1;
}

function isRowDraggable(store: ScheduleStore, record: SchedulerResource): boolean {
  return (
    ![getMainResourceId(), getEmptyResourceId()].includes(record.id) &&
    !store.readonly &&
    !isOnlyChild(record.id) &&
    !store.utils.disableSectionInteractions
  );
}

function dropWouldAssignToDifferentRoot(
  allSections: SectionsInput,
  draggedSection: WbsSectionEntity,
  parentSection?: WbsSectionEntity,
): boolean {
  if (!draggedSection.parentId) return !!parentSection;

  const draggedRoot = getSectionRoot(allSections, draggedSection);
  const parentRoot = parentSection ? getSectionRoot(allSections, parentSection) : null;

  return draggedRoot.id !== parentRoot?.id;
}

function dropWouldTransformChildIntoParent(
  draggedSection: WbsSectionEntity,
  parentSection?: WbsSectionEntity,
): boolean {
  if (!draggedSection.parentId) return false;
  return !parentSection;
}

function dropWouldAssignParentToItself(
  allSections: SectionsInput,
  draggedSection: WbsSectionEntity,
  parentSection?: WbsSectionEntity,
): boolean {
  if (!parentSection) return false;
  const ancestors = getSectionAncestors(allSections, parentSection);
  return ancestors.some((ancestor) => draggedSection.id === ancestor.id);
}

function dropWouldCreateInvalidStructure(
  scheduler: SchedulerPro | undefined,
  allSections: SectionsInput,
  draggedSection: WbsSectionEntity,
  parentSection?: WbsSectionEntity,
  insertBeforeSection?: WbsSectionEntity,
): boolean {
  const oldSections = copy(getFlatSortedSections(allSections));
  const newSections = sanitizeSections(
    getUpdatedSections(
      scheduler,
      copy(oldSections),
      draggedSection.id,
      parentSection?.id ?? '',
      insertBeforeSection?.id ?? null,
    ),
  );

  const roots = newSections.filter((section) => !section.parentId);
  return roots.some((root) => {
    const descendants = getSectionDescendantsByIndentation(newSections, root);
    return (
      !isValidSection(newSections, root) ||
      descendants[ScheduleConstants.MAX_ROW_INDENTATION + 1]?.length > 0
    );
  });
}

export function isValidResourceUpdate(
  scheduler: SchedulerPro | undefined,
  sectionData: {
    allSections: SectionsInput;
    parent: SchedulerResource;
    record: SchedulerResource;
    insertBefore: SchedulerResource | null;
  },
  metaRowIds: {
    mainResourceId: string;
  },
): { isValid: boolean; reason?: string } {
  if (sectionData.insertBefore?.id === metaRowIds.mainResourceId) {
    return { isValid: false };
  }

  const sectionsMap = getSectionsMap(sectionData.allSections);

  const draggedSection = sectionsMap.get(sectionData.record.id);
  const parentSection = sectionsMap.get(sectionData.parent?.id);
  const insertBeforeSection = sectionData.insertBefore
    ? sectionsMap.get(sectionData.insertBefore.id)
    : undefined;

  // NOTE: Not possible that both parent and insertBefore are undefined, since we still have the emptySection
  if (!draggedSection || (!parentSection && !sectionData.insertBefore)) {
    return { isValid: false };
  }

  const wbsSectionArray = Array.from(sectionData.allSections.values());

  if (dropWouldTransformChildIntoParent(draggedSection, parentSection)) {
    return { isValid: false };
  }
  if (dropWouldAssignParentToItself(wbsSectionArray, draggedSection, parentSection)) {
    return { isValid: false };
  }
  if (dropWouldAssignToDifferentRoot(wbsSectionArray, draggedSection, parentSection)) {
    return { isValid: false, reason: 'DIFFERENT_ROOT' };
  }
  if (
    dropWouldCreateInvalidStructure(
      scheduler,
      wbsSectionArray,
      draggedSection,
      parentSection,
      insertBeforeSection,
    )
  ) {
    return { isValid: false };
  }

  return { isValid: true };
}
