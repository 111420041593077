import { isEqual } from 'lodash';
import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';

import { useFeatureAccessStore } from '@/common/featureAccessStore';
import { useGlobalStore } from '@/common/globalStore';
import { useWbsSectionStore } from '@/features/projectStructure';
import { getInverseSectionSelection } from '@/features/projectStructure/utils/getInverseSectionSelection';
import { getDefaultViewPreset, SchedulerViewPreset } from '@/features/schedule/bryntum/presets';
import { ScheduleViewIconName } from '@/features/scheduleViews/icons';
import {
  CreateCustomScheduleViewInput,
  CustomScheduleViewFragment,
  UpdateCustomScheduleViewInput,
} from '@/graphql/__generated__/graphql';
import { copy } from '@/helpers/utils/objects';
import { useApolloClient } from '@/plugins/apollo';
import { useRouteNames } from '@/router/helpers/routeNames';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { SchedulerEventColorType } from '@/services/store/schedule/types';
import { getReadonlyPiniaChildStorage } from '@/utils/iframe';
import { getBrowserLanguage, Language } from '@/utils/languages';

import { refreshScheduleUI } from '../schedule/bryntum/schedulerInteractions';
import {
  createCustomScheduleViewMutation,
  deleteCustomScheduleViewMutation,
  updateCustomScheduleViewMutation,
  userScheduleViewsQuery,
} from './scheduleViewGql';
import { useScheduleViewShortcuts } from './useScheduleViewShortcuts';

export type ScheduleFilterState = {
  contributorGroups: string[];
  sections: string[];
  trades: string[];
};

type InternalFilterState = {
  contributorGroups: string[];
  hiddenSections: string[];
  trades: string[];
};

const getInitialInternalFilter = (): InternalFilterState => ({
  contributorGroups: [],
  hiddenSections: [],
  trades: [],
});

const getInitialScheduleFilter = (): ScheduleFilterState => ({
  contributorGroups: [],
  sections: [],
  trades: [],
});

type ProjectSettings = {
  collapsedRowIds: Set<string>;
  eventColorType: SchedulerEventColorType;
  eventToHighlightRelationsFor: string | null;
  hideWeekends: boolean;
  scrollDate: number | null;
  scrollRowIdx: number | null;
  // TODO: invert logic with https://linear.app/koppla/issue/KOP-2784/[expu-p1]-settings-sidebar
  showDependencies: boolean;
  splitterWidth: number;
  zoomPreset: string;
};

export interface ScheduleViewState {
  locale: Language;
  projectId: string;
  projectsSettings: Record<string, ProjectSettings>;
  projectFilters: Record<string, ScheduleFilterState>;
  storeVersion: number;
  zoomPresets: string[];
}

const getDefaultProjectSettings = (): ProjectSettings => ({
  collapsedRowIds: new Set(),
  eventColorType: SchedulerEventColorType.TRADE,
  eventToHighlightRelationsFor: null,
  hideWeekends: false,
  scrollDate: null,
  scrollRowIdx: null,
  showDependencies: true,
  splitterWidth: 315,
  zoomPreset: getDefaultViewPreset(),
});

export type ScheduleViewStore = ReturnType<typeof useScheduleViewStore>;

export const useScheduleViewStore = defineStore(
  'schedule-view-store',
  () => {
    const globalStore = useGlobalStore();
    const wbsSectionStore = useWbsSectionStore();
    const { Routes, checkRoutesContain } = useRouteNames();
    const route = useRoute();
    const { assignShortcuts } = useScheduleViewShortcuts();
    const projectFilters = ref<Record<string, InternalFilterState>>({});
    const projectsSettings = ref<Record<string, ProjectSettings>>({});

    const locale = ref<Language>(getBrowserLanguage());
    const zoomPresets = ref<SchedulerViewPreset[]>([]);

    const projectId = computed(() => globalStore.scheduleProjectId ?? '');

    const rawScheduleViews = ref<CustomScheduleViewFragment[]>([]);
    const scheduleViewsLoading = ref(true);

    const scheduleViews = computed(() =>
      rawScheduleViews.value.map((view) => ({
        ...view,
        content: {
          ...view.content,
          wbsSections: getInverseSectionSelection(
            view.content.wbsSections,
            wbsSectionStore.wbsSectionsList,
          ),
        },
      })),
    );

    watchEffect(() => {
      assignShortcuts(scheduleViews.value);
    });

    const fetchUserScheduleViews = async () => {
      const client = useApolloClient();
      const response = await client.query({
        query: userScheduleViewsQuery,
        variables: { project: projectId.value },
        fetchPolicy: 'no-cache',
      });
      scheduleViewsLoading.value = false;
      if (!response.data.userScheduleViews) {
        rawScheduleViews.value = [];
      } else {
        rawScheduleViews.value = response.data.userScheduleViews.sort(
          (a, b) => a.position - b.position,
        );
      }

      return rawScheduleViews.value;
    };

    // compute here the inverse sections selection
    const createCustomScheduleView = (input: Omit<CreateCustomScheduleViewInput, 'project'>) => {
      if (input?.content?.wbsSections) {
        input.content.wbsSections = getInverseSectionSelection(
          input.content.wbsSections,
          wbsSectionStore.wbsSectionsList,
        );
      }

      const client = useApolloClient();

      return client
        .mutate({
          mutation: createCustomScheduleViewMutation,
          variables: { input: { ...input, project: projectId.value } },
        })
        .then((result) => {
          const createCustomScheduleView = result.data?.createCustomScheduleView || {};
          const { customScheduleView } = createCustomScheduleView;
          if (!customScheduleView) return null;

          rawScheduleViews.value.push(customScheduleView);

          return customScheduleView;
        });
    };

    const updateCustomScheduleView = (input: UpdateCustomScheduleViewInput) => {
      if (input?.content?.wbsSections) {
        input.content.wbsSections = getInverseSectionSelection(
          input.content.wbsSections,
          wbsSectionStore.wbsSectionsList,
        );
      }

      const client = useApolloClient();
      return client
        .mutate({
          mutation: updateCustomScheduleViewMutation,
          variables: { input },
        })
        .then((result) => {
          const updateCustomScheduleView = result.data?.updateCustomScheduleView || {};
          const { customScheduleView } = updateCustomScheduleView;
          if (!customScheduleView) return null;

          const index = rawScheduleViews.value.findIndex(
            (view) => view.id === customScheduleView.id,
          );

          if (index === -1) return null;

          rawScheduleViews.value[index] = customScheduleView;

          return customScheduleView;
        });
    };

    const moveScheduleView = async (input: { id: string; position: number }) => {
      const client = useApolloClient();
      await client.mutate({
        mutation: updateCustomScheduleViewMutation,
        variables: { input },
      });
    };

    const deleteCustomScheduleView = async (id: string) => {
      const client = useApolloClient();
      await client.mutate({
        mutation: deleteCustomScheduleViewMutation,
        variables: { input: { id } },
      });
    };

    watch(
      projectId,
      (newId) => {
        if (!projectsSettings.value[newId]) {
          projectsSettings.value[newId] = getDefaultProjectSettings();
        }

        if (!newId) return;

        if (route.name && checkRoutesContain(Routes.Lean, route.name)) {
          fetchUserScheduleViews();
        }
      },
      { immediate: true },
    );

    const currentFilter = computed(() => {
      return mapInternalFilterStateToScheduleFilterState(
        projectFilters.value[projectId.value] ?? getInitialInternalFilter(),
      );
    });

    const currentSettings = computed(
      () => projectsSettings.value[projectId.value] || getDefaultProjectSettings(),
    );

    const collapsedRowIds = computed(() => {
      // required for backward compatibility
      // Probably need a watch instead of computed
      if (!(currentSettings.value.collapsedRowIds instanceof Set)) {
        currentSettings.value.collapsedRowIds = new Set<string>();
      }
      return currentSettings.value.collapsedRowIds;
    });

    const eventColorType = computed(() => currentSettings.value.eventColorType);
    const eventToHighlightRelationsFor = computed(
      () => currentSettings.value.eventToHighlightRelationsFor,
    );
    const hideWeekends = computed(() => currentSettings.value.hideWeekends);
    const scrollDate = computed(() => currentSettings.value.scrollDate);
    const scrollRowIdx = computed(() => currentSettings.value.scrollRowIdx);
    const showDependencies = computed(() => currentSettings.value.showDependencies);
    const splitterWidth = computed(() => currentSettings.value.splitterWidth);
    const zoomPreset = computed(() => currentSettings.value.zoomPreset);

    const isFilterActive = computed(() => {
      return Boolean(
        currentFilter.value.sections.length ||
          currentFilter.value.trades.length ||
          currentFilter.value.contributorGroups.length,
      );
    });

    const appliedFilters = computed(() => {
      const filters: { filterKey: keyof ScheduleFilterState; i18nKey: string }[] = [];
      if (currentFilter.value.sections.length) {
        filters.push({
          filterKey: 'sections',
          i18nKey: 'Calendar.filterBanner.sections',
        });
      }
      if (currentFilter.value.trades.length) {
        filters.push({ filterKey: 'trades', i18nKey: 'Calendar.filterBanner.trades' });
      }
      if (currentFilter.value.contributorGroups.length) {
        filters.push({
          filterKey: 'contributorGroups',
          i18nKey: 'Calendar.filterBanner.company',
        });
      }
      return filters;
    });

    const existingScheduleViewsIcons = computed(() =>
      scheduleViews.value.map((view) => view.icon as ScheduleViewIconName),
    );

    const existingScheduleViewsNames = computed(() => scheduleViews.value.map((view) => view.name));

    const applyFilter = (state: ScheduleFilterState) => {
      if (isEqual(state, currentFilter.value)) return;

      projectFilters.value[projectId.value] = mapScheduleFilterStateToInternalFilterState(state);
    };

    const mapScheduleFilterStateToInternalFilterState = (
      state: ScheduleFilterState,
    ): InternalFilterState => {
      return {
        contributorGroups: state.contributorGroups,
        hiddenSections: getInverseSectionSelection(
          state.sections,
          useWbsSectionStore().wbsSectionsList,
        ),
        trades: state.trades,
      };
    };

    const mapInternalFilterStateToScheduleFilterState = (
      state: InternalFilterState,
    ): ScheduleFilterState => {
      return {
        contributorGroups: state.contributorGroups,
        sections: getInverseSectionSelection(
          state.hiddenSections,
          useWbsSectionStore().wbsSectionsList,
        ),
        trades: state.trades,
      };
    };

    const resetFilter = (key?: keyof ScheduleFilterState) => {
      applyFilter(
        key
          ? {
              ...currentFilter.value,
              [key]: getInitialScheduleFilter()[key],
            }
          : getInitialScheduleFilter(),
      );
    };

    const setEventColorType = (eventColorType: SchedulerEventColorType) => {
      const featureAccessStore = useFeatureAccessStore();

      const cannotEnterStatusView =
        (eventColorType === SchedulerEventColorType.STATUS &&
          !featureAccessStore.uiState.views.canEnterStatusView) ||
        (eventColorType === SchedulerEventColorType.BASE_ACTUAL &&
          !featureAccessStore.uiState.views.canEnterBasePlanView);

      const sanitizedEventColorType = cannotEnterStatusView
        ? SchedulerEventColorType.TRADE
        : eventColorType;

      currentSettings.value.eventColorType = sanitizedEventColorType;
      return sanitizedEventColorType;
    };

    const collapseStateForRows = (rowIds: string[]) => {
      rowIds.forEach((rowId) => {
        currentSettings.value.collapsedRowIds.add(rowId);
      });
    };

    const expandStateForRows = (rowIds: string[]) => {
      rowIds.forEach((rowId) => {
        currentSettings.value.collapsedRowIds.delete(rowId);
      });
    };

    const setEventIdToHighlightRelationsFor = (eventId: string | null) => {
      const scheduler = getScheduler();
      if (!scheduler) return;

      if (currentSettings.value.eventToHighlightRelationsFor === eventId) return;

      currentSettings.value.eventToHighlightRelationsFor = eventId;

      refreshScheduleUI(scheduler);
    };

    const setHideWeekends = (hideWeekends: boolean) => {
      currentSettings.value.hideWeekends = hideWeekends;
    };

    const setScrollDate = (scrollDate: number) => {
      currentSettings.value.scrollDate = scrollDate;
    };

    const setScrollRowIdx = (scrollRowIdx: number) => {
      currentSettings.value.scrollRowIdx = scrollRowIdx;
    };
    const setShowDependencies = (showDependencies: boolean) => {
      currentSettings.value.showDependencies = showDependencies;
    };
    const setSplitterWidth = (width: number) => {
      currentSettings.value.splitterWidth = width;
    };

    const setZoomPreset = (preset: string) => {
      currentSettings.value.zoomPreset = preset;
    };

    return {
      appliedFilters,
      currentFilter,
      collapsedRowIds,
      eventColorType,
      eventToHighlightRelationsFor,
      existingScheduleViewsIcons,
      existingScheduleViewsNames,
      hideWeekends,
      isFilterActive,
      locale,
      projectFilters,
      projectsSettings,
      scheduleViews,
      scheduleViewsLoading,
      scrollDate,
      scrollRowIdx,
      showDependencies,
      splitterWidth,
      zoomPreset,
      zoomPresets,
      applyFilter,
      collapseStateForRows,
      createCustomScheduleView,
      updateCustomScheduleView,
      deleteCustomScheduleView,
      moveScheduleView,
      expandStateForRows,
      fetchUserScheduleViews,
      resetFilter,
      setEventColorType,
      setEventIdToHighlightRelationsFor,
      setHideWeekends,
      setScrollDate,
      setScrollRowIdx,
      setShowDependencies,
      setSplitterWidth,
      setZoomPreset,
    };
  },
  {
    persist: {
      pick: ['projectFilters', 'projectsSettings'],
      storage: getReadonlyPiniaChildStorage(),
      serializer: {
        serialize: (rawStoreValue: unknown) => {
          const store = rawStoreValue as ScheduleViewState;

          const toPersist = {
            projectFilters: copy(store.projectFilters),
            projectsSettings: {},
          };

          Object.entries(store.projectsSettings).forEach(([projectId, settings]) => {
            toPersist.projectsSettings[projectId] = {
              ...copy(settings),
              collapsedRowIds: [...settings.collapsedRowIds],
            };
          });

          return JSON.stringify(toPersist);
        },

        deserialize: (rawSerializedValue: string) => {
          try {
            const parsed = JSON.parse(rawSerializedValue) as ScheduleViewState;

            Object.entries(parsed.projectFilters).forEach(([projectId, persistedFilters]) => {
              parsed.projectFilters[projectId] = {
                ...getInitialInternalFilter(),
                ...persistedFilters,
              };
            });

            Object.entries(parsed.projectsSettings).forEach(([projectId, persistedSettings]) => {
              parsed.projectsSettings[projectId] = {
                ...persistedSettings,
                collapsedRowIds: new Set(persistedSettings.collapsedRowIds),
              } as unknown as ProjectSettings;
            });

            return parsed;
          } catch {
            return { projectFilters: {}, projectsSettings: {} };
          }
        },
      },
    },
  },
);
