export const projectTemplateSelectors = {
  frameName: 'project-template-frame',
  table: 'project-templates-table',
  tableRow: (id: string) => `project-templates-table-row-${id}`,
  createButton: 'project-template-create-button',
  editProjectButton: (id: string) => `project-templates-table-edit-project-button-${id}`,
  editProjectDetailsButton: 'project-templates-table-edit-project-details-button',
  duplicate: {
    duplicateButton: (id: string) => `project-template-duplicate-button-${id}`,
  },
  delete: {
    deleteButton: (id: string) => `project-template-delete-button-${id}`,
    confirmButton: 'project-template-delete-confirm',
  },
  baseProjectSelectionModal: {
    projectItem: (id: string) => `project-template-base-project-selection-modal-project-item-${id}`,
    submit: 'project-template-base-project-selection-modal-submit',
  },
  detailsModal: {
    name: 'project-template-details-modal-name',
    description: 'project-template-details-modal-description',
    submit: 'project-template-details-modal-submit',
  },
};
