import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

export class ScheduleFilteredEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SCHEDULE_FILTERED;

  public constructor(
    public properties: {
      companiesSelected: number;
      tradesSelected: number;
      rowsSelected: number;
      rowsSelectable: number;
    },
  ) {
    super();
  }
}

export class ScheduleDependencyViewToggledEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.SCHEDULE_DEPENDENCY_VIEW_TOGGLED;

  public constructor(public properties: { show: boolean }) {
    super();
  }
}

export class ExportMenuOpenedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.EXPORT_MENU_OPENED;

  public constructor(
    public properties: {
      method: 'menu' | 'filters';
    },
  ) {
    super();
  }
}

export type PDFExportEventViewType = 'trade' | 'status' | 'base_actual';

export class PDFExportEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.EXPORT_PDF;

  public constructor(
    public properties: {
      orientation: string;
      paperFormat: string;
      timeSpanInDays: number;
      viewType: PDFExportEventViewType;
      hideDependencies: boolean;
      daysBetweenExportedRangeStartAndExportTime: number;
      allFilters: {
        filteredTimespanInDays: number;
        companiesSelected: number;
        tradesSelected: number;
        rowsSelected: number;
        rowsSelectable: number;
      };
      isSinglePage: boolean;
    },
  ) {
    super();
  }
}

export class XLSExportEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.EXPORT_EXCEL;
}

export class GanttExportEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.EXPORT_GANTT;

  public constructor(public properties: { type: 'ms_project' | 'powerproject' | 'p6' }) {
    super();
  }
}
