import { OperationNames } from 'events.schema';

import { ProjectEntity } from '@/common/types';
import { useNotificationStore } from '@/features/notifications/notificationStore';
import { isRealProject, LifecycleStatus } from '@/features/projects/projectUtils';

import { RTCClientResponseStatus } from '../../rtcClient/types';
import {
  BlockingInteraction,
  BlockingInteractionReason,
  RemoteProjectChangeEvent,
} from '../../types';

export class RTCBlockingInteractionHandler {
  public blockingInteraction = ref<BlockingInteraction | null>(null);

  public setBlockingInteractionForOutOfOrderEventsError(): void {
    this.setBlockingInteraction('INVALID_LOCAL_EVENT_ORDER');
  }

  public setBlockingInteractionForRestoredEntityNotFoundError(): void {
    this.setBlockingInteraction('RESTORED_ENTITY_NOT_FOUND');
  }

  public removeBlockingInteraction(): void {
    this.blockingInteraction.value = null;
  }

  /**
   * Checks if the event is a remote event that requires blocking interaction.
   * If the event is a remote event that requires blocking interaction, the blockingInteraction
   * ref will be set to the corresponding BlockingInteraction object.
   * @param event The remote event to check for blocking interaction.
   */
  public setBlockingInteractionForRemoteEvent(
    event: RemoteProjectChangeEvent,
    project: ProjectEntity | undefined,
  ): void {
    switch (event.operation.name) {
      case OperationNames.CompleteProjectSetup:
        this.setProjectSetupInteraction(event);
        break;
      case OperationNames.CompleteProjectSetupResult:
        this.setProjectSetupCompletedInteraction(event);
        break;
      case OperationNames.PublishProjectAlternative:
      case OperationNames.RestoreProjectVersion:
        this.setVersionChangeInteraction(event);
        break;
      case OperationNames.PublishProjectAlternativeResult:
      case OperationNames.RestoreProjectVersionResult:
        this.setVersionChangeCompletedInteraction(event);
        break;
      case OperationNames.DeleteProjectAlternative:
        this.setBlockingInteraction('PROJECT_ALTERNATIVE_DELETED', { user: event.user });
        break;
      case OperationNames.DeleteProject:
        this.setBlockingInteraction('PROJECT_DELETED', { user: event.user });
        break;
      case OperationNames.UpdateProjectStatus:
        this.setProjectUpdatedInteraction(event, project);
        break;
      default:
        break;
    }
  }

  /**
   * Similarly to the setBlockingInteractionForRemoteEvent method, this method checks if the event
   * requires a blocking interaction, but for the current user's own remote events.
   * @param event The remote event to check for blocking interaction.
   */
  public setBlockingInteractionForOwnRemoteEvent(event: RemoteProjectChangeEvent): void {
    if (event.operation.name === OperationNames.CompleteProjectSetup) {
      this.setProjectSetupInteraction(event);
    }
  }

  private setProjectSetupInteraction(event: RemoteProjectChangeEvent): void {
    if (!('projectTemplateId' in event.operation.input)) return;

    if (event.status === RTCClientResponseStatus.SUCCESS) {
      this.setBlockingInteraction('PROJECT_SETUP', { user: event.user });
    } else if (event.status === RTCClientResponseStatus.ERROR) {
      this.showErrorNotificationAndRemoveInteraction(
        'objects.realTimeCollaboration.projectSetupFailedTitle',
      );
    }
  }

  private setProjectSetupCompletedInteraction(event: RemoteProjectChangeEvent): void {
    if (!('status' in event.operation.input)) return;

    if (event.operation.input?.status === RTCClientResponseStatus.SUCCESS) {
      this.setBlockingInteraction('PROJECT_SETUP_COMPLETED', { user: event.user });
    } else {
      this.showErrorNotificationAndRemoveInteraction(
        'objects.realTimeCollaboration.projectSetupFailedTitle',
      );
    }
  }

  private setVersionChangeInteraction(event: RemoteProjectChangeEvent): void {
    if (event.status === RTCClientResponseStatus.SUCCESS) {
      this.setBlockingInteraction('SCHEDULE_VERSION_CHANGE', { user: event.user });
    } else if (event.status === RTCClientResponseStatus.ERROR) {
      this.showErrorNotificationAndRemoveInteraction(
        'objects.realTimeCollaboration.scheduleVersionChangeFailedTitle',
      );
    }
  }

  private setVersionChangeCompletedInteraction(event: RemoteProjectChangeEvent): void {
    if (!('status' in event.operation.input)) return;
    if (event.operation.input?.status === RTCClientResponseStatus.SUCCESS) {
      this.setBlockingInteraction('SCHEDULE_VERSION_CHANGE_COMPLETED', { user: event.user });
    } else if (event.operation.input?.status === RTCClientResponseStatus.ERROR) {
      this.showErrorNotificationAndRemoveInteraction(
        'objects.realTimeCollaboration.scheduleVersionChangeFailedTitle',
      );
    }
  }

  private setProjectUpdatedInteraction(
    event: RemoteProjectChangeEvent,
    project: ProjectEntity | undefined,
  ): void {
    if (!('status' in event.operation.input)) return;
    if (event.operation.input?.status === LifecycleStatus.ARCHIVED) {
      this.setBlockingInteraction('PROJECT_ARCHIVED', { user: event.user });
    }

    const existingStatus = (project?.lifecycleStatus ?? '') as LifecycleStatus;
    const newStatus = (event.operation.input?.status ?? '') as LifecycleStatus;

    if (!isRealProject(existingStatus) && isRealProject(newStatus)) {
      this.setBlockingInteraction('PROJECT_CONVERTED_TO_REAL', { user: event.user });
    }
  }

  public reset(): void {
    this.blockingInteraction.value = null;
  }

  private setBlockingInteraction(
    reason: BlockingInteractionReason,
    data?: Record<string, unknown> | null,
  ): void {
    this.blockingInteraction.value = {
      reason,
      data,
    };
  }

  private showErrorNotificationAndRemoveInteraction(titleI18nKey: string): void {
    const notificationStore = useNotificationStore();
    notificationStore.push({
      titleI18nKey,
      type: 'attention',
      iconProps: { icon: 'info-circle' },
    });
    this.removeBlockingInteraction();
  }
}
