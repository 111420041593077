import { useQuery } from '@vue/apollo-composable';
import { Ref, ref, SetupContext, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useGlobalStore } from '@/common/globalStore';
import { GanttExportEvent, XLSExportEvent } from '@/features/filter';
import { useNotificationStore } from '@/features/notifications';
import { useScheduleExportStore } from '@/features/scheduleExport/scheduleExportStore';
import { useScheduleViewStore } from '@/features/scheduleViews/scheduleViewStore';
import {
  AsyncProjectExportResult,
  ExportProjectMutation,
  ExportProjectMutationVariables,
  FileContent,
  ProjectExportQuery,
  ProjectExportQueryVariables,
  SyncProjectExportResult,
} from '@/graphql/__generated__/graphql';
import CREATE_PROJECT_EXPORT from '@/graphql/leanProject/export/Create.gql';
import FetchProjectExport from '@/graphql/leanProject/export/Detail.gql';
import { useIocProvider } from '@/ioc/injectKey';
import { useApolloClient } from '@/plugins/apollo';
import { getDisplayedSchedulerSections } from '@/services/store/schedule/actions/filter';
import { useUserStore } from '@/services/store/user';
import { useFeatureToggles } from '@/utils/featureToggles';

import { ExportKey } from '../types';

enum ThirdPartyExportStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

enum ThirdPartyExportFormat {
  EXCEL = 'XLSX',
  MS_PROJECT = 'MPP',
  POWER_PROJECT = 'POWER_PROJECT',
  PRIMAVERA_P6 = 'P6',
}

const exportFormatMap: { [key in Exclude<ExportKey, ExportKey.PDF>]: ThirdPartyExportFormat } = {
  [ExportKey.EXCEL]: ThirdPartyExportFormat.EXCEL,
  [ExportKey.MS_PROJECT]: ThirdPartyExportFormat.MS_PROJECT,
  [ExportKey.POWER_PROJECT]: ThirdPartyExportFormat.POWER_PROJECT,
  [ExportKey.PRIMAVERA_P6]: ThirdPartyExportFormat.PRIMAVERA_P6,
};

export interface UseThirdPartyExportErrorHandling {
  setError: () => void;
}

export interface UseThirdPartyExport extends UseThirdPartyExportErrorHandling {
  onClose: () => void;
  onExport: (exportType: ExportKey | null) => void;
  loading: Ref<boolean>;
}

/**
 * Provides functionality to export a project to different non-pdf formats
 */
export function useThirdPartyExport(
  context: SetupContext,
  projectName: Ref<string | null>,
): UseThirdPartyExport {
  const globalStore = useGlobalStore();
  const i18n = useI18n();
  const client = useApolloClient();
  const { fileDownloadService, loggingService } = useIocProvider();
  const featureToggles = useFeatureToggles();
  const userStore = useUserStore();

  const pushNotificationStore = useNotificationStore();

  const setError = () => {
    pushNotificationStore.showAttentionNotification({
      titleI18nKey: 'Calendar.export.failed',
    });
  };

  const loading = ref(false);

  const exportId = ref('');
  const enableQuery = ref(false);
  const format = ref('');

  const { result: exportResult } = useQuery<ProjectExportQuery, ProjectExportQueryVariables>(
    FetchProjectExport,
    () => ({ id: exportId.value }),
    { enabled: enableQuery, pollInterval: 1000 },
  );

  function onClose(): void {
    context.emit('close');
  }

  const finishLoading = () => {
    loading.value = false;
  };

  function onDownload(file: FileContent): void {
    const name = `${projectName.value}_${i18n
      .d(new Date(), 'dateDMY')
      .toString()
      .replaceAll(/[^0-9]+/gi, '-')}.${format.value.toLowerCase()}`;

    fileDownloadService
      .download(
        {
          url: file.downloadUrl || file.url,
          name,
        },
        false,
      )
      .then(() => {
        context.emit('done');
      })
      .catch(() => {
        context.emit('error');
      })
      .finally(() => {
        finishLoading();
      });
  }

  function onExport(exportType: ExportKey | null): void {
    if (!exportType) {
      return;
    }
    loading.value = true;
    const exportFormat = exportFormatMap[exportType];
    format.value = exportFormat;
    context.emit('load');

    let analyticsEvent: XLSExportEvent | GanttExportEvent;
    switch (exportFormat) {
      case ThirdPartyExportFormat.EXCEL:
        analyticsEvent = new XLSExportEvent();
        break;
      case ThirdPartyExportFormat.MS_PROJECT:
        analyticsEvent = new GanttExportEvent({ type: 'ms_project' });
        break;
      case ThirdPartyExportFormat.POWER_PROJECT:
        analyticsEvent = new GanttExportEvent({ type: 'powerproject' });
        break;
      case ThirdPartyExportFormat.PRIMAVERA_P6:
      default:
        analyticsEvent = new GanttExportEvent({ type: 'p6' });
        break;
    }
    loggingService.trackEvent(analyticsEvent);

    let exportFilter: ExportProjectMutationVariables['input']['filter'] = undefined;

    if (featureToggles.enableBackendExportFilter(userStore.ownUser!)) {
      const displayedSections = getDisplayedSchedulerSections();

      const scheduleViewStore = useScheduleViewStore();
      const { exportDateRange } = useScheduleExportStore();
      if (scheduleViewStore.isFilterActive || exportDateRange) {
        exportFilter = {};

        if (exportDateRange) {
          exportFilter.period = {
            from: exportDateRange.start,
            to: exportDateRange.end,
          };
        }

        if (scheduleViewStore.currentFilter.contributorGroups.length) {
          exportFilter.contributorGroups = scheduleViewStore.currentFilter.contributorGroups;
        }

        if (scheduleViewStore.currentFilter.trades.length) {
          exportFilter.tenantTradeVariations = scheduleViewStore.currentFilter.trades;
        }

        exportFilter.wbsSections = displayedSections.map((s) => s.id);
      }
    }

    const mutation = client.mutate<ExportProjectMutation, ExportProjectMutationVariables>({
      mutation: CREATE_PROJECT_EXPORT,
      variables: {
        input: {
          project: globalStore.scheduleProjectId!,
          format: exportFormat,
          filter: exportFilter,
        },
      },
    });
    mutation
      .then((result) => {
        if (!result) {
          loading.value = false;
          return;
        }

        const { data } = result;
        if (data && (data?.exportProject as SyncProjectExportResult)?.file) {
          onDownload((data.exportProject as SyncProjectExportResult).file);
        } else {
          exportId.value = (data?.exportProject as AsyncProjectExportResult).exportId ?? '';
          enableQuery.value = true;
        }
      })
      .catch(() => {
        loading.value = false;
        setError();
      });
  }

  watch(exportResult, () => {
    if (!exportResult.value) return;

    if (exportResult.value.projectExport?.status === ThirdPartyExportStatus.ERROR) {
      loading.value = false;
      enableQuery.value = false;
      setError();
    } else if (
      exportResult.value.projectExport?.status === ThirdPartyExportStatus.COMPLETED &&
      exportResult.value.projectExport?.file
    ) {
      enableQuery.value = false;
      exportId.value = '';
      onDownload(exportResult.value.projectExport.file);
    }
  });

  return {
    onClose,
    onExport,
    loading,
    setError,
  };
}
