import { defineStore } from 'pinia';
import { Ref } from 'vue';

import {
  Entity,
  InteractiveEntityChanges,
  MilestoneEntity,
  OrderDependencyEntity,
  PartialEntity,
  WrapRef,
} from '@/common/types';
import { useOrderDependencyStore } from '@/features/orderDependencies';
import { useRTCController } from '@/features/realTimeCollaboration';
import {
  OperationInputType,
  ProjectChangeEventContext,
} from '@/features/realTimeCollaboration/types';
import { pushLocalChangeEventAndCommit } from '@/features/realTimeCollaboration/utils';
import { EVENT_LAYOUT_STACK } from '@/features/schedule/bryntum/resources/const';
import { UndoRedoCommit } from '@/features/undoRedo/types';
import { ProjectMilestoneFragment } from '@/graphql/__generated__/graphql';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';
import { getMainResourceId } from '@/services/store/schedule/parsers/base';
import { SchedulerResource } from '@/services/store/schedule/types';

import { MilestoneStore, MilestoneType } from '../types';
import {
  createUndoRedoCreateCommit,
  createUndoRedoDeleteCommit,
  createUndoRedoRestoreCommit,
  createUndoRedoUpdateCommit,
} from './milestoneCommits';
import {
  createProjectChangeCreateEvent,
  createProjectChangeDeleteEvent,
  createProjectChangeRestoreEvent,
  createProjectChangeUpdateEvent,
  createProjectChangeUpdateMilestoneStatusEvent,
} from './milestoneEvents';
import { milestoneQuery } from './milestoneGql';

export const useMilestoneStore = defineStore(
  'milestone-store',
  (): WrapRef<
    MilestoneStore,
    | 'milestones'
    | 'milestoneList'
    | 'fetchAllPromise'
    | 'overlappingMilestonesByDateAndResource'
    | 'resourcesWithOverlappingMilestones'
  > => {
    const milestones = ref(new Map<string, MilestoneEntity>());
    const deletedMilestones = ref(
      new Map<string, { milestone: MilestoneEntity; dependencies: string[] }>(),
    );
    const milestoneList = computed(() => Array.from(milestones.value.values()));

    const overlappingMilestonesByDateAndResource = computed(() => {
      return milestoneList.value.reduce<Record<string, number>>((acc, milestone) => {
        const key = generateDateIdKey(milestone.date, milestone.wbsSection?.id ?? '');
        acc[key] = (acc[key] ?? 0) + 1;
        return acc;
      }, {});
    });

    const resourcesWithOverlappingMilestones = computed(() => {
      const ids = new Set<string>();
      for (const [date, count] of Object.entries(overlappingMilestonesByDateAndResource.value)) {
        if (count === 0) continue;

        // This assumes generateDateIdKey separates with '_'
        const id = date.split('_', 1)[0];

        if (id) {
          ids.add(id);
        }
      }
      return ids;
    });

    const create = async (
      vars: MilestoneEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeCreateEvent(vars, context);
      const commit = createUndoRedoCreateCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStore().copyState(),
      });
    };

    const update = async (
      vars: PartialEntity<MilestoneEntity>[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeUpdateEvent(vars, context);
      const commit = createUndoRedoUpdateCommit(vars, milestones.value);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStore().copyState(),
      });
    };

    const restore = async (
      vars: MilestoneEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const restoredAdjacentDependencies = vars.flatMap((milestone) => {
        const deletedDependencies = deletedMilestones.value.get(milestone.id)?.dependencies ?? [];
        return deletedDependencies
          .map((dependencyId) => useOrderDependencyStore().dependencies.get(dependencyId))
          .filter(Boolean) as OrderDependencyEntity[];
      });

      const event = createProjectChangeRestoreEvent(vars, context, restoredAdjacentDependencies);
      const commit = createUndoRedoRestoreCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStore().copyState(),
      });
    };

    const remove = async (
      vars: MilestoneEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeDeleteEvent(vars, context);
      const commit = createUndoRedoDeleteCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStore().copyState(),
      });
    };

    const updateMilestoneStatus = async (
      vars: OperationInputType<'UpdateMilestoneStatus'>,
      context?: ProjectChangeEventContext,
    ) => {
      const event = createProjectChangeUpdateMilestoneStatusEvent(vars, context);

      return useRTCController().pushLocalProjectChangeEvent(event);
    };

    const fetchAllPromise: Ref<Promise<MilestoneEntity[]> | null> = ref(null);

    const fetchAll = async (projectId: string): Promise<MilestoneEntity[]> => {
      const client = useApolloClient();

      fetchAllPromise.value = client
        .query({
          query: milestoneQuery,
          variables: {
            project: projectId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          const newMilestones = flattenNodeConnection<ProjectMilestoneFragment>(
            result.data.projectMilestones,
          ).map((data) => ({
            ...data,
            date: new SchedulingDate(data.date),
            completedAt: typeof data.completedAt === 'string' ? new Date(data.completedAt) : null,
            type: data.isFixed ? MilestoneType.FIXED : MilestoneType.FLEXIBLE,
          }));
          milestones.value = new Map(newMilestones.map((milestone) => [milestone.id, milestone]));
          return newMilestones;
        });
      return fetchAllPromise.value;
    };

    const reset = () => {
      milestones.value = new Map();
      fetchAllPromise.value = null;
    };

    const setState = (state?: Map<string, MilestoneEntity>) => {
      if (!state) {
        return;
      }
      state.forEach((milestone) => {
        if (deletedMilestones.value.has(milestone.id)) {
          const previousResult = deletedMilestones.value.get(milestone.id)!;
          deletedMilestones.value.set(milestone.id, { ...previousResult, milestone });
        }
      });
      milestones.value.forEach((milestone) => {
        const dependencyStore = useOrderDependencyStore();
        if (!state.has(milestone.id)) {
          const relatedDependencies = dependencyStore.getDependenciesPartiallyRelatedToEntityIds(
            new Set([milestone.id]),
          );
          deletedMilestones.value.set(milestone.id, {
            milestone,
            dependencies: relatedDependencies.map((d) => d.id),
          });
          relatedDependencies.forEach((dependency) => {
            dependencyStore.setSoftDeletedEntity(dependency);
          });
        }
      });
      milestones.value = new Map(state);
    };

    const applyChanges = (changes: {
      add?: MilestoneEntity[];
      update?: PartialEntity<MilestoneEntity>[];
      delete?: Entity[];
    }): void => {
      changes.add?.forEach((milestone) => {
        milestones.value.set(milestone.id, milestone);
      });
      changes.update?.forEach((milestone) => {
        milestones.value.set(milestone.id, {
          ...milestones.value.get(milestone.id)!,
          ...milestone,
        });
        if (deletedMilestones.value.has(milestone.id)) {
          const previousResult = deletedMilestones.value.get(milestone.id)!;
          deletedMilestones.value.set(milestone.id, {
            ...previousResult,
            milestone: { ...previousResult.milestone, ...milestone },
          });
        }
      });
      changes.delete?.forEach((milestone) => {
        const existingMilestone = milestones.value.get(milestone.id);
        const dependencyStore = useOrderDependencyStore();
        if (existingMilestone) {
          const relatedDependencies = dependencyStore.getDependenciesPartiallyRelatedToEntityIds(
            new Set([milestone.id]),
          );
          deletedMilestones.value.set(milestone.id, {
            milestone: existingMilestone,
            dependencies: relatedDependencies.map((d) => d.id),
          });
          relatedDependencies.forEach((dependency) => {
            dependencyStore.setSoftDeletedEntity(dependency);
          });
          milestones.value.delete(milestone.id);
        }
      });
    };

    const copyState = () => {
      return new Map(milestones.value);
    };

    const getSoftDeletedEntity = (
      id: string,
    ): { milestone: MilestoneEntity; dependencies: string[] } | undefined => {
      return deletedMilestones.value.get(id);
    };

    const setSoftDeletedEntity = (id: string, dependency?: string): void => {
      const existingMilestone = deletedMilestones.value.get(id);
      if (!existingMilestone) return;
      deletedMilestones.value.set(id, {
        milestone: existingMilestone.milestone,
        dependencies: dependency
          ? [...existingMilestone.dependencies, dependency]
          : existingMilestone.dependencies,
      });
    };

    const checkDateHasOverlappingMilestones = (date: Date, resource: SchedulerResource) => {
      return getNumberOfOverlappingMilestonesForDate(date, resource) > 1;
    };

    const getNumberOfOverlappingMilestonesForDate = (date: Date, resource: SchedulerResource) => {
      const numOfOverlappingMilestones =
        overlappingMilestonesByDateAndResource.value[generateDateIdKey(date, resource.id)] ?? 0;

      const result =
        resource.id !== getMainResourceId() && resource.eventLayout !== EVENT_LAYOUT_STACK
          ? numOfOverlappingMilestones
          : 0;
      return result;
    };

    const generateDateIdKey = (date: Date, id: string) => {
      return `${id}_${date.toISOString()}`;
    };

    return {
      milestones,
      milestoneList,
      overlappingMilestonesByDateAndResource,
      resourcesWithOverlappingMilestones,
      create,
      update,
      restore,
      delete: remove,
      updateMilestoneStatus,
      fetchAll,
      fetchAllPromise,
      applyChanges,
      copyState,
      setState,
      reset,
      getSoftDeletedEntity,
      setSoftDeletedEntity,
      checkDateHasOverlappingMilestones,
      getNumberOfOverlappingMilestonesForDate,
    };
  },
);
