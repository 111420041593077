<template>
  <!-- eslint-disable-next-line vuejs-accessibility/label-has-for  -->
  <component
    :is="is"
    class="ds-field"
    :class="{ '--destructive': destructive || !!errorMessage }"
    v-bind="$attrs"
    :data-testid="dsFieldSelectors.field"
  >
    <span v-if="label || $slots.label" class="ds-field-label" :data-testid="dsFieldSelectors.label">
      <slot name="label">{{ label }}</slot>
    </span>
    <span
      v-if="subtitle || $slots.subtitle"
      class="ds-field-subtitle"
      :data-testid="dsFieldSelectors.subtitle"
    >
      <slot name="subtitle">{{ subtitle }}</slot>
    </span>
    <slot>
      <DsInput
        v-bind="$attrs"
        :model-value="modelValue"
        :copyable="copyable"
        :destructive="destructive || !!errorMessage"
        :autofocus="autofocus"
        :disabled="disabled"
        :readonly="readonly"
        :data-testid="dataTestid || dsFieldSelectors.input"
        :append-icon="appendIcon"
        :prepend-icon="prependIcon"
        @update:model-value="emit('update:model-value', $event)"
        @click-append-icon="$emit('click-append-icon')"
      />
    </slot>
    <span v-if="hint || $slots.hint" class="ds-field-hint" :data-testid="dsFieldSelectors.hint">
      <slot name="hint">{{ hint }}</slot>
    </span>
    <span
      v-if="warning || $slots.warning"
      class="ds-field-warning"
      :data-testid="dsFieldSelectors.warning"
    >
      <slot name="warning">{{ warning }}</slot>
    </span>
    <span
      v-if="errorMessage || $slots.errorMessage"
      class="ds-field-error"
      :data-testid="dsFieldSelectors.error"
    >
      <slot name="errorMessage">{{ errorMessage }}</slot>
    </span>
  </component>
</template>
<script setup lang="ts">
import { SvgIconType } from '@/assets/generated/svgIcons/_types';

import { dsFieldSelectors } from './DsField.selectors';
import { InputModelValue, inputProps } from './utils/input';

defineProps({
  ...inputProps(),
  disabled: { type: Boolean, default: false },
  destructive: { type: Boolean, default: false },
  hint: { type: String, default: '' },
  warning: { type: String, default: '' },
  is: { type: String, default: 'label' },
  label: { type: String, default: '' },
  autofocus: { type: Boolean, default: false },
  copyable: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  dataTestid: { type: String, default: '' },
  subtitle: { type: String, default: '' },
  appendIcon: {
    type: String as PropType<SvgIconType | null>,
    default: null,
  },
  prependIcon: {
    type: String as PropType<SvgIconType | null>,
    default: null,
  },
});

const emit = defineEmits<{
  (ev: 'update:model-value', value: InputModelValue): void;
  (ev: 'click-append-icon'): void;
}>();
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style scoped>
.ds-field {
  @apply tw-block;
}
.ds-field-label {
  @apply tw-block tw-mb-1 tw-ds-text-sm--medium tw-text-secondary;
}
.ds-field-subtitle {
  @apply tw-block tw-mb-1 tw-ds-text-sm tw-text-cool-grey-500;
}
.ds-field-hint {
  @apply tw-block tw-mt-1 tw-ds-text-sm tw-text-cool-grey-500;
}
.ds-field-warning {
  @apply tw-block tw-mt-1 tw-ds-text-sm tw-text-warning-500;
}
.ds-field-error {
  @apply tw-block tw-mt-1 tw-ds-text-sm tw-text-attention-500;
}

.ds-field.--destructive .ds-field-hint {
  @apply tw-text-attention-500;
}
</style>
