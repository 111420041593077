<template>
  <div class="project-template-mode-banner">
    <div class="tw-flex tw-items-center tw-gap-4">
      <DsTooltip
        v-slot="tooltipProps"
        :text="$t('projectTemplates.backToTemplates')"
        position="bottom-left"
      >
        <a
          v-bind="tooltipProps"
          class="tw-inline-flex tw-w-11 tw-h-11 tw-items-center tw-justify-center hover:tw-bg-[rgba(255,255,255,0.2)] tw-rounded-xs"
          role="button"
          :href="templateUrl"
          :aria-label="$t('projectTemplates.backToTemplates')"
        >
          <DsSvgIcon icon="arrow-narrow-left" size="lg" />
        </a>
      </DsTooltip>
      <div>
        <h4 class="tw-ds-h4">
          {{ $t(titleKey) }}
        </h4>
        <p v-if="subtitleKey" class="tw-ds-text-xs">{{ $t(subtitleKey) }}</p>
      </div>
    </div>
    <ProjectTemplatesDetailsEditButtonWithModal v-if="isInEditMode" />
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';

import { useProjectTemplatesStore } from '@/features/projectTemplates';
import { useRouteNames } from '@/router/helpers/routeNames';

import ProjectTemplatesDetailsEditButtonWithModal from './ProjectTemplatesDetailsEditButtonWithModal.vue';

const router = useRouter();
const { Routes } = useRouteNames();
const projectTemplatesStore = useProjectTemplatesStore();

const isInEditMode = computed(() => projectTemplatesStore.mode === 'edit');
const templateUrl = computed(() => router.resolve({ name: Routes.Tenant.ProjectTemplates }).href);

const titleKey = computed(() => {
  return isInEditMode.value
    ? 'projectTemplates.bannerEditModeTitle'
    : 'projectTemplates.bannerPreviewModeTitle';
});
const subtitleKey = computed(() => {
  return projectTemplatesStore.mode === 'edit' ? 'projectTemplates.bannerEditModeSubtitle' : null;
});
</script>

<style scoped>
.project-template-mode-banner {
  @apply tw-flex tw-justify-between tw-items-center;
  @apply tw-w-full tw-bg-petrol-400 tw-text-white;
  @apply tw-p-4 tw-px-2;
}
</style>
