import { checkWinAltOrMacOptionPressed, checkWinCtrlOrMacCmdKeyPressed } from '@/helpers/utils/os';

let listenersAttached = false;

const isCtrlKeyPressed = ref(false);
const isMetaKeyPressed = ref(false);
const isShiftKeyPressed = ref(false);
const isWinCtrlMacCmdPressed = ref(false);
const isWinAltMacOptionPressed = ref(false);
const isCtrlCmdOrShiftKeyPressed = computed(() => {
  return isCtrlKeyPressed.value || isMetaKeyPressed.value || isShiftKeyPressed.value;
});

export const useModifierKeys = () => {
  registerListeners();

  return {
    isCtrlKeyPressed,
    isMetaKeyPressed,
    isShiftKeyPressed,
    isWinCtrlMacCmdPressed,
    isWinAltMacOptionPressed,
    isCtrlCmdOrShiftKeyPressed,
  };
};

const registerListeners = () => {
  if (listenersAttached) return;

  window.addEventListener('keydown', setModifierKeys, { passive: true });
  window.addEventListener('keyup', setModifierKeys, { passive: true });

  listenersAttached = true;
};

function setModifierKeys(event: KeyboardEvent) {
  isCtrlKeyPressed.value = checkCtrlKeyPressed(event);
  isMetaKeyPressed.value = checkMetaKeyPressed(event);
  isShiftKeyPressed.value = checkShiftKeyPressed(event);
  isWinCtrlMacCmdPressed.value = checkWinCtrlOrMacCmdKeyPressed(event);
  isWinAltMacOptionPressed.value = checkWinAltOrMacOptionPressed(event);
}

export function checkShiftKeyPressed(event: MouseEvent | KeyboardEvent): boolean {
  return event.shiftKey;
}

export function checkMetaKeyPressed(event: MouseEvent | KeyboardEvent): boolean {
  return event.metaKey;
}

export function checkCtrlKeyPressed(event: MouseEvent | KeyboardEvent): boolean {
  return event.ctrlKey;
}
