import { defineStore } from 'pinia';
import { useRoute, useRouter } from 'vue-router';

import {
  CustomFeatureAccess,
  FeatureDenyReason,
  useFeatureAccessStore,
} from '@/common/featureAccessStore';
import { fetchBaseProjectsQuery } from '@/features/projects/projectGql';
import {
  createProjectTemplateMutation,
  deleteProjectTemplateMutation,
  editProjectTemplateMutation,
  fetchProjectTemplateQuery,
  fetchProjectTemplatesQuery,
  projectTemplateCreateTaskQuery,
} from '@/features/projectTemplates/projectTemplatesGql';
import { TemplateMode } from '@/features/projectTemplates/types';
import { useParentMessageListener } from '@/globalMessageListener';
import {
  BaseProjectFragment,
  CreateProjectTemplateInput,
  ProjectTemplateFragment,
  ProjectTemplatePreviewFragment,
  ProjectTemplateQueryVariables,
  ProjectTemplatesQueryVariables,
  UpdateProjectTemplateInput,
} from '@/graphql/__generated__/graphql';
import { sleep } from '@/helpers/async';
import { copy } from '@/helpers/utils/objects';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';
import { useRouteNames } from '@/router/helpers/routeNames';
import { checkHostIsIFrame } from '@/utils/iframe';

import { useRTCController } from '../realTimeCollaboration';

export const useProjectTemplatesStore = defineStore('project-templates-store', () => {
  const route = useRoute();
  const router = useRouter();
  const rtcController = useRTCController();

  const { Routes, checkRoutesContain } = useRouteNames();

  const isInitialized = ref(false);
  const templatesLoading = ref(false);
  const baseProjectsLoading = ref(false);
  const templates = ref<Map<string, ProjectTemplatePreviewFragment>>(new Map());
  const baseProjects = ref<BaseProjectFragment[]>([]);
  const isInProjectTemplateMode = ref(false);
  const mode = ref<TemplateMode>('preview');

  const templatesSortedByNameAsc = computed(() => {
    return Array.from(templates.value.values()).sort((a, b) => a.name.localeCompare(b.name));
  });

  useParentMessageListener((message) => {
    if (message.type === 'DISPLAY_TEMPLATE') {
      displayTemplate(message.data);
    }
  });

  watchEffect(async () => {
    isInProjectTemplateMode.value = checkRoutesContain(Routes.ProjectTemplate, route.name);
    if (isInProjectTemplateMode.value) {
      await fetchCurrentProjectTemplate({ id: route.params.templateId as string });
      mode.value = route.query.mode === 'edit' ? 'edit' : 'preview';
      useFeatureAccessStore().setCustomAccess(
        getProjectTemplateFeatureAccess(mode.value === 'edit'),
      );
      if (checkHostIsIFrame()) {
        rtcController.startIgnoringEvents();
      }
    }
  });

  const currentProjectTemplate = ref<ProjectTemplateFragment | null>(null);
  function fetchCurrentProjectTemplate(vars: ProjectTemplateQueryVariables) {
    const client = useApolloClient();

    return client
      .query({
        query: fetchProjectTemplateQuery,
        variables: vars,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const { projectTemplate } = result.data;

        currentProjectTemplate.value = projectTemplate ?? null;

        return projectTemplate;
      });
  }

  function fetchBaseProjects(vars: { tenant: string }) {
    baseProjectsLoading.value = true;
    const client = useApolloClient();
    return client
      .query({
        query: fetchBaseProjectsQuery,
        variables: vars,
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        const flattenedProjects = flattenNodeConnection(response.data.projects).sort((a, b) =>
          a.name.localeCompare(b.name),
        );

        baseProjects.value = flattenedProjects;

        return flattenedProjects;
      })
      .finally(() => {
        baseProjectsLoading.value = false;
      });
  }

  function fetchProjectTemplates(vars: ProjectTemplatesQueryVariables) {
    templatesLoading.value = true;

    const client = useApolloClient();

    return client
      .query({
        query: fetchProjectTemplatesQuery,
        variables: vars,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const projectTemplates = flattenNodeConnection(result.data.projectTemplates);

        templates.value = new Map(projectTemplates.map((template) => [template.id, template]));

        return projectTemplates;
      })
      .finally(() => {
        templatesLoading.value = false;
      });
  }

  const createTemplate = async (input: CreateProjectTemplateInput) => {
    const client = useApolloClient();

    const result = await client.mutate({
      mutation: createProjectTemplateMutation,
      variables: { input },
    });

    const taskId = result?.data?.createProjectTemplate?.taskId;

    if (!taskId) throw new Error('Failed to create project template.');

    const INITIAL_DELAY_MS = 1000;
    const POLL_INTERVAL_MS = 1000;

    // Wait some time for processing of task to start
    await sleep(INITIAL_DELAY_MS);

    while (true) {
      const response = await client.query({
        query: projectTemplateCreateTaskQuery,
        variables: { taskId },
        fetchPolicy: 'no-cache',
      });
      const taskDetails = response.data.projectTemplateCreateTask;
      if (!taskDetails) {
        throw new Error(`Failed to fetch task details for task: ${taskId}`);
      }
      if (taskDetails.status === 'ERROR') {
        throw new Error('Failed to create project template.');
      }

      if (taskDetails.status === 'COMPLETED') {
        const template = response.data.projectTemplateCreateTask?.template;
        if (template) {
          templates.value.set(template.id, template);
        }

        return template;
      }

      await sleep(POLL_INTERVAL_MS);
    }
  };

  const editCurrentTemplate = async (
    input: Pick<UpdateProjectTemplateInput, 'name' | 'description'>,
  ) => {
    const client = useApolloClient();

    if (!currentProjectTemplate.value) return;

    const backup = copy(currentProjectTemplate.value);

    currentProjectTemplate.value = {
      ...currentProjectTemplate.value,
      name: input.name ?? currentProjectTemplate.value.name,
      description: input.description ?? currentProjectTemplate.value.description,
    };
    await client
      .mutate({
        mutation: editProjectTemplateMutation,
        variables: { input: { ...input, id: currentProjectTemplate.value.id } },
      })
      .catch(() => {
        currentProjectTemplate.value = backup;
      });
  };

  const deleteTemplate = async (templateId: string) => {
    const client = useApolloClient();

    const result = await client.mutate({
      mutation: deleteProjectTemplateMutation,
      variables: { input: { id: templateId } },
    });

    if (!result?.data?.deleteProjectTemplate?.success) {
      throw new Error('Failed to delete project template.');
    }

    templates.value.delete(templateId);
  };

  const initialize = (tenantId: string) => {
    return fetchProjectTemplates({ tenant: tenantId }).finally(() => {
      isInitialized.value = true;
    });
  };

  const displayTemplate = async (template: {
    tenantId: string;
    projectQueryId: string;
    templateId: string;
  }) => {
    const { tenantId, projectQueryId, templateId } = template;

    await router.push({
      name: Routes.ProjectTemplate.Schedule,
      params: {
        tenantId: tenantId,
        id: projectQueryId,
        templateId: templateId,
        // We specifically clear the objectId to avoid opening an empty resource
        objectId: '',
      },
    });
  };

  return {
    currentProjectTemplate,
    isInitialized,
    isInProjectTemplateMode,
    templatesLoading,
    baseProjectsLoading,
    mode,
    templates,
    baseProjects,
    templatesSortedByNameAsc,
    fetchBaseProjects,
    fetchCurrentProjectTemplate,
    fetchProjectTemplates,
    createTemplate,
    editCurrentTemplate,
    deleteTemplate,
    initialize,
  };
});

function getProjectTemplateFeatureAccess(editMode = false): CustomFeatureAccess {
  const isHostIFrame = checkHostIsIFrame();
  return {
    id: 'project-template',
    reason: FeatureDenyReason.PROJECT_TEMPLATE,
    uiState: {
      enableExports: {
        pdf: !isHostIFrame,
        gantt: !isHostIFrame,
        xlsx: !isHostIFrame,
      },
      filters: {
        showContributorGroupFilter: false,
      },
      milestones: {
        showStatusDetails: false,
      },
      multiSelectToolbar: {
        enableStatusReport: false,
      },
      orders: {
        showProgressIndicator: false,
        showStatusDetails: false,
      },
      pauses: {
        showMainClickListItem: false,
      },
      projects: {
        canChangeAddress: false,
      },
      scheduleViews: {
        showScheduleViews: false,
        canModifyScheduleViews: false,
      },
      showCollisionNotifications: !isHostIFrame,
      showCollisions: !isHostIFrame,
      showNonWorkingTimeResolutionExceptionCreateAction: false,
      views: {
        canEnterBasePlanView: false,
        canEnterStatusView: false,
      },
    },
    features: {
      BASE_PLAN: {
        write: false,
        read: false,
      },
      // change this to non refs
      SCHEDULE_PLANNING: {
        write: editMode,
      },
      ORDER_PLANNING: {
        write: editMode,
      },
      TRADE_SEQUENCES: {
        write: editMode,
        read: true,
      },
      TENANT_TRADE_SEQUENCE_TEMPLATES: {
        read: false,
        write: false,
      },
      DEPENDENCY_PLANNING: {
        write: editMode,
      },
      MILESTONE_REPORTING: {
        write: false,
      },
      ORDER_DOCUMENTS: {
        write: false,
        read: false,
      },
      PLANNING_MODE: {
        write: false,
        read: false,
      },
      PROGRESS_REPORTING: {
        write: false,
        read: false,
      },
      TICKETS: {
        write: false,
        read: false,
      },
      TICKET_REPORTING: {
        write: false,
        read: false,
      },
      VERSIONING: {
        write: false,
        read: false,
      },
    },
  };
}
