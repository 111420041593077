import {
  createProjectAlternativeAsyncMutation,
  projectAlternativeCreateTaskQuery,
  projectAlternativesQuery,
  updateProjectAlternativeMutation,
} from '@/features/projectAlternatives/store/projectAlternativeGql';
import {
  CreateProjectAlternativeInput,
  QueryProjectAlternativesArgs,
  UpdateProjectAlternativeInput,
} from '@/graphql/__generated__/graphql';
import { sleep } from '@/helpers/async';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';

const initialAsyncDelayMs = 1000;
const asyncPollIntervalMs = 1000;

export class ProjectAlternativeStoreRepository {
  public async create(input: CreateProjectAlternativeInput) {
    const client = useApolloClient();

    const result = await client.mutate({
      mutation: createProjectAlternativeAsyncMutation,
      variables: { input },
    });

    const taskId = result?.data?.createProjectAlternativeAsync?.taskId;

    if (!taskId) throw new Error('Failed to create project alternative.');

    // Wait some time for processing of task to start
    await sleep(initialAsyncDelayMs);

    while (true) {
      const response = await client.query({
        query: projectAlternativeCreateTaskQuery,
        variables: { taskId },
        fetchPolicy: 'no-cache',
      });
      const taskDetails = response.data.projectAlternativeCreateTask;
      if (!taskDetails) {
        throw new Error('Failed to fetch task details.');
      }
      if (taskDetails.status === 'ERROR') {
        throw new Error('Failed to create project alternative.');
      }

      if (taskDetails.status === 'COMPLETED') {
        return response.data.projectAlternativeCreateTask?.projectAlternative;
      }

      await sleep(asyncPollIntervalMs);
    }
  }

  public async update(input: UpdateProjectAlternativeInput) {
    return useApolloClient()
      .mutate({
        mutation: updateProjectAlternativeMutation,
        variables: { input },
      })
      .then((response) => response?.data?.updateProjectAlternative?.projectAlternative);
  }

  public async fetchAll(args: QueryProjectAlternativesArgs) {
    return useApolloClient()
      .query({
        query: projectAlternativesQuery,
        variables: args,
        fetchPolicy: 'no-cache',
      })
      .then((result) => flattenNodeConnection(result.data.projectAlternatives));
  }
}
