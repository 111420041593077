import { PropType } from 'vue';

import { RelativeScheduleRange } from '@/features/scheduleViews/types';
import { TreeAbleEntity } from '@/helpers/utils/orderedTree';

export type InputModelValue = string | number | undefined | null;
export type DateRangeModelValue = [Date, Date] | null;

export const inputProps = () => ({
  modelValue: {
    type: [String, Number] as PropType<InputModelValue>,
    default: '',
  },
  errorMessage: {
    type: String,
    required: false,
    default: () => null,
  },
});

export type RadioModelValue = string | boolean | object | null;

export type NestedSelectOption = TreeAbleEntity & { name: string };

export function getDefaultRelativeRange(): RelativeScheduleRange {
  return {
    type: 'relative',
    last: {
      amount: 0,
      unit: 'WEEK',
    },
    next: {
      amount: 0,
      unit: 'WEEK',
    },
  };
}
