import { CSSProperties } from 'vue';

import { clamp } from '@/helpers/utils/numbers';

import { getStylesFunction, MAX_BOUNDS, OVERLAY_APPEARANCE_TIMEOUT, Rect } from './overlay.helpers';

export type OverlayPosition =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'upper-edge-right'
  | 'upper-edge-left'
  | 'lower-edge-right'
  | 'lower-edge-left'
  | 'top-right'
  | 'top-left'
  | 'top-contain'
  | 'bottom-right'
  | 'bottom-left'
  | 'bottom-contain';

export interface UseGloballyPositionedOverlayOptions {
  position: OverlayPosition;
  disabled?: boolean;
  delayMs?: number;
  minHeight?: number;
}

export const useGloballyPositionedOverlay = (
  triggerElementRef: Ref<HTMLElement | null>,
  // warning: string is way slower than Ref<HTMLElement | null>
  // use only when refs cannot be used reliantly
  overlayElement: Ref<HTMLElement | null> | string,
  options: UseGloballyPositionedOverlayOptions,
) => {
  let timer: number | undefined;
  const clearDisplayTimer = () => {
    if (!timer) return;
    window.clearTimeout(timer);
    timer = undefined;
  };

  onUnmounted(() => {
    clearDisplayTimer();
  });

  const isShown = ref(false);
  const style = ref<CSSProperties>();

  watch([isShown, () => overlayElement, triggerElementRef], () => calculateStyle());

  const showOverlay = async () => {
    if (options.disabled) return;
    if (options.delayMs && !timer) {
      timer = window.setTimeout(() => {
        showOverlay();
      }, options.delayMs);
      return;
    }
    clearDisplayTimer();
    isShown.value = true;
  };

  const hideOverlay = () => {
    isShown.value = false;
    clearDisplayTimer();
  };

  const waitForOverlayElement = async (overlayElementSelector: string) => {
    let element = document.querySelector<HTMLElement>(overlayElementSelector);
    let timeoutReached = false;
    const timeout = window.setTimeout(() => {
      timeoutReached = true;
    }, OVERLAY_APPEARANCE_TIMEOUT);
    while (!element && !timeoutReached) {
      await new Promise((resolve) => {
        element = document.querySelector<HTMLElement>(overlayElementSelector);
        requestAnimationFrame(resolve);
      });
    }
    window.clearTimeout(timeout);
    return element;
  };

  const calculateStyle = async () => {
    await nextTick(); // wait for tooltipRef to get populated

    const triggerRect = getRect(triggerElementRef.value);

    const overlay =
      typeof overlayElement === 'string'
        ? await waitForOverlayElement(overlayElement)
        : overlayElement.value;

    const overlayRect = getRect(overlay);

    // we are interested more in the content (scrollHeight) than the actual size of the overlay
    overlayRect.height = overlay?.scrollHeight ?? overlayRect.height;
    overlayRect.width = overlay?.scrollWidth ?? overlayRect.width;
    const { left, top, width, bottom } = getStylesFunction(options.position)(
      triggerRect,
      overlayRect,
      options.minHeight,
    );
    const maxLeft = window.innerWidth - (overlayRect.width + MAX_BOUNDS);
    const clampedLeft = clamp(left, [MAX_BOUNDS, maxLeft]);
    const maxTop = Math.max(MAX_BOUNDS, top);

    style.value = {
      left: `${clampedLeft}px`,
      top: `${maxTop}px`,
      width: width !== undefined ? `${width}px` : undefined,
      bottom: bottom !== undefined ? `${bottom}px` : undefined,
      position: 'fixed',
      zIndex: 10000, // Ensure visibility over other elements
    };
  };

  return {
    isShown,
    style,
    showOverlay,
    hideOverlay,
    calculateStyle,
  };
};

const getRect = (element: HTMLElement | null): Rect =>
  element?.getBoundingClientRect() ?? {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
  };
