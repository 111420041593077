import { SchedulerPro } from '@bryntum/schedulerpro';
import { addMinutes, isAfter, isBefore, startOfDay } from 'date-fns';
import { isEqual } from 'lodash';

import { WbsSectionEntity } from '@/common/types/entities';
import { useMilestoneStore } from '@/features/milestones';
import { useOrderStore } from '@/features/orders';
import { usePauseStore } from '@/features/pauses';
import { useWbsSectionStore } from '@/features/projectStructure';
import {
  ScheduleFilterState,
  useScheduleViewStore,
} from '@/features/scheduleViews/scheduleViewStore';
import { DateRangeObject } from '@/helpers/utils/dates';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { DryingBreakEventParser } from '@/services/store/schedule/parsers';
import {
  getEmptyResourceId,
  getMainResourceId,
  getPlaceholderEventId,
} from '@/services/store/schedule/parsers/base';
import { SchedulerEvent, SchedulerResource } from '@/services/store/schedule/types';

export function applyFilter(): void {
  const scheduleViewStore = useScheduleViewStore();

  if (!scheduleViewStore.isFilterActive) {
    const scheduler = getScheduler();
    scheduler?.eventStore.clearFilters();
    scheduler?.resourceStore.clearFilters();
    return;
  }

  filterEvents(scheduleViewStore.currentFilter);
  filterResources(scheduleViewStore.currentFilter);
}

function identifyEntityDatesAndSection(
  entityId: string,
  entityType: NodeName.ORDER | NodeName.MILESTONE | NodeName.PAUSE,
) {
  let startDate: Date = new Date();
  let endDate: Date = new Date();
  let sectionId: string = '';
  if (entityType === NodeName.ORDER) {
    const orderStore = useOrderStore();
    const order = orderStore.orders.get(entityId);
    if (!order) {
      return undefined;
    }
    startDate = order.startAt;
    endDate = addMinutes(order.finishAt, order.dryingBreak?.duration ?? 0);
    sectionId = order.wbsSection.id;
  } else if (entityType === NodeName.MILESTONE) {
    const milestoneStore = useMilestoneStore();
    const milestone = milestoneStore.milestones.get(entityId);
    if (!milestone) return undefined;
    startDate = milestone.date;
    endDate = milestone.date;
    sectionId = milestone.wbsSection?.id ?? '';
  } else if (entityType === NodeName.PAUSE) {
    const pauseStore = usePauseStore();
    const pause = pauseStore.pauses.get(entityId);
    if (!pause) return undefined;
    startDate = pause.start;
    endDate = pause.end;
  }
  return { startDate, endDate, sectionId };
}

export function entityIsVisible(
  entityId: string,
  entityType: NodeName.ORDER | NodeName.MILESTONE | NodeName.PAUSE,
  filterState: ScheduleFilterState,
): boolean {
  const datesAndSection = identifyEntityDatesAndSection(entityId, entityType);
  if (!datesAndSection) return false;

  if (entityType === NodeName.ORDER) {
    const orderStore = useOrderStore();
    const order = orderStore.orders.get(entityId);
    if (!order) return false;

    let isAllowed = true;
    if (filterState.contributorGroups?.length) {
      isAllowed = filterState.contributorGroups.includes(order?.contributorGroup?.id ?? '');
    }
    if (filterState.trades?.length) {
      isAllowed = isAllowed && filterState.trades.includes(order?.tenantTradeVariation?.id ?? '');
    }
    return isAllowed;
  }
  return true;
}

function sectionIsVisible(
  sectionId: string,
  allSectionsWithEvents: Set<string>,
  filterState: ScheduleFilterState,
): boolean {
  const sectionStore = useWbsSectionStore();
  const section = sectionStore.wbsSections.get(sectionId);

  /***
   * Section can be undefined when live project filter is used in the versioning preview.
   */
  if (!section) return false;
  const children = sectionStore.getOrderedWbsSectionsTree().get(sectionId)?.children ?? [];

  const allowedSections = new Set(filterState.sections);

  const shouldShowSection = (sectionId: string) => {
    let isAllowed = true;

    if (allowedSections.size > 0) {
      isAllowed = allowedSections.has(sectionId);
    }
    if (filterState.contributorGroups.length || filterState.trades.length) {
      return isAllowed && allSectionsWithEvents.has(sectionId);
    }
    return isAllowed;
  };

  return shouldShowSection(section.id) || children.some((child) => shouldShowSection(child.id));
}

/**
 * returns true if event (i.e.: order, milestone etc.) partially
 * overlaps within the provided range
 * CASE 1 -> returns true
 * order:        ---------
 * range:             ---------
 *
 * CASE 2 -> returns true
 * order:        ---------
 * range:          ----
 *
 * CASE 3 -> returns false
 * order:        ---------
 * range:                  ----
 */
export function doesEventOverlapWithRange(
  eventStart: Date,
  eventEnd: Date,
  rangeStart?: string,
  rangeEnd?: string,
) {
  if (!rangeStart || !rangeEnd) return true;
  if (!isBefore(eventStart, startOfDay(new SchedulingDate(rangeEnd)))) return false;

  // end date usually is exclusive, except when start and end date are identical
  if (isEqual(eventStart, eventEnd)) {
    if (isBefore(eventEnd, startOfDay(new SchedulingDate(rangeStart)))) return false;
  } else {
    if (!isAfter(eventEnd, startOfDay(new SchedulingDate(rangeStart)))) return false;
  }
  return true;
}

function filterEvents(currentFilter: ScheduleFilterState): void {
  const scheduler = getScheduler();

  scheduler?.eventStore.filter({
    filters: (event: SchedulerEvent) => {
      if (event.id === getPlaceholderEventId()) {
        return true;
      }
      if (event.entity === NodeName.DRYING_BREAK) {
        const orderId = DryingBreakEventParser.dryingBreakIdToOrderId(event.id);
        return entityIsVisible(orderId, NodeName.ORDER, currentFilter);
      }
      return entityIsVisible(
        event.id,
        event.entity as NodeName.ORDER | NodeName.MILESTONE | NodeName.PAUSE,
        currentFilter,
      );
    },
    replace: true,
  });
}

function filterResources(currentFilter: ScheduleFilterState): void {
  const scheduler = getScheduler();
  if (!scheduler) return;

  const resourcesWithEvents = getResourcesWithEvents(scheduler);

  const filterResourcesWithEvents = (
    scheduler: SchedulerPro,
    currentFilter: ScheduleFilterState,
  ) => {
    scheduler.resourceStore.filter({
      filters: (resource: SchedulerResource) => {
        if (resource.id === getMainResourceId() || resource.id === getEmptyResourceId()) {
          return true;
        }
        return sectionIsVisible(resource.id, resourcesWithEvents, currentFilter);
      },
      replace: true,
    });
  };

  // NOTE: We clear old filters first, otherwise, it messes up positioning of resources sometimes
  // NOTE: Since v6 there is a bug that the return value is sometimes null, sometimes a promise
  const clearPromise: Promise<void> | null = scheduler.resourceStore.clearFilters();
  if (clearPromise) {
    clearPromise.then(() => {
      filterResourcesWithEvents(scheduler, currentFilter);
    });
  } else {
    filterResourcesWithEvents(scheduler, currentFilter);
  }
}

function getResourcesWithEvents(scheduler: SchedulerPro): Set<string> {
  const visibleTimeSpan = scheduler.eventStore.getTotalTimeSpan() as DateRangeObject;
  if (!visibleTimeSpan.startDate || !visibleTimeSpan.endDate) return new Set();
  const visibleEvents = (
    scheduler.eventStore.getEvents(visibleTimeSpan) as SchedulerEvent[]
  ).filter((e) => e.entity === NodeName.ORDER);
  return new Set(visibleEvents.map((event) => event.resourceId as string));
}

export const checkIfEventIsFilteredOut = (
  entityId: string,
  entityType: NodeName.ORDER | NodeName.MILESTONE | NodeName.PAUSE,
): boolean => {
  const scheduleViewStore = useScheduleViewStore();

  if (!scheduleViewStore.isFilterActive) {
    return false;
  }

  const scheduler = getScheduler();
  if (!scheduler) return true;

  const entityIsVisibleInSchedule = entityIsVisible(
    entityId,
    entityType,
    scheduleViewStore.currentFilter,
  );
  if (!entityIsVisibleInSchedule) return true;

  const sectionId = identifyEntityDatesAndSection(entityId, entityType)?.sectionId;
  // events with no section assignment are always visible
  if (!sectionId) return false;

  const sectionIsVisibleInSchedule = sectionIsVisible(
    sectionId,
    getResourcesWithEvents(scheduler),
    scheduleViewStore.currentFilter,
  );
  if (!sectionIsVisibleInSchedule) return true;

  return false;
};

/**
 * Returns sections that are currently displayed in schedule.
 * Custom rows are not included.
 * @returns
 */
export function getDisplayedSchedulerSections(): WbsSectionEntity[] {
  const scheduleViewStore = useScheduleViewStore();
  const scheduler = getScheduler();
  const sections = useWbsSectionStore();
  if (!scheduler) return [];

  const resourcesWithEvents = getResourcesWithEvents(scheduler);

  const displayedSections = sections.wbsSectionsList.filter((section) =>
    sectionIsVisible(section.id, resourcesWithEvents, scheduleViewStore.currentFilter),
  );
  const displayedSectionIds = new Set(displayedSections.map((s) => s.id));

  /**
   * Displayed sections might not contain all parent nodes, we add those manually below.
   */

  const newSections: WbsSectionEntity[] = [];

  const enqueueSectionParent = (section: WbsSectionEntity) => {
    if (!section.parentId || displayedSectionIds.has(section.parentId)) return;

    const parentSection = sections.wbsSections.get(section.parentId);
    if (!parentSection) {
      return;
    }
    newSections.push(parentSection);
    displayedSections.push(parentSection);
    displayedSectionIds.add(parentSection.id);
  };

  displayedSections.forEach(enqueueSectionParent);

  while (newSections.length) {
    const section = newSections.pop();
    if (section) {
      enqueueSectionParent(section);
    }
  }

  return displayedSections;
}
