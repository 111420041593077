import '@/jsx/components/modalStyle.css';

import { h } from '../jsxFactory';

type ModalProps = {
  $children?: any; // workaround so ts does not complain
  className: string;
  headerText?: string;
  headerSupportingText?: string | HTMLElement;
  headerAlignment?: string;
  footerAlignment?: string;
  horizontal?: boolean;
  zIndex?: number;
  submitAction?: (event: SubmitEvent) => void;
  dataTestid?: string;
};

export default function Modal(
  {
    className = '',
    headerText = '',
    headerSupportingText = '',
    headerAlignment = '',
    footerAlignment = '',
    zIndex = 9999,
    horizontal = false,
    submitAction = (event) => {
      event.preventDefault();
    },
    dataTestid = '',
  }: ModalProps,
  children: (HTMLElement | string)[],
) {
  setTimeout(() => {
    modal.classList.add('--visible');
  });

  const modal = (
    <form
      class="ds-jsx-modal-overlay"
      style={{ 'z-index': zIndex }}
      onSubmit={submitAction}
      data-testid={dataTestid}
    >
      <div class={`ds-jsx-modal-content ${horizontal ? '--horizontal' : horizontal} ${className}`}>
        <header class={`ds-jsx-modal-header --${headerAlignment}`}>
          <div class="tw-flex tw-w-full tw-justify-between tw-flex-row tw-items-center">
            <div>
              {headerText ? (
                <h2 class="tw-ds-text-xl--medium tw-text-grey-700">{headerText}</h2>
              ) : (
                ''
              )}
              {headerSupportingText ? (
                <p class="tw-mt-1 tw-ds-text-sm tw-text-cool-grey-600 tw-whitespace-pre-line">
                  {headerSupportingText}
                </p>
              ) : (
                ''
              )}
            </div>
          </div>
        </header>
        {footerAlignment ? (
          <footer class={`ds-jsx-modal-footer --${footerAlignment}`}>{...children}</footer>
        ) : (
          ''
        )}
      </div>
    </form>
  );
  return modal;
}
