import { InvokedConstraint, OrderSchedulingEngine } from '@koppla-tech/scheduling-engine';
import { addMilliseconds, isSameDay } from 'date-fns';

import { convertFromUtcToLocalTimezone } from '@/common/composables/timezones';
import { useFeatureAccessStore } from '@/common/featureAccessStore';
import { OrderDependencyEntity } from '@/common/types';
import { useMultiSelectActionsStore } from '@/features/multiSelectActions/multiSelectActionsStore';
import { useNotificationStore } from '@/features/notifications';
import {
  DependencyLagViolatedEvent,
  getDependencyTypeOfSelection,
  OrderDependencyStore,
  showOverruleExistingDependenciesNotification,
  useOrderDependencyStore,
} from '@/features/orderDependencies';
import { showActionFailedNotification } from '@/features/realTimeCollaboration';
import { useLocalOrderSchedulingEngine } from '@/features/schedule';
import { getCurrentSchedulingContext } from '@/features/schedule/utils';
import { subtract } from '@/helpers/utils/arrays';
import { LoggingService } from '@/interfaces/services';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { SchedulerPopupComponent, useSchedulePopupStore } from '@/services/store/schedulePopup';
import { trackUpdate } from '@/utils/analyticsEvents/scheduleTracking';

import { DryingBreakEventParser } from '../../../parsers';
import { getMainResourceId, getPlaceholderEventId } from '../../../parsers/base';
import { ScheduleStore } from '../../../store';
import { SchedulerEvent, SchedulerResource } from '../../../types';
import {
  eventHasValidResource,
  getNextValidResource,
} from '../../../utils/eventResourceAssignment';

export interface BeforeEventDropFinalizeListener {
  beforeEventDropFinalize: ReturnType<typeof useBeforeEventDropFinalizeListener>;
}

export function useBeforeEventDropFinalizeListener(
  store: ScheduleStore,
  loggingService: LoggingService,
): (data: {
  context: {
    async: boolean;
    valid: boolean;
    finalize: (allow?: boolean) => void;
    proxyElements: HTMLElement[];
    eventRecords: SchedulerEvent[];
    context: { newX: number; newY: number };
    newResource: SchedulerResource;
    eventRecord: SchedulerEvent;
    startDate: Date | string;
  };
}) => void {
  return async ({ context }) => {
    // We need to make it async in order to be able to use finalize
    context.async = true;
    store.utils.isDraggingEvent = false;
    store.utils.scheduleContextMarker = null;

    const scheduler = getScheduler();
    const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();
    const orderDependencyStore = useOrderDependencyStore();
    if (!scheduler || !context.valid) {
      context.valid = false;
      context.finalize(false);
      return;
    }

    const someDraggedElementHasBeenRemovedWhileBeingDragged = context.eventRecords.some(
      (event) => !scheduler.eventStore.getById(event.id),
    );
    if (someDraggedElementHasBeenRemovedWhileBeingDragged) {
      showActionFailedNotification();
      context.valid = false;
      context.finalize(false);
      return;
    }
    // Only return if the directly dragged event record has an invalid resource, all other resources will be auto-fixed
    if (!eventHasValidResource(context.eventRecord, context.newResource.id)) {
      context.valid = false;
      context.finalize(false);
      return;
    }

    const { newDate, timeDiff } = calculateTimeDifference(context);
    const newResources = calculateNewResources(context);

    if (context.eventRecord.id === getPlaceholderEventId()) {
      handlePlaceholderEventUpdate(store, context.eventRecord, newDate, newResources[0]);
      // We currently allow dragging to any date, so we can safely accept this
      context.finalize(true);
      return;
    }

    // NOTE: When LockRows is enabled, we need to hide the proxy element as it won't disappear otherwise
    // context.proxyElements.forEach((proxy) => {
    //   proxy.style.display = 'none';
    // });
    // setTimeout(() => {
    // context.finalize(false);
    // });

    // We always need to revert the update here, as the UI wouldn't be updated correctly otherwise when rescheduled to valid dates,
    context.finalize(false);

    const originalEventValues = calculateOriginalEvents(context);
    const newEventValues = calculateNewEvents(originalEventValues, timeDiff, newResources);
    const invalidEvents = filterEventsWithInvalidWorkingTime(newEventValues);

    // In all cases, we perform a scheduling operation to make sure the events are rescheduled to the correct working times and dependencies are kept
    const { scheduledEventValues, invokedConstraints } = performScheduling(
      newEventValues,
      originalEventValues,
      localOrderSchedulingEngine,
      orderDependencyStore,
    );

    if (invokedConstraints.length) {
      showOverruleExistingDependenciesNotification(invokedConstraints);
      const orderDependencyStore = useOrderDependencyStore();
      const dependencies = invokedConstraints.map((c) =>
        orderDependencyStore.dependencies.get(c.dependencyId),
      );
      loggingService.trackEvent(
        new DependencyLagViolatedEvent({
          method: 'drag_and_drop',
          dependencyType: getDependencyTypeOfSelection(dependencies),
          count: invokedConstraints.length,
          source: 'schedule',
        }),
      );
    }

    if (invalidEvents.size) {
      const showAction = showNonWorkingTimeNotification(originalEventValues, invalidEvents);
      loggingService.trackEvent(
        new loggingService.AnalyticEventCategories.OrdersMovedOutsideWorkingTimesEvent({
          canAddException: showAction,
        }),
      );
    }

    if (hasChanges(scheduledEventValues, originalEventValues)) {
      handleEntityUpdates(context, scheduledEventValues, originalEventValues);
      const groups = countOccurrences(context.eventRecords);
      trackUpdate(groups, loggingService, 'drag_and_drop');
    }
  };
}

function showNonWorkingTimeNotification(
  originalEvents: EventValues[],
  invalidNewEvents: Map<string, EventValues>,
): boolean {
  const notificationStore = useNotificationStore();

  const allEventsAreOnSameDay = Array.from(invalidNewEvents.values()).every((event) =>
    isSameDay(event.startDate, Array.from(invalidNewEvents.values())[0].startDate),
  );
  const canCreateException =
    useFeatureAccessStore().uiState.showNonWorkingTimeResolutionExceptionCreateAction;
  const invalidOriginalEvents = originalEvents.filter((event) => invalidNewEvents.has(event.id));

  notificationStore.push({
    titleI18nKey: 'objects.nonWorkingTimeResolution.notificationTitle',
    titleI18nKeyVariables: {
      count: invalidNewEvents.size,
    },
    bodyI18nKey: 'objects.nonWorkingTimeResolution.notificationSubtitleStart',
    bodyI18nKeyVariables: {
      count: invalidNewEvents.size,
    },
    primaryAction:
      allEventsAreOnSameDay && canCreateException
        ? {
            callback: () => {
              const schedulePopupStore = useSchedulePopupStore();
              schedulePopupStore.openPopup({
                component: SchedulerPopupComponent.NON_WORKING_TIME_RESOLUTION,
                payload: {
                  events: invalidOriginalEvents.map((originalEvent) => {
                    const newEvent = invalidNewEvents.get(originalEvent.id)!;
                    return {
                      id: originalEvent.id,
                      newDate: newEvent.startDate,
                      newResourceId: newEvent.resourceId ?? '',
                      originalStartDate: originalEvent.startDate,
                      originalEndDate: originalEvent.endDate,
                      calendarId: originalEvent.calendarId ?? '',
                      type: 'start',
                      duration: newEvent.duration,
                    };
                  }),
                },
              });
            },
            i18nKey: 'objects.nonWorkingTimeResolution.notificationAction',
          }
        : null,
    type: 'blue',
    timeout: 5000,
  });

  return allEventsAreOnSameDay;
}

function performScheduling(
  newEventValues: EventValues[],
  originalEventValues: EventValues[],
  schedulingEngine: OrderSchedulingEngine,
  orderDependencyStore: OrderDependencyStore,
): {
  scheduledEventValues: EventValues[];
  invokedConstraints: InvokedConstraint[];
} {
  const pauseUpdates = newEventValues
    .filter((event) => event.entity === NodeName.PAUSE)
    .map((event) => ({
      id: event.id,
      start: event.startDate,
      end: event.endDate,
    }));

  const orderUpdates = newEventValues
    .filter((event) => event.entity === NodeName.ORDER)
    .map((event) => ({
      id: event.id,
      startAt: event.startDate,
      finishAt: event.endDate,
      duration: event.duration,
    }));

  const milestoneUpdates = newEventValues
    .filter((event) => event.entity === NodeName.MILESTONE)
    .map((event) => ({
      id: event.id,
      date: event.startDate,
      ...(event.resourceId !== undefined
        ? {
            wbsSection: event.resourceId === getMainResourceId() ? null : { id: event.resourceId! },
          }
        : {}),
    }));

  /**
   * When moving multiple entities together, we can never change any of the dependencies between them,
   * because they are moved with the same offset. That means we actually need to make sure the dependencies
   * always stay the same, even in the case that some entities need to be rescheduled because of different working times.
   * In order to do so, we only pass in the root nodes (the ones with no incoming dependency within the selection)
   * and the dependencies within the selection into the engine. This will reschedule potential invalid
   * dates of the selection, while keeping the dependencies between the entities the same.
   */
  const updatedEntityIds = [
    ...orderUpdates.map((order) => order.id),
    ...milestoneUpdates.map((milestone) => milestone.id),
  ];
  const dependencyIdsToEnforce = schedulingEngine.utils.getIncidentDependencies(
    updatedEntityIds,
    true,
  );
  const dependenciesToEnforce = dependencyIdsToEnforce
    .map((id) => orderDependencyStore.dependencies.get(id))
    .filter(Boolean) as OrderDependencyEntity[];

  const nonRootNodes = new Set<string>();
  dependenciesToEnforce.forEach((dependency) => {
    nonRootNodes.add(dependency.to.id);
  });
  const rootNodes = subtract(updatedEntityIds, Array.from(nonRootNodes));

  const rootOrderUpdates = orderUpdates.filter((order) => rootNodes.includes(order.id));
  const rootMilestoneUpdates = milestoneUpdates.filter((milestone) =>
    rootNodes.includes(milestone.id),
  );

  const { changes: schedulingChanges, meta } = schedulingEngine.schedule(
    {
      update: {
        pauses: pauseUpdates,
        orders: rootOrderUpdates,
        milestones: rootMilestoneUpdates,
        dependencies: dependenciesToEnforce,
      },
    },
    getCurrentSchedulingContext(),
    { stateless: true },
  );

  // For all new events in the selection, we use the result of the engine to update the new events
  // If there is no new result, we actually know that we need to use the original values again (e.g. when the event was rescheduled to the same date)
  const scheduledEventValues = newEventValues.map((event) => {
    if (event.entity === NodeName.MILESTONE) {
      const rescheduledMilestone = schedulingChanges.update?.milestones?.find(
        (milestone) => milestone.id === event.id,
      );
      if (!rescheduledMilestone) {
        const originalValues = originalEventValues.find(
          (originalEvent) => originalEvent.id === event.id,
        )!;
        return {
          ...event,
          startDate: originalValues.startDate,
          endDate: originalValues.startDate,
        };
      }
      return {
        ...event,
        startDate: rescheduledMilestone.date!,
        endDate: rescheduledMilestone.date!,
      };
    }
    if (event.entity === NodeName.ORDER) {
      const rescheduledOrder = schedulingChanges.update?.orders?.find(
        (order) => order.id === event.id,
      );
      if (!rescheduledOrder) {
        const originalValues = originalEventValues.find(
          (originalEvent) => originalEvent.id === event.id,
        )!;
        return {
          ...event,
          startDate: originalValues.startDate,
          endDate: originalValues.endDate,
        };
      }
      return {
        ...event,
        startDate: rescheduledOrder.startAt!,
        endDate: rescheduledOrder.finishAt!,
      };
    }
    return event;
  });

  const relevantInvokedConstraints = meta?.invokedConstraints.filter((constraint) =>
    newEventValues.some((event) => event.id === constraint.toId),
  );

  return {
    scheduledEventValues,
    invokedConstraints: relevantInvokedConstraints,
  };
}

function hasChanges(newEventValues: EventValues[], originalEventValues: EventValues[]): boolean {
  return newEventValues.some((event, idx) => {
    return (
      event.startDate.getTime() !== originalEventValues[idx].startDate.getTime() ||
      event.endDate.getTime() !== originalEventValues[idx].endDate.getTime() ||
      (event.resourceId !== undefined && event.resourceId !== originalEventValues[idx].resourceId)
    );
  });
}

function handleEntityUpdates(
  context: {
    eventRecords: SchedulerEvent[];
  },
  newEventValues: EventValues[],
  originalEventValues: EventValues[],
): void {
  const scheduleMultiActionsStore = useMultiSelectActionsStore();

  scheduleMultiActionsStore
    .rescheduleEntities({
      pauses: newEventValues
        .filter((event) => event.entity === NodeName.PAUSE)
        .map((event) => ({
          id: event.id,
          start: event.startDate,
          end: event.endDate,
        })),
      milestones: newEventValues
        .filter((event) => event.entity === NodeName.MILESTONE)
        .map((event) => ({
          id: event.id,
          date: event.startDate,
          ...(event.resourceId !== undefined
            ? {
                wbsSection:
                  event.resourceId === getMainResourceId() ? null : { id: event.resourceId! },
              }
            : {}),
        })),
      orders: newEventValues
        .filter((event) => event.entity === NodeName.ORDER)
        .map((event) => ({
          id: event.id,
          startAt: event.startDate,
          finishAt: event.endDate,
          ...(event.resourceId !== undefined ? { wbsSection: { id: event.resourceId! } } : {}),
        })),
    })
    .then(() => {
      // we need to reset the event records, since the rescheduling might reschedule to the initial values again, which wouldn't update the UI again
      nextTick(() => {
        context.eventRecords.forEach((event, idx) => {
          event.startDate = convertFromUtcToLocalTimezone(newEventValues[idx].startDate);
          event.endDate = convertFromUtcToLocalTimezone(newEventValues[idx].endDate);
          event.resourceId =
            newEventValues[idx].resourceId !== undefined
              ? newEventValues[idx].resourceId
              : event.resourceId;
        });
      });
    })
    .catch(() => {
      nextTick(() => {
        context.eventRecords.forEach((event, idx) => {
          event.startDate = convertFromUtcToLocalTimezone(originalEventValues[idx].startDate);
          event.endDate = convertFromUtcToLocalTimezone(originalEventValues[idx].endDate);
          event.resourceId = originalEventValues[idx].resourceId;
        });
      });
    });
}

function handlePlaceholderEventUpdate(
  store: ScheduleStore,
  event: SchedulerEvent,
  newStartDate: Date,
  newResourceId: string,
): void {
  if (event.entity === NodeName.MILESTONE) {
    store.updatePlaceholderEvent({
      startDate: newStartDate,
      endDate: newStartDate,
      entity: NodeName.MILESTONE,
      resourceId: newResourceId,
      isFixed: event.isFixed,
    });
  }

  if (event.entity === NodeName.PAUSE) {
    store.updatePlaceholderEvent({
      startDate: newStartDate,
      endDate: addMilliseconds(newStartDate, event.duration),
      entity: NodeName.PAUSE,
      resourceId: getMainResourceId(),
    });
  }

  if (event.entity === NodeName.ORDER) {
    const dryingBreakPlaceholderId =
      DryingBreakEventParser.orderIdToDryingBreakId(getPlaceholderEventId());
    const placeholderEvent = store.entities.events.get(getPlaceholderEventId())!;
    const dryingBreakPlaceholderEvent = store.entities.events.get(dryingBreakPlaceholderId);

    store.updatePlaceholderEvent({
      startDate: newStartDate,
      endDate: addMilliseconds(newStartDate, event.duration),
      entity: NodeName.ORDER,
      resourceId: newResourceId,
      calendarId: placeholderEvent.calendarId,
      dryingBreak: dryingBreakPlaceholderEvent
        ? {
            name: dryingBreakPlaceholderEvent.name,
            duration: dryingBreakPlaceholderEvent.duration,
          }
        : null,
    });
  }
}

function filterEventsWithInvalidWorkingTime(eventValues: EventValues[]): Map<string, EventValues> {
  const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();
  const updatedPauses = eventValues
    .filter((event) => event.entity === NodeName.PAUSE)
    .map((event) => ({ id: event.id, start: event.startDate, end: event.endDate }));
  return new Map(
    eventValues
      .filter((event) => {
        return !localOrderSchedulingEngine.utils.isStartDateDuringWorkingTime(
          event.startDate,
          event.calendarId || null,
          undefined,
          updatedPauses,
        );
      })
      .map((event) => [event.id, { ...event }]),
  );
}

function calculateTimeDifference(context: {
  startDate: Date | string;
  eventRecord: SchedulerEvent;
}): {
  newDate: Date;
  timeDiff: number;
} {
  // NOTE: We calculate the time diff to the previous start date using the new x coordinate of the event
  const dropDate = new Date(context.startDate);
  const newReferenceDate = new SchedulingDate(dropDate);
  const originalReferenceDate = new SchedulingDate(context.eventRecord.startDate);
  const timeDiff = newReferenceDate.getTime() - originalReferenceDate.getTime();

  return {
    newDate: newReferenceDate,
    timeDiff,
  };
}

interface EventValues {
  id: string;
  startDate: Date;
  endDate: Date;
  resourceId: string | null | undefined;
  entity: NodeName;
  duration: number;
  calendarId: string | null | undefined;
}

function calculateOriginalEvents(context: { eventRecords: SchedulerEvent[] }): EventValues[] {
  const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();

  const durations = context.eventRecords.map((event) =>
    localOrderSchedulingEngine.utils.computeWorkingTimeBetween(
      new SchedulingDate(event.endDate),
      new SchedulingDate(event.startDate),
      event.calendarId || null,
    ),
  );

  return context.eventRecords.map((event, idx) => ({
    id: event.id,
    startDate: new SchedulingDate(event.startDate),
    endDate: new SchedulingDate(event.endDate),
    duration: durations[idx],
    resourceId: event.resourceId,
    calendarId: event.calendarId,
    entity: event.entity,
  }));
}

function calculateNewEvents(
  originalEvents: EventValues[],
  timeDiff: number,
  newResources: string[],
): EventValues[] {
  return originalEvents.map((originalEvent, idx) => ({
    id: originalEvent.id,
    startDate: addMilliseconds(originalEvent.startDate, timeDiff),
    endDate: addMilliseconds(originalEvent.endDate, timeDiff),
    duration: originalEvent.duration,
    resourceId: newResources[idx] !== originalEvent.resourceId ? newResources[idx] : undefined,
    calendarId: originalEvent.calendarId,
    entity: originalEvent.entity,
  }));
}

function calculateNewResources(context: {
  eventRecords: SchedulerEvent[];
  newResource: SchedulerResource;
  eventRecord: SchedulerEvent;
}): string[] {
  const { eventRecords, newResource, eventRecord: targetEventRecord } = context;
  const scheduler = getScheduler();
  if (!scheduler) return [];
  /**
   * Bryntum does return all dragged event records, but not the new resources per event.
   * Therefore, we need to calculate all new resources in advance, based on the one dragged
   * event, where the new resource is given. This can be done by using the index differences.
   */
  const resourceIds = scheduler.resourceStore.allRecords.map((resource) => resource.id as string);
  const resourceDiff =
    resourceIds.findIndex((resource) => newResource.id === resource) -
    resourceIds.findIndex((resource) => targetEventRecord.resourceId === resource);

  const newResources = eventRecords.map((event) => {
    const resourceId = getNextValidResource(
      event,
      resourceIds[
        Math.max(
          Math.min(
            resourceIds.findIndex((resource) => event.resourceId === resource) + resourceDiff,
            resourceIds.length - 1,
          ),
          0,
        )
      ],
    );
    if (!resourceId) {
      return event.resourceId ?? '';
    }
    return resourceId;
  });

  return newResources;
}

function countOccurrences(array: SchedulerEvent[]): Partial<Record<NodeName, number>> {
  return array.reduce((collector, { entity }) => {
    if (!(entity in collector)) collector[entity] = 0;
    collector[entity] += 1;
    return collector;
  }, {});
}
