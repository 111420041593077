import { useGlobalStore } from '@/common/globalStore';
import { ProjectEntity } from '@/common/types';
import { getInitialAddressFormInput } from '@/components/forms/address';
import { useProjectTemplatesStore } from '@/features/projectTemplates/projectTemplatesStore';
import { BaseProjectFragment } from '@/graphql/__generated__/graphql';
import { useFormattedAddressWithout } from '@/helpers/address';
import { i18nInstance } from '@/plugins/i18n';

import { useProjectStore } from './projectStore';
import { ProjectFormInputs } from './projectTypes';

export const isCurrentTenantProjectOwner = (project: ProjectEntity) => {
  const globalStore = useGlobalStore();
  return project.tenant.id === globalStore.currentTenantId;
};

export function getInitialProjectFormValues(project?: ProjectEntity): ProjectFormInputs {
  return {
    address: getInitialAddressFormInput(project?.address ?? undefined),
    name: project?.name ?? '',
    internalId: project?.internalId ?? null,
    image: undefined,
  };
}
export enum LifecycleStatus {
  TEST = 'TEST',
  IN_PREPARATION = 'IN_PREPARATION',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
}

export function isRealProject(status: LifecycleStatus): boolean {
  return (
    status === LifecycleStatus.IN_PREPARATION ||
    status === LifecycleStatus.ACTIVE ||
    status === LifecycleStatus.COMPLETED
  );
}

export function filterByRealProject(project: ProjectEntity): boolean {
  return isRealProject(project.lifecycleStatus as LifecycleStatus);
}

export function filterByTestProject(project: ProjectEntity): boolean {
  return project.lifecycleStatus === LifecycleStatus.TEST;
}

export function filterByArchivedProject(project: ProjectEntity): boolean {
  return project.lifecycleStatus === LifecycleStatus.ARCHIVED;
}

export function sortBySortState(
  projectA: ProjectEntity,
  projectB: ProjectEntity,
  sortState: { id: SortOption; order: SortOrder },
): number {
  const compare = (a: string | number | Date, b: string | number | Date): number => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  let result = 0;

  if (sortState.id === SortOption.NAME) {
    result = compare(projectA.name, projectB.name);
  } else if (sortState.id === SortOption.NUMBER) {
    result = compare(projectA.internalId ?? '', projectB.internalId ?? '');
  } else if (sortState.id === SortOption.STATUS) {
    result = compare(projectA.lifecycleStatus, projectB.lifecycleStatus);
  } else if (sortState.id === SortOption.CREATED_AT) {
    result = compare(projectA.createdAt, projectB.createdAt);
  } else if (sortState.id === SortOption.UPDATED_AT) {
    result = compare(projectA.updatedAt, projectB.updatedAt);
  }

  if (result === 0) {
    result = compare(projectA.name, projectB.name);
  }

  return sortState.order === SortOrder.ASC ? result : -result;
}

export function filterBySelectedStatus(
  project: ProjectEntity,
  selectedStatuses: string[] = [],
): boolean {
  if (selectedStatuses.length === 0) {
    return true;
  }

  return selectedStatuses.includes(project.lifecycleStatus);
}

export function filterBySearchQuery(project: ProjectEntity, query: string) {
  return query ? project.name.toLowerCase().includes(query.toLowerCase()) : project;
}

export type SortState = { id: SortOption; order: SortOrder };

export type ProjectType = 'real' | 'test';

export enum SortOption {
  NAME = 'NAME',
  NUMBER = 'NUMBER',
  STATUS = 'STATUS',
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const ALL_SORT_OPTIONS: { id: SortOption; labelId: string }[] = [
  {
    id: SortOption.NAME,
    labelId: 'LeanProjects.LeanProjectsAll.sortBy.name',
  },
  {
    id: SortOption.NUMBER,
    labelId: 'LeanProjects.LeanProjectsAll.sortBy.projectNumber',
  },
  {
    id: SortOption.STATUS,
    labelId: 'LeanProjects.LeanProjectsAll.sortBy.status',
  },
  {
    id: SortOption.CREATED_AT,
    labelId: 'LeanProjects.LeanProjectsAll.sortBy.createdAt',
  },
  {
    id: SortOption.UPDATED_AT,
    labelId: 'LeanProjects.LeanProjectsAll.sortBy.lastUpdatedAt',
  },
];

export const REDUCED_SORT_OPTIONS: { id: SortOption; labelId: string }[] = [
  {
    id: SortOption.NAME,
    labelId: 'LeanProjects.LeanProjectsAll.sortBy.name',
  },
  {
    id: SortOption.NUMBER,
    labelId: 'LeanProjects.LeanProjectsAll.sortBy.projectNumber',
  },
  {
    id: SortOption.CREATED_AT,
    labelId: 'LeanProjects.LeanProjectsAll.sortBy.createdAt',
  },
];

export function getStatusLabel(status: LifecycleStatus) {
  switch (status) {
    case LifecycleStatus.TEST:
      return 'LeanProjects.LeanProjectsAll.status.test';
    case LifecycleStatus.IN_PREPARATION:
      return 'LeanProjects.LeanProjectsAll.status.inPreparation';
    case LifecycleStatus.ACTIVE:
      return 'LeanProjects.LeanProjectsAll.status.active';
    case LifecycleStatus.COMPLETED:
      return 'LeanProjects.LeanProjectsAll.status.completed';
    case LifecycleStatus.ARCHIVED:
      return 'LeanProjects.LeanProjectsAll.status.archived';
  }
}

/* Subtitle containing short address and project internal id, if available */
export const getShortProjectSubtitle = (project: BaseProjectFragment) => {
  const formattedAddress = useFormattedAddressWithout(
    i18nInstance.global,
    project.address,
    ['country', 'street', 'street2'],
    undefined,
    '',
  );
  const formattedInternalId = project.internalId ? `#${project.internalId}` : '';

  return [formattedAddress, formattedInternalId].filter(Boolean).join(' - ');
};

export const REAL_PROJECT_STATUSES = [
  {
    id: LifecycleStatus.IN_PREPARATION,
    labelId: 'LeanProjects.LeanProjectsAll.status.inPreparation',
  },
  {
    id: LifecycleStatus.ACTIVE,
    labelId: 'LeanProjects.LeanProjectsAll.status.active',
  },
  {
    id: LifecycleStatus.COMPLETED,
    labelId: 'LeanProjects.LeanProjectsAll.status.completed',
  },
];

export const useProjectName = () =>
  computed(() => {
    const projectStore = useProjectStore();
    const projectTemplatesStore = useProjectTemplatesStore();

    return projectTemplatesStore.isInProjectTemplateMode
      ? (projectTemplatesStore.currentProjectTemplate?.name ?? '')
      : (projectStore.currentProject?.name ?? '');
  });
