import gql from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

export const orderPhotosQuery = gqlFn(/* GraphQL */ `
  query OrderPhotoDetails($id: ID!, $first: Int!, $after: String) {
    order(id: $id) {
      id
      photos(after: $after, first: $first) {
        edges {
          node {
            ...OrderPhoto
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`);

export const createOrderPhotoMutation = gqlFn(/* GraphQL */ `
  mutation CreateOrderPhoto(
    $order: ID!
    $content: Upload!
    $createdAt: DateTime!
    $comment: String
  ) {
    createOrderPhoto(
      input: { order: $order, content: $content, createdAt: $createdAt, comment: $comment }
    ) {
      photo {
        ...OrderPhoto
      }
    }
  }
`);

export const deleteOrderPhotoMutation = gqlFn(/* GraphQL */ `
  mutation DeleteOrderPhoto($id: ID!) {
    deleteOrderPhoto(input: { id: $id }) {
      success
    }
  }
`);

export const orderPhotoFragment = gql`
  fragment OrderPhoto on OrderPhotoNode {
    id
    image {
      url
      downloadUrl
    }
    lowResImage {
      url
    }
    imageThumbnail {
      url
    }
    comment
    createdAt
    createdBy {
      ...UserSimple
    }
    createdByContributorGroup {
      id
      name
    }
  }
`;
