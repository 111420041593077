import { OrderSchedulingEngine } from '@koppla-tech/scheduling-engine';

export const INITIALIZATION_CHECK_INTERVAL = 100;
export const INITIALIZATION_MAX_WAIT = 10000;

/**
 * Continuously checks if `engine.isInitialized`, polling at `INITIALIZATION_CHECK_INTERVAL` ms.
 * If initialization does not complete within `INITIALIZATION_MAX_WAIT` ms, the function rejects.
 */
export async function engineIsInitialized(engine: OrderSchedulingEngine): Promise<void> {
  if (engine.isInitialized) return;

  let elapsed = 0;

  return new Promise((resolve, reject) => {
    const timer = setInterval(() => {
      elapsed += INITIALIZATION_CHECK_INTERVAL;

      if (engine.isInitialized) {
        clearInterval(timer);
        resolve();
      } else if (elapsed >= INITIALIZATION_MAX_WAIT) {
        clearInterval(timer);
        reject(new Error('Timeout waiting for scheduling engine to initialize.'));
      }
    }, INITIALIZATION_CHECK_INTERVAL);
  });
}
