import { useScheduleViewStore } from '@/features/scheduleViews/scheduleViewStore';
import { debounce } from '@/helpers/utils/functions';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { cannotShowEventHover } from '@/services/store/schedule/config/listeners/utils';
import { ScheduleScrollHelper } from '@/services/store/schedule/types';
import { SchedulerPopupComponent } from '@/services/store/schedulePopup';

import { getAdjustedPopupMouseEvent } from './events';

const SCROLL_THRESHOLD = 50;

export interface ScrollListeners {
  horizontalscroll: (event: { scrollLeft: number }) => void;
  scroll: (event: { scrollTop: number }) => void;
}

export function getScrollListeners(
  store: ScheduleStore,
  scrollHelper: ScheduleScrollHelper,
): ScrollListeners {
  const persistScroll = getScrollPersistence(store, scrollHelper);
  let lastScrollTop = 0;
  let lastScrollLeft = 0;

  const handleVerticalScrollThreshold = debounce((scrollTop: number) => {
    closePopupIfThresholdReached(lastScrollTop, scrollTop, store);
    lastScrollTop = scrollTop;
  }, 50);
  const handleHorizontalScrollThreshold = debounce((scrollLeft: number) => {
    closePopupIfThresholdReached(lastScrollLeft, scrollLeft, store);
    lastScrollLeft = scrollLeft;
  }, 50);

  return {
    scroll: (event) => {
      startScroll(store, scrollHelper);
      handleVerticalScrollThreshold(event.scrollTop);
      persistScroll();
    },
    horizontalscroll: (event) => {
      startScroll(store, scrollHelper);
      handleHorizontalScrollThreshold(event.scrollLeft);
      persistScroll();
    },
  };
}

function startScroll(store: ScheduleStore, scrollHelper: ScheduleScrollHelper): void {
  if (!scrollHelper.isScrolling) {
    scrollHelper.isScrolling = true;
    store.closePopup(SchedulerPopupComponent.EVENT_HOVER);
  }
}

function getScrollPersistence(
  store: ScheduleStore,
  scrollHelper: ScheduleScrollHelper,
): () => void {
  const scheduleViewStore = useScheduleViewStore();
  return debounce(() => {
    const scheduler = getScheduler();
    if (store.initialized) {
      const date = scheduler?.viewportCenterDate?.getTime() ?? null;
      const row = scheduler?.firstVisibleRow ?? null;

      if (date) {
        scheduleViewStore.setScrollDate(date);
      }
      if (row) {
        scheduleViewStore.setScrollRowIdx(row.dataIndex);
      }
    }

    getOpenEventHoverPopupAfterScrollIfNecessary(store);
    scrollHelper.isScrolling = false;
  }, 500);
}

function closePopupIfThresholdReached(
  initialValue: number,
  newValue: number,
  store: ScheduleStore,
) {
  if (Math.abs(initialValue - newValue) > SCROLL_THRESHOLD) {
    store.closePopup();
  }
}

function getOpenEventHoverPopupAfterScrollIfNecessary(store: ScheduleStore): void {
  if (
    cannotShowEventHover({
      isScrolling: false,
      scheduler: getScheduler(),
      scheduleStore: store,
    })
  ) {
    return;
  }

  const element = document.querySelector('.b-sch-event-hover') as HTMLElement | undefined;
  if (!element) return;
  const eventId = element.getAttribute('data-event-id');
  if (!eventId) return;
  const event = store.entities.events.get(eventId);
  if (!event) return;

  store.openPopup({
    component: SchedulerPopupComponent.EVENT_HOVER,
    payload: {
      entity: event.entity,
      id: event.id,
      mouseEvent: getAdjustedPopupMouseEvent(new MouseEvent('custom'), element),
    },
    submissive: true,
  });
}
