<template>
  <Transition name="appear">
    <slot />
  </Transition>
</template>

<style scoped>
.appear-enter-active,
.appear-leave-active {
  transition: opacity 0.1s ease;
}

.appear-enter-from,
.appear-leave-to {
  opacity: 0;
}
</style>
