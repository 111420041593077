<template>
  <button
    class="tw-inline-flex tw-items-center tw-gap-1 tw-p-2 hover:tw-bg-[rgba(255,255,255,0.2)] tw-rounded-xs"
    type="button"
    :data-testid="projectTemplateSelectors.editProjectDetailsButton"
    @click="isDetailsModalShown = true"
  >
    <DsSvgIcon icon="edit-02" />
    <span class="tw-ds-text-sm--semibold">
      {{ $t('projectTemplates.editName') }}
    </span>
  </button>
  <ProjectTemplatesDetailsModal
    v-if="isDetailsModalShown"
    :title="$t('projectTemplates.createFlowDetailsModalTitle')"
    :name="initialVariables.name"
    :description="initialVariables.description"
    @submit="editProjectTemplateDetails"
    @cancel="isDetailsModalShown = false"
  />
</template>
<script setup lang="ts">
import { projectTemplateSelectors } from '@/common/selectors/projectTemplates';
import { useProjectTemplatesStore } from '@/features/projectTemplates';
import ProjectTemplatesDetailsModal from '@/features/projectTemplates/components/ProjectTemplatesDetailsModal.vue';

const projectTemplatesStore = useProjectTemplatesStore();

const isDetailsModalShown = ref(false);

const initialVariables = computed(() => ({
  name: projectTemplatesStore.currentProjectTemplate?.name ?? '',
  description: projectTemplatesStore.currentProjectTemplate?.description ?? '',
}));

const editProjectTemplateDetails = (payload: { name: string; description: string }) => {
  isDetailsModalShown.value = false;
  projectTemplatesStore.editCurrentTemplate(payload);
};
</script>

<style scoped></style>
