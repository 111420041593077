<template>
  <main class="the-main" :class="mainClass">
    <div class="the-main-content">
      <ProjectPlanningModeBanner v-if="shouldShowPlanningBanner" />
      <ProjectTemplateModeBanner v-else-if="shouldShowProjectTemplateBanner" />
      <slot />
    </div>
  </main>
</template>

<script setup lang="ts">
import ProjectPlanningModeBanner from '@/features/projectAlternatives/components/ProjectPlanningModeBanner.vue';
import ProjectTemplateModeBanner from '@/features/projectTemplates/components/ProjectTemplateModeBanner.vue';

const props = defineProps({
  mode: {
    type: String as PropType<'no-drawer' | 'planning' | 'project-template' | 'default'>,
    default: 'default',
  },
});

const shouldShowPlanningBanner = computed(() => props.mode === 'planning');
const shouldShowProjectTemplateBanner = computed(() => props.mode === 'project-template');

const mainClass = computed(() => {
  return {
    '--planning': shouldShowPlanningBanner.value,
    '--project-template': shouldShowProjectTemplateBanner.value,
    '--default': props.mode === 'default',
    '--no-drawer': props.mode === 'no-drawer',
  };
});
</script>

<style scoped>
.the-main {
  @apply tw-flex tw-flex-col tw-grow tw-overflow-y-auto tw-max-w-full tw-h-screen tw-p-0;
}

.the-main.--default {
  @apply tw-pl-[var(--kop-drawer-width)];
}

.the-main.--no-drawer {
  @apply tw-pl-0;
}

.the-main-content {
  @apply tw-flex tw-flex-col tw-flex-grow tw-max-h-screen;
}

.the-main.--planning .the-main-content {
  @apply tw-border-attention-500 tw-border-solid tw-border-8 tw-border-t-0;
}
.the-main.--project-template .the-main-content {
  @apply tw-border-petrol-400 tw-border-solid tw-border-8 tw-border-t-0;
}
</style>
