<template>
  <span class="ds-dot" :style="style" :class="{ '--border': hasBorder, '--circle': circle }" />
</template>

<script lang="ts" setup>
import { isAboveMinimumContrast } from '@/helpers/utils/colors';

const props = defineProps({
  color: { type: String, default: '#E9EAEB' },
  autoBorder: { type: Boolean, default: true },
  size: { type: Number, default: 14 },
  circle: { type: Boolean, default: false },
});

const style = computed(() => {
  const dimension = `${props.size}px`;
  return {
    height: dimension,
    width: dimension,
    backgroundColor: props.color,
  };
});

const hasBorder = computed(() => {
  return props.autoBorder && !isAboveMinimumContrast(props.color, '#FFFFFF');
});
</script>
<style scoped>
.ds-dot {
  @apply tw-flex-none tw-inline-block tw-rounded-[40%];
}
.ds-dot.--circle {
  @apply tw-rounded-full;
}
.ds-dot.--border {
  @apply tw-border tw-border-solid tw-border-cool-grey-200;
}
</style>
