import { DependencyBaseModel, SchedulerEventModel, TimeSpan } from '@bryntum/schedulerpro';
import { isAfter, subMilliseconds } from 'date-fns';
import { Composer } from 'vue-i18n';

import { FeatureAccessStore } from '@/common/featureAccessStore';
import { escapeCharacters } from '@/helpers/utils/escapeCharacters';
import { NodeName } from '@/repositories/utils/cache';
import { calculateEndAndKeepPriorDuration } from '@/services/store/schedule/actions/utils/eventCalculations';
import { SchedulerEvent } from '@/services/store/schedule/types';

import { DryingBreakEventParser } from '../../parsers';
import { DepencyCreateTooltipSelectors } from './tooltips.selectors';

type ResizeInput = {
  startDate: Date;
  endDate: Date;
};

type DragInput = {
  startDate: Date;
  eventRecord: SchedulerEventModel;
};

export function getRenderResizeTooltip(i18n: Composer): (context: ResizeInput) => string {
  return ({ startDate, endDate }) => {
    const clippedEndDate = subMilliseconds(endDate, 1);
    const translatedStart = i18n.d(startDate, 'dateWithWeekday').toString();
    const translatedEnd = i18n.d(clippedEndDate, 'dateWithWeekday').toString();

    return generateTableHTMLForScheduleTooltip([
      [`${i18n.t('objects.scheduleEntities.start').toString()}:`, translatedStart],
      [`${i18n.t('objects.scheduleEntities.end').toString()}:`, translatedEnd],
    ]);
  };
}

export function getRenderDragTooltip(i18n: Composer): (context: DragInput) => string {
  return ({ startDate, eventRecord }) => {
    const endDateFromWorkingTimes = calculateEndAndKeepPriorDuration(
      eventRecord as SchedulerEvent,
      startDate,
    );
    const endDateIsAfterStartDate = isAfter(endDateFromWorkingTimes, startDate);
    const clippedEndDate = endDateIsAfterStartDate
      ? subMilliseconds(endDateFromWorkingTimes, 1)
      : endDateFromWorkingTimes;
    const translatedStart = i18n.d(startDate, 'dateWithWeekday').toString();
    const translatedEnd = i18n.d(clippedEndDate, 'dateWithWeekday').toString();

    if (eventRecord.isMilestone) {
      return generateTableHTMLForScheduleTooltip([
        [`${i18n.t('time.date').toString()}:`, translatedStart],
      ]);
    }

    return generateTableHTMLForScheduleTooltip([
      [`${i18n.t('objects.scheduleEntities.start').toString()}:`, translatedStart],
      [`${i18n.t('objects.scheduleEntities.end').toString()}:`, translatedEnd],
    ]);
  };
}

export function getRenderDependencyTooltip(
  i18n: Composer,
  eventMap: Map<string, SchedulerEvent>,
): (dependency: DependencyBaseModel) => any {
  return (dependency) => {
    const toEventName = escapeCharacters(eventMap.get(dependency.to as string)?.name);
    const fromEvent = eventMap.get(dependency.from as string);
    let fromEventName = fromEvent?.name;

    if (fromEvent?.entity === NodeName.DRYING_BREAK) {
      const orderEventName =
        eventMap.get(DryingBreakEventParser.dryingBreakIdToOrderId(fromEvent.id))?.name ?? '';
      fromEventName = `${fromEvent.name} (${orderEventName})`;
    }

    fromEventName = fromEventName && escapeCharacters(fromEventName);

    return toEventName && fromEventName
      ? generateTableHTMLForScheduleTooltip([
          [`${i18n.t('objects.orderDependency.from').toString()}:`, fromEventName],
          [`${i18n.t('objects.orderDependency.to').toString()}:`, toEventName],
        ])
      : '';
  };
}

function getChipText(props: {
  valid: boolean;
  toEvent: SchedulerEvent | undefined;
  i18n: Composer;
  featureAccessStore: FeatureAccessStore;
}): string {
  const { valid, toEvent, i18n, featureAccessStore } = props;

  if (valid) {
    return i18n.t('objects.orderDependency.valid').toString();
  }

  const hasAccesss = featureAccessStore.features.ORDER_PLANNING(toEvent?.id).value.write;

  return hasAccesss
    ? i18n.t('objects.orderDependency.invalid').toString()
    : i18n.t('objects.orderDependency.onlyAssigned').toString();
}

export const dependencyTooltipIcons = {
  'slash-circle-01':
    'M4.93 4.93L19.07 19.07M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z',
  check: 'M20 6L9 17L4 12',
  'arrow-narrow-down': 'M12 4V20M12 20L18 14M12 20L6 14',
};

const getIcon = (iconKey: keyof typeof dependencyTooltipIcons, strokeClass: string) => {
  return `
    <span class="tw-inline-flex tw-items-center tw-justify-center tw-flex-none tw-rounded-full tw-bg-transparent tw-border-none ${strokeClass}" :style="outerStyle">
        <span
            class="tw-blue tw-inline-flex tw-items-center tw-justify-center tw-flex-none"
            style="width: 12px; height: 12px;"
        >
            <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="${dependencyTooltipIcons[iconKey]}" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </span>
    </span>
`;
};

export function getRenderDependencyCreateTooltip(
  i18n: Composer,
  eventMap: Map<string, SchedulerEvent>,
  featureAccessStore: FeatureAccessStore,
): (data: {
  source: TimeSpan;
  target?: TimeSpan | undefined;
  fromSide: string;
  toSide: string;
  valid: boolean;
}) => string {
  return (data) => {
    const fromEvent = eventMap.get(`${data.source?.id}`);
    const fromEventName = escapeCharacters(fromEvent?.name);
    const toEvent = eventMap.get(`${data.target?.id}`);
    const toEventName = data.target ? escapeCharacters(toEvent?.name) : undefined;

    const icon = data.valid ? 'check' : 'slash-circle-01';
    const strokeClass = data.valid ? 'tw-stroke-success-700' : 'tw-stroke-attention-500';

    const chipIcon = getIcon(icon, strokeClass);
    const downArrowIcon = getIcon('arrow-narrow-down', 'tw-stroke-secondary');

    const chipClasses = data.valid
      ? 'tw-bg-success-50 tw-text-success-700'
      : 'tw-bg-attention-50 tw-text-attention-500';

    const chip =
      toEventName && fromEventName
        ? `
        <div class="tw-inline-flex tw-items-center tw-gap-1 tw-truncate tw-px-2 tw-py-1 tw-rounded-xs tw-leading-[14px] ${chipClasses}" data-testid="${DepencyCreateTooltipSelectors.validityChip}">
            ${chipIcon}
            <span class="tw-ds-text-xs--medium">${getChipText({ valid: data.valid, toEvent, i18n, featureAccessStore })}</span>
        </div>
    `
        : '';

    const eventNameClassString = 'tw-max-w-[208px] tw-text-ellipsis tw-overflow-hidden';

    const toContent = data.target
      ? `<span class="${eventNameClassString}" data-testid="${DepencyCreateTooltipSelectors.toName}">${toEventName}</span>`
      : '';

    return `
        <div class="tw-max-w-[208px] tw-flex tw-flex-col tw-gap-2 tw-items-center" data-testid="${DepencyCreateTooltipSelectors.card}">
            <div class="tw-flex tw-flex-col tw-gap-[2px] tw-items-center tw-ds-text-sm--medium tw-text-grey-700">
                <span class="${eventNameClassString}" data-testid="${DepencyCreateTooltipSelectors.fromName}">${fromEventName}</span>
                <span>${downArrowIcon}</span>
                ${toContent}
            </div>
            ${chip}
        </div>
    `;
  };
}

function generateTableHTMLForScheduleTooltip(content: string[][]): string {
  const generateHTMLForTableCell = (cellContent: string, isDescriptionCell = false): string => {
    return cellContent
      ? `<td ${
          isDescriptionCell ? 'style="font-size: 12px; font-weight: bold"' : ''
        }> ${cellContent} </td>`
      : '';
  };

  const generateHTMLForTableRow = (rowContent: string[]): string => {
    if (!rowContent.length) return '';
    const hasMultipleColumns = rowContent.length > 1;
    const rowCells = rowContent.map((cell, index) =>
      generateHTMLForTableCell(cell, hasMultipleColumns && index === 0),
    );
    return `<tr> ${rowCells.join('\n')} </tr>`;
  };

  const generateHTMLForTableContent = (tableContent: string[][]): string => {
    if (!tableContent.length) return '';
    const rows = tableContent.map(generateHTMLForTableRow);
    return rows.join('\n');
  };

  const tooltipTableHTML = `
      <table style="border-spacing: 8px 4px;"> ${generateHTMLForTableContent(content)}</table>
    `;
  return tooltipTableHTML;
}
