import { SchedulerEventModel, SchedulerPro } from '@bryntum/schedulerpro';

import { FeatureAccessStore } from '@/common/featureAccessStore';
import { NodeName } from '@/repositories/utils/cache';
import { ScheduleStore } from '@/services/store/schedule';
import { DryingBreakEventParser } from '@/services/store/schedule/parsers';
import { SchedulerEvent } from '@/services/store/schedule/types';

export type MultiSelectActions = {
  filterAndSetMultiSelectedEvents: (
    filter?: (event: SchedulerEventModel) => boolean,
    selectionOverwrite?: SchedulerEventModel[],
  ) => void;
};

export interface FilterAndSetMultiSelectedEventsParams {
  scheduleStore: ScheduleStore;
  featureAccessStore: FeatureAccessStore;
  scheduler: SchedulerPro;
  filter: (event: SchedulerEvent) => boolean;
  selectionOverwrite?: SchedulerEvent[];
}
export const filterAndSetMultiSelectedEvents = ({
  scheduleStore,
  featureAccessStore,
  scheduler,
  filter,
  selectionOverwrite,
}: FilterAndSetMultiSelectedEventsParams) => {
  const selectedSchedulerEvents = scheduler.selectedEvents as SchedulerEvent[];

  let allowedEvents: SchedulerEventModel[] = [];
  const forbiddenEvents: SchedulerEventModel[] = [];

  if (selectionOverwrite) {
    allowedEvents = selectionOverwrite as SchedulerEventModel[];
  } else {
    selectedSchedulerEvents.forEach((event) => {
      const sanitizedEventId = getSanitizedEventId(event);
      const sanitizedEvent = scheduler.eventStore.getById(sanitizedEventId) as SchedulerEventModel;

      if (canSelectEvent(event, sanitizedEventId, filter, featureAccessStore)) {
        allowedEvents.push(sanitizedEvent);
      } else {
        // Here we use the non sanitized event because we want to deselect drying breaks, not their parent orders
        forbiddenEvents.push(event as SchedulerEventModel);
      }
    });
  }

  if (!allowedEvents.length) {
    scheduleStore.utils.allowEventDeselection = true;
    scheduler.clearEventSelection();
    return;
  }

  const multiSelectedEvents = new Map<string, NodeName>(
    allowedEvents.map((event) => [`${event.id}`, (event as SchedulerEvent).entity]),
  );

  scheduleStore.utils.isMultiSelecting = true;
  scheduleStore.utils.multiSelectedEvents = multiSelectedEvents;

  scheduler.selectEvents(allowedEvents, !selectionOverwrite);
  scheduler.deselectEvents(forbiddenEvents);

  scheduleStore.utils.isMultiSelecting = false;
};

// For drying breaks we return the parent order id, for regular events we return the event id
function getSanitizedEventId(event: SchedulerEvent): string {
  return event.entity === NodeName.DRYING_BREAK
    ? DryingBreakEventParser.dryingBreakIdToOrderId(event.id)
    : event.id;
}

function canSelectEvent(
  event: SchedulerEvent,
  orderId: string,
  filter: (event: SchedulerEvent) => boolean,
  featureAccessStore: FeatureAccessStore,
): boolean {
  return (
    event.isSelectable &&
    filter(event) &&
    featureAccessStore.features.ORDER_PLANNING(orderId, true).value.write
  );
}
