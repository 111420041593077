import { SchedulerPro } from '@bryntum/schedulerpro';

import { PageOrientation } from '@/features/scheduleExport/pdfExport/types';

import { PdfCustomExportConfig } from '../../scheduleExport/types';

const FRAMES_PER_SECOND = 60;

// Rendering after zooming takes a while (especially dependencies), so we add a little timout
// to make sure everything looks as expected
export async function waitNumberOfRenderCycles(count = FRAMES_PER_SECOND): Promise<void> {
  let counter = 0;
  while (counter < count) {
    await new Promise(requestAnimationFrame);
    counter++;
  }
}

const PAPERSIZE_IN_PX = {
  A1: {
    height: 3177,
    width: 2246,
  },
  A2: {
    height: 2246,
    width: 1583,
  },
  A3: {
    height: 1583,
    width: 1122,
  },
  A4: {
    height: 1122,
    width: 792,
  },
};

/** Returns the width of the paper (depending on the orientation) for common din paper formats assuming 96 PPI */
export function getPaperWidthInPx(
  config: Pick<PdfCustomExportConfig, 'orientation' | 'paperFormat'>,
): number {
  const isLandscape = config.orientation === PageOrientation.LANDSCAPE;
  const papersizeForFormat = PAPERSIZE_IN_PX[config.paperFormat] ?? PAPERSIZE_IN_PX.A4;
  const width = isLandscape ? papersizeForFormat.height : papersizeForFormat.width;
  return width;
}

export function getPaperHeightInPx(
  config: Pick<PdfCustomExportConfig, 'orientation' | 'paperFormat'>,
): number {
  const isLandscape = config.orientation === PageOrientation.LANDSCAPE;
  const papersizeForFormat = PAPERSIZE_IN_PX[config.paperFormat] ?? PAPERSIZE_IN_PX.A4;
  const height = isLandscape ? papersizeForFormat.width : papersizeForFormat.height;
  return height;
}

export const EXPORT_RESOURCE_MARGIN = 2;
export const EXPORT_BAR_MARGIN = EXPORT_RESOURCE_MARGIN;

export const getEventHeightForRowHeight = (schedulerInstance, rowHeight?: number) => {
  // this is only roughly correct, but we can expect the resource margin and bar margin to be the same for exports
  return (rowHeight || schedulerInstance.rowHeight) - 2 * schedulerInstance.resourceMargin;
};

export const getRowHeightForEventHeight = (schedulerInstance: SchedulerPro, eventHeight) => {
  return eventHeight + 2 * (schedulerInstance.resourceMargin as number);
};
