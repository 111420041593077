import {
  PresetStore,
  ResourceModel,
  SchedulerEventModel,
  SchedulerPro,
  ViewPreset,
  ViewPresetConfig,
} from '@bryntum/schedulerpro';

/**
 * Refreshes all the rows of the scheduler to update the UI in case any external data changed.
 * @param scheduler
 * @returns
 */
export function refreshScheduleUI(scheduler: SchedulerPro | undefined): void {
  if (!scheduler) return;

  if (scheduler.features.lockRows.disabled) {
    scheduler.refreshRows();
  } else {
    scheduler.features.lockRows.subViews.forEach((subView) => {
      const subScheduler = subView as SchedulerPro;
      subScheduler.refreshRows();
    });
  }
}

/**
 * Expands a collapsed resource in the scheduler.
 * @param scheduler
 * @param resourceId
 * @returns
 */
export async function expandResource(
  scheduler: SchedulerPro | undefined,
  resourceId: string,
): Promise<void> {
  if (!scheduler) return;

  const resource = scheduler.resourceStore.getById(resourceId) as ResourceModel;
  if (resource.expanded) return;

  if (scheduler.features.lockRows.disabled) {
    return scheduler.expand(resource);
  } else {
    return Promise.all(
      scheduler.features.lockRows.subViews.map((subView) => {
        const subScheduler = subView as SchedulerPro;
        return subScheduler.expand(resource);
      }),
    ).then(() => {});
  }
}

/**
 * Collapses an expanded resource in the scheduler.
 * @param scheduler
 * @param resourceId
 * @returns
 */
export async function collapseResource(
  scheduler: SchedulerPro | undefined,
  resourceId: string,
): Promise<void> {
  if (!scheduler) return;

  const resource = scheduler.resourceStore.getById(resourceId) as ResourceModel;
  if (!resource.expanded) return;

  if (scheduler.features.lockRows.disabled) {
    return scheduler.collapse(resource);
  } else {
    return Promise.all(
      scheduler.features.lockRows.subViews.map((subView) => {
        const subScheduler = subView as SchedulerPro;
        return subScheduler.collapse(resource);
      }),
    ).then(() => {});
  }
}

/**
 * Scrolls the schedule horizontally to the specified date, positioning the date either at the start, center or end of the viewport.
 * @param scheduler
 * @param date
 * @param position
 * @returns
 */
export async function scrollToDate(
  scheduler: SchedulerPro | undefined,
  date: Date,
  position: 'start' | 'center' | 'end' = 'center',
): Promise<void> {
  if (!scheduler) return;

  return scheduler.scrollToDate(date, { block: position });
}

/**
 * Scrolls the scheduler vertically to a specified resource, positioning the resource either at the start, center or end of the viewport.
 * @param scheduler
 * @param resourceId
 * @param position
 * @returns
 */
export async function scrollToResource(
  scheduler: SchedulerPro | undefined,
  resourceId: string,
  position: 'start' | 'center' | 'end' = 'center',
): Promise<void> {
  if (!scheduler) return;

  if (scheduler.features.lockRows.disabled) {
    const resource = scheduler.resourceStore.getById(resourceId) as ResourceModel;
    if (!resource) return;
    return scheduler.scrollRowIntoView(resource, { block: position });
  } else {
    return Promise.all(
      scheduler.features.lockRows.subViews.map((subView) => {
        const subScheduler = subView as SchedulerPro;
        const resource = subScheduler.resourceStore.getById(resourceId) as ResourceModel;
        if (!resource) return;
        return subView.scrollRowIntoView(resource, { block: position });
      }),
    ).then(() => {});
  }
}

/**
 * Scrolls the scheduler horizontally and vertically to a specified event, positioning the event either at the start, center or end of the viewport.
 * @param scheduler
 * @param eventId
 * @param position
 * @returns
 */
export async function scrollToEvent(
  scheduler: SchedulerPro | undefined,
  eventId: string,
  position: 'start' | 'center' | 'end' = 'center',
): Promise<void> {
  if (!scheduler) return;

  const event = scheduler.eventStore.getById(eventId) as SchedulerEventModel;
  if (!event) return;

  const resource = scheduler.resourceStore.getById(event.resourceId) as ResourceModel;
  if (!resource) return;

  await scheduler.scrollRowIntoView(resource);
  return scheduler.scrollToDate(new Date(event.startDate), { block: position });
}

/**
 * Returns the current view preset applied to the scheduler.
 * @param scheduler
 * @returns
 */
export function getCurrentViewPreset(scheduler: SchedulerPro | undefined): ViewPreset | undefined {
  return scheduler?.viewPreset as ViewPreset;
}

/**
 * Returns the current view preset applied to the scheduler.
 * @param scheduler
 * @returns
 */
export function registerViewPresets(
  scheduler: SchedulerPro | undefined,
  presets: ViewPresetConfig[],
): void {
  if (!scheduler) return;

  (scheduler.presets as PresetStore).removeAll();
  (scheduler.presets as PresetStore).add(presets);
}

/**
 * Zooms the scheduler to a specific view preset.
 * @param scheduler
 * @param presetId
 * @returns
 */
export function zoomToViewPreset(scheduler: SchedulerPro | undefined, presetId: string): void {
  if (!scheduler) return;

  scheduler.zoomTo({ preset: presetId });
}
