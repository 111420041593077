import { SchedulerPro } from '@bryntum/schedulerpro';
import { Composer } from 'vue-i18n';

import { NodeName } from '@/repositories/utils/cache';
import { ScheduleStore } from '@/services/store/schedule';
import { EventBus } from '@/utils/eventBus';

export function showImmutableHint(
  eventBus: EventBus,
  i18n: Composer,
  type: 'completedFixed' | 'assignment',
  overwrite = true,
): void {
  eventBus.emit(
    'new-notification',
    i18n.t(
      type === 'completedFixed'
        ? 'errors.scheduler.completedFixedImmutableHint'
        : 'errors.scheduler.assignmentImmutableHint',
    ),
    undefined,
    undefined,
    undefined,
    overwrite,
  );
}

export function targetIsRowExpandButton(event: MouseEvent): boolean {
  return (
    event.target instanceof HTMLElement &&
    Array.from(event.target.classList).some((c) => c.includes('milestone-expand-row-button'))
  );
}

export function cannotShowEventHover({
  isScrolling,
  scheduler,
  scheduleStore,
  entity,
  isPlaceholderOrDryingBreakPlaceholder = false,
  isOverlappingMilestone = false,
  targetIsRowExpandButton = false,
}: {
  isScrolling: boolean;
  scheduler: SchedulerPro | null | undefined;
  scheduleStore: ScheduleStore;
  entity?: NodeName;
  isPlaceholderOrDryingBreakPlaceholder?: boolean;
  isOverlappingMilestone?: boolean;
  targetIsRowExpandButton?: boolean;
}) {
  return Boolean(
    isScrolling ||
      scheduler?.selectedEvents.length ||
      scheduleStore.isSidebarOpen ||
      scheduleStore.utils.isDraggingEvent ||
      scheduleStore.utils.isDraggingDependency ||
      scheduleStore.utils.showDragCursor ||
      scheduleStore.utils.disableEventInteractions ||
      (entity && [NodeName.SECTION, NodeName.SECTION_BASEPLAN].includes(entity)) ||
      isPlaceholderOrDryingBreakPlaceholder ||
      isOverlappingMilestone ||
      targetIsRowExpandButton,
  );
}
