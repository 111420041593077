<template>
  <span
    class="ds-input ds-input-wrapper"
    :class="[
      USERBACK_IGNORE_CLASS,
      {
        '--filled': !!modelValue,
        '--destructive': destructive,
        '--focused': focused,
        '--disabled': disabled,
        '--limit-width': limitWidth,
        '--readonly': readonly,
      },
      $attrs.class,
    ]"
    v-bind="{
      style: $attrs.style as string | undefined,
      dataTestId: $attrs['data-test-id'],
    }"
  >
    <DsDot v-if="isColor && modelValue" :color="modelValue as string" />
    <DsSvgIcon
      v-if="prependIcon"
      class="ds-input-icon --prepend"
      :class="{ 'tw-cursor-move': draggable }"
      :icon="prependIcon"
      size="md"
    />
    <input
      ref="dsInput"
      v-model.trim="model"
      :class="[USERBACK_IGNORE_CLASS, $attrs.class]"
      v-bind="omitKeys($attrs, ['class', 'style', 'data-test-id'])"
      :disabled="disabled"
      :readonly="readonly"
      @keydown.esc="onEscape"
      @vue:mounted="onInputMounted"
    />
    <DsSvgIcon
      v-if="appendIcon"
      class="ds-input-icon --append"
      size="sm"
      :custom-stroke-width="1.6"
      :icon="appendIcon"
      @click="$emit('click-append-icon')"
    />

    <DsInputCloseButton
      v-if="clearable && !!modelValue"
      class="close-button"
      @click="() => (model = '')"
    />

    <slot />

    <DsCopyButton v-if="copyable && !!modelValue" :value-to-copy="modelValue" />
  </span>
</template>
<script setup lang="ts">
import './input.css';

import { SvgIconType } from '@/assets/generated/svgIcons/_types';
import { blurActiveElement } from '@/ds-components/utils/blur';
import { omitKeys } from '@/helpers/utils/objects';
import { USERBACK_IGNORE_CLASS } from '@/plugins/userback';

import { InputModelValue, inputProps } from './utils/input';

defineEmits<{
  (e: 'click-append-icon'): void;
}>();

const props = defineProps({
  errorMessage: inputProps().errorMessage,
  immediateFocus: {
    type: Boolean,
    default: false,
  },
  disabled: { type: Boolean, default: false },
  destructive: { type: Boolean, default: false },
  focused: { type: Boolean, default: false },
  appendIcon: {
    type: String as PropType<SvgIconType | null>,
    default: null,
  },
  prependIcon: {
    type: String as PropType<SvgIconType | null>,
    default: null,
  },
  copyable: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  isColor: {
    type: Boolean,
    default: false,
  },
  limitWidth: {
    type: Boolean,
    default: false,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  autofocusTimeout: {
    type: Number,
    default: 0,
  },
  autoselect: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  draggable: {
    type: Boolean,
    default: false,
  },
});

const model = defineModel<InputModelValue>();

const onInputMounted = (event) => {
  setTimeout(() => {
    if (props.autofocus) {
      event.el?.focus();
    }
    if (props.autoselect) {
      event.el?.select();
    }
  }, props.autofocusTimeout);
};

const dsInput = ref<HTMLInputElement | null>(null);

onMounted(() => {
  if (props.immediateFocus) dsInput.value?.focus();
});

const onEscape = (event: KeyboardEvent) => {
  blurActiveElement(event);
};
</script>

<script lang="ts">
export default { inheritAttrs: false };
</script>

<style>
.ds-input-wrapper {
  @apply tw-relative;
}
.ds-input-wrapper input {
  @apply tw-flex-1 tw-outline-none tw-self-stretch;
}
.ds-input-icon {
  @apply tw-text-grey-400;
}
.ds-input-wrapper.--sm .ds-input-icon.--prepend {
  @apply tw-left-3;
}
.ds-input-wrapper.--md .ds-input-icon.--prepend {
  @apply tw-left-[0.875rem];
}
.ds-input-wrapper .close-button {
  @apply tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-h-0 tw-w-0 tw-p-4;
  @apply tw-right-2;
}
.ds-input-wrapper .ds-input-icon + .close-button {
  @apply tw-right-8;
}
.ds-input-wrapper.--sm .ds-input-icon.--append {
  @apply tw-right-3;
}
.ds-input-wrapper.--md .ds-input-icon.--append {
  @apply tw-right-[0.875rem];
}

.ds-input-wrapper.ds-input.--destructive .ds-input-icon.--append,
.ds-input.ds-input-wrapper.--destructive:focus-within .ds-input-icon.--append,
.ds-input.ds-input-wrapper.--destructive.--focused .ds-input-icon.--append {
  @apply tw-text-attention-500;
}

.ds-input-wrapper.--disabled .ds-input-icon {
  @apply tw-text-secondary-light tw-mix-blend-multiply;
}
</style>
