import { Composer } from 'vue-i18n';

import { sectionSelectors } from '@/common/selectors/section';
import { WbsSectionEntity } from '@/common/types';
import { TreeNode } from '@/helpers/utils/orderedTree';
import Button from '@/jsx/components/Button';
import Modal from '@/jsx/components/Modal';
import { h } from '@/jsx/jsxFactory';

type SectionDeleteConfirmationDialogProps = {
  section: TreeNode<WbsSectionEntity>;
  onConfirm: (section: WbsSectionEntity) => void;
  onCancel: () => void;
  i18n: Composer;
};

export default function SectionDeleteConfirmationDialog({
  section,
  onConfirm,
  onCancel,
  i18n,
}: SectionDeleteConfirmationDialogProps) {
  const onSubmit = (event: Event) => {
    event.preventDefault();
    onConfirm(section);
  };

  const getDeleteSectionTitle = () => {
    if (!section.parentId) return i18n.t('objects.section.deleteTopLevel');

    return section.children.length
      ? i18n.t('objects.section.deleteMidLevel')
      : i18n.t('objects.section.deleteBottomLevel');
  };

  const getDeleteSectionMessageKeyPath = () => {
    return section.children.length
      ? 'objects.section.deleteWithChildrenDialogMessage'
      : 'objects.section.deleteWithoutChildrenDialogMessage';
  };

  const HeaderSupportingText = (): HTMLElement => {
    const supportingTexts = i18n
      .t(getDeleteSectionMessageKeyPath(), { section: '{section}' })
      .split('{section}');
    return (
      <div class="tw-ds-text-sm tw-text-cool-grey-500">
        {supportingTexts[0]} <span class="tw-ds-text-sm--medium">{section.name}</span>{' '}
        {supportingTexts[1]}
      </div>
    );
  };

  return (
    <Modal
      className="tw-w-[540px]"
      footerAlignment="horizontal"
      headerAlignment="horizontal-left"
      dataTestid={sectionSelectors.deleteConfirmationDialog.dialog}
      headerText={getDeleteSectionTitle()}
      headerSupportingText={<HeaderSupportingText />}
      submitAction={onSubmit}
    >
      <Button hierarchy="tertiary-grey" onClick={onCancel}>
        {i18n.t('actions.cancel')}
      </Button>
      <Button
        hierarchy="primary"
        destructive
        type="submit"
        dataTestid={sectionSelectors.deleteConfirmationDialog.confirmButton}
      >
        {i18n.t('actions.deleteNow')}
      </Button>
    </Modal>
  );
}
