import { NodeName } from '@/repositories/utils/cache';

export enum BaseActualStatus {
  ON_TIME = 'ON_TIME',
  DELAYED = 'DELAYED',
  AHEAD_OF_TIME = 'AHEAD_OF_TIME',
  UNAVAILABLE = 'UNAVAILABLE',
}

export const BaseActualColor = {
  ON_TIME: '--color-success-800',
  DELAYED: '--color-attention-500',
  AHEAD_OF_TIME: '--color-success-500',
  UNAVAILABLE: '--color-cool-grey-300',
};

export const BaseActualEntities = new Set([
  NodeName.MILESTONE,
  NodeName.ORDER,
  NodeName.SECTION,
  NodeName.DRYING_BREAK,
]);

export type BaseOrderState = {
  id: string;
  startAt: Date;
  finishAt: Date;
  dryingBreakDuration: number | undefined;
};

export type BaseMilestoneState = {
  id: string;
  date: Date;
};

export type BaseSectionState = {
  startAt: Date;
  finishAt: Date;
};

export type BaseDryingBreakState = {
  startAt: Date;
  finishAt: Date;
};

export type BasePlan = {
  id: string;
  name: string;
  progress: number;
  basePlanTime: Date;
  createdAt: Date;
  updatedAt: Date;
};

export type BaseState = {
  status: BaseActualStatus;
  timeDifference: number | undefined;
};

export type BaseEntityState<T extends BaseOrderState | BaseMilestoneState | BaseSectionState> =
  BaseState & {
    entity: T | undefined;
  };
