<template>
  <Teleport :to="teleportTarget">
    <div
      class="ds-modal-overlay"
      :class="{ '--visible': isModalVisible }"
      :style="{ 'z-index': zIndex }"
      @mousedown.self="persist ? undefined : $emit('close-request')"
    >
      <slot name="custom" />

      <component
        :is="is"
        v-if="!$slots.custom"
        v-bind="{ ...$attrs }"
        ref="bodyElement"
        class="ds-modal-content"
        :class="{ '--horizontal': horizontal }"
      >
        <header
          v-if="headerAlignment !== 'hidden'"
          class="ds-modal-header tw-w-full"
          :class="`--${headerAlignment}`"
        >
          <DsSvgIcon v-if="headerIcon" :color="status" :icon="headerIcon" theme="halo-light" />

          <div class="tw-flex tw-w-full tw-justify-between tw-flex-row tw-items-center">
            <div class="tw-w-full">
              <h2 v-if="$slots['header-text']" class="ds-modal-header-text">
                <slot name="header-text" />
              </h2>
              <p v-if="$slots['header-supporting-text']" class="ds-modal-header-supporting-text">
                <slot name="header-supporting-text" />
              </p>
            </div>
            <div class="tw-flex tw-justify-between tw-items-center ds-modal-header-close-button">
              <slot name="header-actions" />
              <DsCloseButton v-if="!hideCloseButton" @click="$emit('close-request')" />
            </div>
          </div>
        </header>

        <DsDivider v-if="divide" divider-color="tw-bg-secondary-light" />

        <div v-if="$slots.default" :class="{ 'ds-modal-content-body': !noBodyStyling }">
          <slot name="default"></slot>
        </div>

        <DsDivider
          v-if="divide && footerAlignment !== 'hidden'"
          class="tw-pb-3"
          divider-color="tw-bg-secondary-light"
        />

        <footer
          v-if="footerAlignment !== 'hidden'"
          class="ds-modal-footer"
          :class="`--${footerAlignment}`"
        >
          <div v-if="$slots['actions-pre']" class="ds-modal-footer-actions-pre">
            <slot name="actions-pre" />
          </div>
          <slot name="actions" />
        </footer>
      </component>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { SvgIconType } from '@/assets/generated/svgIcons/_types';
import { useClosableStack } from '@/common/composables/closable';
import { useEnterListener } from '@/common/composables/enterListener';
import DsCloseButton from '@/ds-components/buttons/DsCloseButton.vue';

import { DsModalFooterAlignment, DsModalHeaderAlignment, DsModalStatus } from './types';

const props = defineProps({
  footerAlignment: {
    type: String as PropType<DsModalFooterAlignment>,
    default: 'horizontal',
  },
  headerAlignment: {
    type: String as PropType<DsModalHeaderAlignment>,
    default: 'left',
  },
  headerIcon: {
    type: String as PropType<SvgIconType | null>,
    default: null,
  },
  hideCloseButton: { type: Boolean, default: false },
  noBodyStyling: { type: Boolean, default: false },
  divide: {
    type: Boolean,
    default: false,
  },
  is: { type: String as PropType<'aside' | 'form'>, default: 'aside' },
  persist: { type: Boolean, default: false },
  status: { type: String as PropType<DsModalStatus>, default: 'success' },
  horizontal: { type: Boolean, default: false },
  zIndex: { type: Number, default: 9999 },
});
const emit = defineEmits<{ (e: 'close-request'): void }>();

const isModalVisible = ref(false);
setTimeout(() => {
  isModalVisible.value = true;
});

const bodyElement = ref<HTMLElement | null>(null);
if (props.is === 'form') {
  useEnterListener(() => {
    // Trigger native form submit when pressing enter while the modal is opened
    bodyElement.value?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  });
}

const hasCloseButton = computed(() => !props.hideCloseButton);

const closableStack = useClosableStack();
const closable = {
  close: () => {
    emit('close-request');
  },
  persist: Boolean(!hasCloseButton.value),
};

const teleportTarget = ref(document.querySelector('#app.application') || document.body);

onMounted(() => {
  document.documentElement.classList.add('tw-overflow-hidden');
  if (hasCloseButton.value) {
    closableStack.pushClosable(closable);
  }
});
onUnmounted(() => {
  document.documentElement.classList.remove('tw-overflow-hidden');
  if (hasCloseButton.value) {
    closableStack.removeClosable(closable);
  }
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style scoped>
.ds-modal-overlay {
  @apply tw-m-0 !important;
  @apply tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;
  @apply tw-bg-blend-overlay tw-z-[9999];
  @apply tw-transition-opacity tw-duration-150 tw-opacity-0 tw-pointer-events-none;
  @apply tw-flex tw-justify-center tw-items-center;
  @apply tw-ds-blur-md--dark;
}

.ds-modal-content {
  @apply tw-bg-white tw-shadow-xl tw-rounded-sm tw-w-[400px] tw-max-h-[calc(100vh-48px)] tw-overflow-y-auto;
}
.ds-modal-content.--horizontal {
  @apply tw-w-[544px];
}

.ds-modal-content-body {
  @apply tw-px-6 tw-pb-5;
}

.ds-modal-overlay.--visible {
  @apply tw-opacity-100 tw-pointer-events-auto;
}

.ds-modal-header {
  @apply tw-relative tw-gap-4 tw-pl-6 tw-pr-14 tw-pt-6 tw-pb-5;
}
.ds-modal-header.--left {
  @apply tw-flex tw-flex-col tw-items-start;
}
.ds-modal-header.--center {
  @apply tw-flex tw-flex-col tw-items-center tw-text-center;
}
.ds-modal-header.--horizontal-left {
  @apply tw-flex tw-flex-row tw-items-start;
}

.ds-modal-header-close-button {
  @apply tw-absolute tw-top-3 tw-right-3;
}

.ds-modal-header-text {
  @apply tw-ds-text-xl--medium tw-text-secondary tw-break-words;
}

.ds-modal-header-supporting-text {
  @apply tw-mt-1 tw-ds-text-sm tw-text-cool-grey-600 tw-whitespace-pre-line;
}

.ds-modal-footer {
  @apply tw-flex tw-gap-3 tw-mt-3 tw-px-6 tw-pb-6;
}
.ds-modal-footer.--horizontal {
  @apply tw-flex-row tw-justify-end;
}
.ds-modal-footer.--vertical {
  @apply tw-flex-col;
}
.ds-modal-footer-actions-pre {
  @apply tw-flex tw-items-center tw-grow;
}
</style>
