import { InvokedConstraint } from '@koppla-tech/scheduling-engine';
import { differenceInMinutes } from 'date-fns';

import { convertFromUtcToLocalTimezone } from '@/common/composables/timezones';
import { useNotificationStore } from '@/features/notifications';
import {
  DependencyLagViolatedEvent,
  getDependencyTypeOfSelection,
  showOverruleExistingDependenciesNotification,
  useOrderDependencyStore,
} from '@/features/orderDependencies';
import { useOrderStore } from '@/features/orders';
import { usePauseStore } from '@/features/pauses';
import { showActionFailedNotification } from '@/features/realTimeCollaboration';
import { useLocalOrderSchedulingEngine } from '@/features/schedule';
import { getCurrentSchedulingContext } from '@/features/schedule/utils';
import { isEqual } from '@/helpers/utils/objects';
import { LoggingService } from '@/interfaces/services';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { isPlaceholderOrDryingBreakPlaceholder } from '@/services/store/schedule/actions/placeholderEvent';
import { SchedulerEvent } from '@/services/store/schedule/types';
import { SchedulerPopupComponent, useSchedulePopupStore } from '@/services/store/schedulePopup';
import { trackUpdate } from '@/utils/analyticsEvents/scheduleTracking';

import { DryingBreakEventParser } from '../../../parsers';
import { getMainResourceId, getPlaceholderEventId } from '../../../parsers/base';

export interface BeforeEventResizeFinalizeListener {
  beforeEventResizeFinalize: ReturnType<typeof useBeforeEventResizeFinalizeListener>;
}

export function useBeforeEventResizeFinalizeListener(
  store: ScheduleStore,
  loggingService: LoggingService,
): (data: {
  context: {
    async: boolean;
    finalize: (allow?: boolean) => void;
    date: Date;
    startDate: Date | undefined;
    endDate: Date | undefined;
    eventRecord: SchedulerEvent;
  };
  startDate: Date | undefined;
  originalStartDate: Date | undefined;
}) => void {
  return (data) => {
    // We need to make it async in order to be able to use finalize
    const { context } = data;
    context.async = true;

    const scheduler = getScheduler();

    if (!scheduler) {
      context.finalize(false);
      return;
    }

    if (!scheduler.eventStore.getById(context.eventRecord.id)) {
      showActionFailedNotification();
      context.finalize(false);
      return;
    }

    const originalStart = new SchedulingDate(context.eventRecord.startDate);
    const originalEnd = new SchedulingDate(context.eventRecord.endDate);

    const isStartDateUpdated =
      data.startDate !== undefined &&
      data.originalStartDate !== undefined &&
      !isEqual(data.startDate, data.originalStartDate);
    const newDate = new SchedulingDate(context.date);

    if (isPlaceholderOrDryingBreakPlaceholder(context.eventRecord)) {
      handlePlaceholderEventUpdate(store, context.eventRecord, newDate, isStartDateUpdated);
      // We currently allow resizing to any date, so we can safely accept this
      context.finalize(true);
      return;
    }

    // We automatically reschedule the event to the nearest working time by default
    const { scheduledStartDate, scheduledEndDate, invokedConstraints } = performScheduling(
      newDate,
      isStartDateUpdated,
      originalStart,
      originalEnd,
      context.eventRecord.id,
      context.eventRecord.calendarId,
    );

    // We need to update the UI according to the scheduled change
    nextTick(() => {
      context.eventRecord.startDate = convertFromUtcToLocalTimezone(scheduledStartDate);
      context.eventRecord.endDate = convertFromUtcToLocalTimezone(scheduledEndDate);
      // We always need to revert the update here, as the UI wouldn't be updated correctly otherwise when rescheduled to valid dates,
      context.finalize(false);
    });

    if (invokedConstraints.length) {
      showOverruleExistingDependenciesNotification(invokedConstraints);
      const orderDependencyStore = useOrderDependencyStore();
      const dependencies = invokedConstraints.map((c) =>
        orderDependencyStore.dependencies.get(c.dependencyId),
      );
      loggingService.trackEvent(
        new DependencyLagViolatedEvent({
          method: 'resize',
          dependencyType: getDependencyTypeOfSelection(dependencies),
          count: invokedConstraints.length,
          source: 'schedule',
        }),
      );
    }

    if (!isValidWorkingTime(newDate, context.eventRecord.calendarId, isStartDateUpdated)) {
      showNonWorkingTimeNotification(
        new SchedulingDate(context.date),
        context.eventRecord,
        scheduledStartDate,
        scheduledEndDate,
        isStartDateUpdated,
      );
      loggingService.trackEvent(
        new loggingService.AnalyticEventCategories.OrdersMovedOutsideWorkingTimesEvent({
          canAddException: true,
        }),
      );
    }

    if (hasChanges(scheduledStartDate, scheduledEndDate, originalStart, originalEnd)) {
      handleEntityUpdate(context, scheduledStartDate, scheduledEndDate, originalStart, originalEnd);
      const groups = { [context.eventRecord.entity]: 1 };
      trackUpdate(groups, loggingService, 'resize');
    }
  };
}

function showNonWorkingTimeNotification(
  newDate: Date,
  eventRecord: SchedulerEvent,
  scheduledStartDate: Date,
  scheduledEndDate: Date,
  isStartDateUpdated: boolean,
): void {
  const notificationStore = useNotificationStore();

  notificationStore.push({
    titleI18nKey: 'objects.nonWorkingTimeResolution.notificationTitle',
    titleI18nKeyVariables: {
      count: 1,
    },
    bodyI18nKey: isStartDateUpdated
      ? 'objects.nonWorkingTimeResolution.notificationSubtitleStart'
      : 'objects.nonWorkingTimeResolution.notificationSubtitleEnd',
    bodyI18nKeyVariables: {
      count: 1,
    },
    primaryAction: {
      callback: () => {
        const schedulePopupStore = useSchedulePopupStore();
        schedulePopupStore.openPopup({
          component: SchedulerPopupComponent.NON_WORKING_TIME_RESOLUTION,
          payload: {
            events: [
              {
                id: eventRecord.id,
                newDate,
                newResourceId: eventRecord.resourceId ?? '',
                originalStartDate: scheduledStartDate,
                originalEndDate: scheduledEndDate,
                calendarId: eventRecord.calendarId ?? '',
                type: isStartDateUpdated ? 'start' : 'end',
              },
            ],
          },
        });
      },
      i18nKey: 'objects.nonWorkingTimeResolution.notificationAction',
    },
    type: 'blue',
    timeout: 5000,
  });
}

function performScheduling(
  date: Date,
  isStartDateUpdated: boolean,
  originalStart: Date,
  originalEnd: Date,
  id: string,
  calendarId: string | null | undefined,
): {
  scheduledStartDate: Date;
  scheduledEndDate: Date;
  invokedConstraints: InvokedConstraint[];
} {
  if (!calendarId) {
    if (isStartDateUpdated) {
      return {
        scheduledStartDate: date,
        scheduledEndDate: originalEnd,
        invokedConstraints: [],
      };
    }
    return {
      scheduledStartDate: originalStart,
      scheduledEndDate: date,
      invokedConstraints: [],
    };
  }

  const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();

  const duration = localOrderSchedulingEngine.utils.computeWorkingTimeBetween(
    isStartDateUpdated ? originalEnd : date,
    isStartDateUpdated ? date : originalStart,
    calendarId,
  );

  const { changes, meta } = localOrderSchedulingEngine.schedule(
    {
      update: {
        orders: [
          {
            id,
            ...(isStartDateUpdated ? { startAt: date, duration } : { finishAt: date, duration }),
          },
        ],
      },
    },
    getCurrentSchedulingContext(),
    { stateless: true, minimal: true },
  );

  const orderChange = changes.update!.orders![0];
  const scheduledStartDate = orderChange.startAt ?? originalStart;
  const scheduledEndDate = orderChange.finishAt ?? originalEnd;

  const relevantInvokedConstraints = meta?.invokedConstraints.filter(
    (constraint) => constraint.toId === id,
  );

  return {
    scheduledStartDate,
    scheduledEndDate,
    invokedConstraints: relevantInvokedConstraints,
  };
}

function hasChanges(
  newStartDate: Date,
  newEndDate: Date,
  originalStart: Date,
  originalEnd: Date,
): boolean {
  return !isEqual(newStartDate, originalStart) || !isEqual(newEndDate, originalEnd);
}

function handleEntityUpdate(
  context: { eventRecord: SchedulerEvent },
  newStartDate: Date,
  newEndDate: Date,
  originalStart: Date,
  originalEnd: Date,
): void {
  if (context.eventRecord.entity === NodeName.ORDER) {
    const orderStore = useOrderStore();

    orderStore
      .update([
        {
          id: context.eventRecord.id,
          startAt: newStartDate,
          finishAt: newEndDate,
        },
      ])
      .catch(() => {
        nextTick(() => {
          context.eventRecord.startDate = convertFromUtcToLocalTimezone(originalStart);
          context.eventRecord.endDate = convertFromUtcToLocalTimezone(originalEnd);
        });
      });
  }

  if (context.eventRecord.entity === NodeName.DRYING_BREAK) {
    const orderStore = useOrderStore();
    const id = DryingBreakEventParser.dryingBreakIdToOrderId(context.eventRecord.id);
    const order = orderStore.orders.get(id)!;

    orderStore
      .update([
        {
          id,
          dryingBreak: {
            name: order.dryingBreak!.name,
            duration: differenceInMinutes(newEndDate, newStartDate),
          },
        },
      ])
      .catch(() => {
        nextTick(() => {
          context.eventRecord.startDate = convertFromUtcToLocalTimezone(originalStart);
          context.eventRecord.endDate = convertFromUtcToLocalTimezone(originalEnd);
        });
      });
  }

  if (context.eventRecord.entity === NodeName.PAUSE) {
    const pauseStore = usePauseStore();

    pauseStore
      .update([
        {
          id: context.eventRecord.id,
          start: newStartDate,
          end: newEndDate,
        },
      ])
      .catch(() => {
        nextTick(() => {
          context.eventRecord.startDate = convertFromUtcToLocalTimezone(originalStart);
          context.eventRecord.endDate = convertFromUtcToLocalTimezone(originalEnd);
        });
      });
  }
}

function handlePlaceholderEventUpdate(
  store: ScheduleStore,
  event: SchedulerEvent,
  newDate: Date,
  isStartDateUpdated: boolean,
): void {
  if (event.entity === NodeName.PAUSE) {
    store.updatePlaceholderEvent({
      startDate: isStartDateUpdated ? newDate : event.startDate,
      endDate: isStartDateUpdated ? event.endDate : newDate,
      entity: NodeName.PAUSE,
      resourceId: getMainResourceId(),
    });
  }

  if (event.entity === NodeName.ORDER) {
    const dryingBreakPlaceholderId =
      DryingBreakEventParser.orderIdToDryingBreakId(getPlaceholderEventId());
    const placeholderEvent = store.entities.events.get(getPlaceholderEventId())!;
    const dryingBreakPlaceholderEvent = store.entities.events.get(dryingBreakPlaceholderId);

    store.updatePlaceholderEvent({
      startDate: isStartDateUpdated ? newDate : event.startDate,
      endDate: isStartDateUpdated ? event.endDate : newDate,
      entity: NodeName.ORDER,
      resourceId: event.resourceId,
      calendarId: placeholderEvent.calendarId,
      dryingBreak: dryingBreakPlaceholderEvent
        ? {
            name: dryingBreakPlaceholderEvent.name,
            duration: dryingBreakPlaceholderEvent.duration,
          }
        : null,
    });
  }

  if (event.entity === NodeName.DRYING_BREAK) {
    const dryingBreakPlaceholderId =
      DryingBreakEventParser.orderIdToDryingBreakId(getPlaceholderEventId());
    const placeholderEvent = store.entities.events.get(getPlaceholderEventId())!;
    const dryingBreakPlaceholderEvent = store.entities.events.get(dryingBreakPlaceholderId)!;

    store.updatePlaceholderEvent({
      startDate: placeholderEvent.startDate,
      endDate: placeholderEvent.endDate,
      entity: NodeName.ORDER,
      resourceId: placeholderEvent.resourceId,
      calendarId: placeholderEvent.calendarId,
      dryingBreak: {
        name: dryingBreakPlaceholderEvent.name,
        duration: differenceInMinutes(newDate, new SchedulingDate(event.startDate)),
      },
    });
  }
}

function isValidWorkingTime(
  newDate: Date,
  calendarId: string | null | undefined,
  isStartUpdated: boolean,
): boolean {
  const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();
  if (isStartUpdated) {
    return localOrderSchedulingEngine.utils.isStartDateDuringWorkingTime(
      newDate,
      calendarId ?? null,
    );
  }
  return localOrderSchedulingEngine.utils.isEndDateDuringWorkingTime(newDate, calendarId ?? null);
}
