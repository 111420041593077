<template>
  <Component
    :is="is"
    ref="buttonRef"
    :to="to"
    class="ds-button"
    :class="[
      `--${hierarchy} --${size} --icon-${icon}`,
      {
        '--destructive': destructive,
        '--semi-destructive': semiDestructive,
        '--full': full,
        '--force-background': forceBackground,
      },
    ]"
    :disabled="disabled || loading"
    :type="type"
    @keydown.esc="onEscape"
  >
    <template v-if="loading">
      <DsLoadingCircle size="16" />
    </template>
    <slot />
  </Component>
</template>

<script setup lang="ts">
import { PopoverButton } from '@headlessui/vue';
import { PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';

import AppLink from '@/ds-components/AppLink.vue';
import { blurActiveElement } from '@/ds-components/utils/blur';

const props = defineProps({
  hierarchy: {
    type: String as PropType<
      | 'primary'
      | 'primary-light'
      | 'secondary'
      | 'secondary-grey'
      | 'tertiary'
      | 'tertiary-grey'
      | 'link'
      | 'link-grey'
    >,
    default: 'primary',
  },
  destructive: { type: Boolean, default: false },
  semiDestructive: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  forceBackground: { type: Boolean, default: false },
  size: { type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'>, default: 'md' },
  full: { type: Boolean, default: false },
  type: { type: String as PropType<'button' | 'submit'>, default: 'button' },
  icon: { type: String as PropType<'default' | 'only'>, default: 'default' },
  loading: { type: Boolean, default: false },
  isLink: { type: Boolean, default: false }, // expects all required RouterLink props,
  to: { type: [Object, String] as PropType<RouteLocationRaw | undefined>, default: undefined },
  isPopoverTrigger: { type: Boolean, default: false },
});

const is = computed(() => {
  if (props.isPopoverTrigger) {
    return PopoverButton;
  }
  return props.isLink && Boolean(props.to) ? AppLink : 'button';
});

const onEscape = (event: KeyboardEvent) => {
  if (props.isPopoverTrigger) {
    return;
  }
  blurActiveElement(event);
};
</script>

<style>
.ds-button {
  @apply tw-inline-flex tw-items-center tw-justify-center tw-max-w-full tw-rounded-xs tw-whitespace-nowrap;
  @apply disabled:tw-cursor-default disabled:tw-opacity-50;
}
.ds-button.--xs {
  @apply tw-h-7 tw-px-[0.875rem] tw-gap-2 tw-ds-text-sm--semibold;
}
.ds-button.--sm {
  @apply tw-h-9 tw-px-[0.875rem] tw-gap-2 tw-ds-text-sm--semibold;
}
.ds-button.--sm.--icon-only {
  @apply tw-w-9 tw-px-0;
}
.ds-button.--md {
  @apply tw-h-10 tw-px-4 tw-gap-2 tw-ds-text-sm--semibold;
}
.ds-button.--md.--icon-only {
  @apply tw-w-10 tw-px-0;
}
.ds-button.--lg {
  @apply tw-h-11 tw-px-[1.125rem] tw-gap-2 tw-ds-text-md--semibold;
}
.ds-button.--lg.--icon-only {
  @apply tw-w-11 tw-px-0;
}
.ds-button.--xl {
  @apply tw-h-12 tw-px-5 tw-gap-2 tw-ds-text-md--semibold;
}
.ds-button.--xl.--icon-only {
  @apply tw-w-12 tw-px-0;
}
.ds-button.--2xl {
  @apply tw-h-[3.75rem] tw-px-7 tw-gap-3 tw-ds-text-lg--semibold;
}
.ds-button.--2xl.--icon-only {
  @apply tw-h-[3.5rem] tw-w-[3.5rem] tw-px-0;
}

.ds-button.--icon-only {
  @apply tw-flex-none;
}

.ds-button.--full {
  @apply tw-w-full;
}

.ds-button.--primary {
  @apply tw-bg-blue-500 tw-text-white;
}
.ds-button.--primary:not(:disabled) {
  @apply hover:tw-bg-blue-700;
  @apply active:tw-bg-blue-500;
  @apply focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-blue-50;
}
.ds-button.--primary.--destructive {
  @apply tw-bg-attention-500;
}
.ds-button.--primary.--destructive:not(:disabled) {
  @apply hover:tw-bg-attention-700;
  @apply active:tw-bg-attention-500;
  @apply focus-visible:tw-ring-attention-50;
}
.ds-button.--primary.--semi-destructive:not(:disabled) {
  @apply hover:tw-bg-attention-700;
}

.ds-button.--primary-light {
  @apply tw-bg-blue-25 tw-text-blue;
}
.ds-button.--primary-light:not(:disabled) {
  @apply hover:tw-bg-blue-100 hover:tw-text-blue-700;
  @apply active:tw-bg-blue-50;
  @apply focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-blue-50;
}
.ds-button.--primary-light.--destructive {
  @apply tw-bg-attention-25 tw-text-attention;
}
.ds-button.--primary-light.--destructive:not(:disabled) {
  @apply hover:tw-bg-attention-100 hover:tw-text-attention-700;
  @apply active:tw-bg-attention-500;
  @apply focus-visible:tw-ring-attention-50;
}
.ds-button.--primary-light.--semi-destructive:not(:disabled) {
  @apply hover:tw-bg-attention-100 hover:tw-text-attention-700;
}

.ds-button.--secondary {
  @apply tw-bg-white tw-text-blue-500 tw-border tw-border-solid tw-border-blue-500;
}
.ds-button.--secondary:not(:disabled) {
  @apply hover:tw-bg-blue-50;
  @apply active:tw-bg-white;
  @apply focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-blue-50;
}
.ds-button.--secondary:not(.--force-background) {
  @apply tw-mix-blend-multiply;
}
.ds-button.--secondary.--destructive {
  @apply tw-border tw-border-attention-500 tw-text-attention-500;
}
.ds-button.--secondary.--destructive:not(:disabled) {
  @apply hover:tw-bg-attention-50;
  @apply active:tw-bg-white;
  @apply focus-visible:tw-ring-attention-50;
}
.ds-button.--secondary.--semi-destructive:not(:disabled) {
  @apply hover:tw-border hover:tw-border-attention-500 hover:tw-text-attention-500 hover:tw-bg-attention-50;
}

.ds-button.--secondary-grey {
  @apply tw-border tw-border-solid tw-border-grey-100 tw-bg-white tw-text-grey;
}
.ds-button.--secondary-grey:not(:disabled) {
  @apply hover:tw-bg-secondary-bg-dark hover:tw-text-secondary;
  @apply active:tw-bg-secondary-bg-light;
  @apply focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-secondary-bg-dark;
}

.ds-button.--tertiary {
  @apply tw-bg-transparent tw-text-blue-500;
}
.ds-button.--tertiary:not(:disabled) {
  @apply hover:tw-bg-blue-50;
  @apply active:tw-bg-transparent;
  @apply focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-blue-50;
}
.ds-button.--tertiary:not(.--force-background) {
  @apply tw-mix-blend-multiply;
}
.ds-button.--tertiary.--destructive {
  @apply tw-text-attention-500;
}
.ds-button.--tertiary.--destructive:not(:disabled) {
  @apply hover:tw-bg-attention-50;
  @apply active:tw-bg-transparent;
  @apply focus-visible:tw-ring-attention-50;
}
.ds-button.--tertiary.--semi-destructive:not(:disabled) {
  @apply hover:tw-text-attention-500 hover:tw-bg-attention-50;
}

.ds-button.--tertiary-grey {
  @apply tw-bg-transparent tw-text-grey;
}
.ds-button.--tertiary-grey:not(:disabled) {
  @apply hover:tw-bg-secondary-bg-dark hover:tw-text-secondary;
  @apply active:tw-bg-secondary-bg-light;
  @apply focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-secondary-bg-dark;
}
.ds-button.--tertiary-grey.--destructive:not(:disabled) {
  @apply hover:tw-text-attention-500 hover:tw-bg-attention-50;
  @apply active:tw-bg-transparent;
  @apply focus-visible:tw-ring-attention-50;
}
.ds-button.--tertiary-grey.--semi-destructive:not(:disabled) {
  @apply hover:tw-text-attention-500 hover:tw-bg-attention-50;
}

.ds-button.--link,
.ds-button.--link-grey {
  @apply tw-h-auto tw-px-0 tw-bg-transparent;
}

.ds-button.--link.--destructive {
  @apply tw-text-attention-500;
}
.ds-button.--link.--destructive:not(:disabled) {
  @apply hover:tw-text-attention-700;
  @apply focus-visible:tw-text-attention-700;
}
.ds-button.--link.--semi-destructive:not(:disabled) {
  @apply hover:tw-text-attention-700;
}
.ds-button.--link-grey {
  @apply tw-text-cool-grey-500;
}
.ds-button.--link-grey:not(:disabled) {
  @apply hover:tw-text-secondary;
  @apply focus-visible:tw-outline-none focus-visible:tw-text-secondary;
}
</style>
