import { MultiPageExporter, PdfExportConfig } from '@bryntum/schedulerpro';

import { APP_ENV, APP_VERSION, BRYNTUM_AUTH_TOKEN, bryntumExportServer } from '@/utils/config';

export const TS_EXPORT_HEADER_HEIGHT = 140;

export function getPdfExportConfig(): Partial<PdfExportConfig> {
  return {
    exportServer: bryntumExportServer,
    // @ts-expect-error-next-line
    exporters: [MultiPageExporter],
    rowsRange: 'all',
    scheduleRange: 'daterange',
    keepRegionSizes: {
      locked: true,
    },
    showErrorToast: false,
    fetchOptions: {
      headers: {
        'app-version': APP_VERSION,
        stage: APP_ENV,
        Authorization: BRYNTUM_AUTH_TOKEN,
        'Content-Type': 'application/json',
      },
    },
  };
}
