import { OrderSchedulingEngine } from '@koppla-tech/scheduling-engine';

import { Entity, OrderDependencyEntity, OrderEntity, PartialEntity } from '@/common/types';
import { useOrderDependencyStore } from '@/features/orderDependencies';
import { useOrderStore } from '@/features/orders/orderStore';
import { OperationInputType } from '@/features/realTimeCollaboration/types';
import { StatusReport } from '@/helpers/orders/status';
import { NodeName } from '@/repositories/utils/cache';

export function convertOrderCreateOperationInput(
  engine: OrderSchedulingEngine,
  input: OperationInputType<'CreateOrders'>,
): OrderEntity[] {
  return input.map((o) => {
    const startAt = new SchedulingDate(o.startAt);
    const finishAt = new SchedulingDate(o.finishAt);
    return {
      __typename: NodeName.ORDER,
      id: o.id,
      name: o.name,
      startAt,
      finishAt,
      finishedAt: null,
      status: StatusReport.NOT_SET,
      progress: 0,
      wbsSection: { id: o.wbsSectionId },
      tenantTradeVariation: { id: o.tradeId },
      contributorGroup: o.contributorGroupId ? { id: o.contributorGroupId } : null,
      calendar: { id: o.calendarId },
      isFixed: o.isFixed ?? false,
      tradeSequenceActivity: null,
      tradeSequenceInstanceId: null,
      /**
       * Duration is not set for requests coming from the HTTP API.
       * This can lead to state divergence if the calendar changed.
       * We accept this for now because calendar changes and API requests are rare.
       */
      duration:
        o.workingTimeDuration ??
        engine.utils.computeWorkingTimeBetween(finishAt, startAt, o.calendarId),
      dryingBreak: o.dryingBreak,
    };
  });
}

export function convertOrderUpdateOperationInput(
  input: OperationInputType<'UpdateOrders'>,
): PartialEntity<OrderEntity>[] {
  return input.map((o) => ({
    id: o.id,
    ...(o.calendarId !== undefined ? { calendar: { id: o.calendarId! } } : {}),
    ...(o.name !== undefined ? { name: o.name! } : {}),
    ...(o.startAt !== undefined ? { startAt: new SchedulingDate(o.startAt!) } : {}),
    ...(o.finishAt !== undefined ? { finishAt: new SchedulingDate(o.finishAt!) } : {}),
    ...(o.wbsSectionId !== undefined ? { wbsSection: { id: o.wbsSectionId! } } : {}),
    ...(o.isFixed !== undefined ? { isFixed: o.isFixed! } : {}),
    ...(o.contributorGroupId !== undefined
      ? { contributorGroup: o.contributorGroupId ? { id: o.contributorGroupId } : null }
      : {}),
    ...(o.dryingBreak !== undefined ? { dryingBreak: o.dryingBreak } : {}),
    ...(o.tradeId ? { tenantTradeVariation: { id: o.tradeId } } : undefined),
    ...(o.workingTimeDuration != null ? { duration: o.workingTimeDuration } : {}),
  }));
}

export function convertOrderCopyOperationInput(input: OperationInputType<'CopyOrders'>): {
  copiedOrders: OrderEntity[];
  copiedDependencies: OrderDependencyEntity[];
} {
  const orderStore = useOrderStore();
  const orderDependencyStore = useOrderDependencyStore();

  const newOrderIdsToOriginalOrderIds = new Map<string, string>();

  const generatedOrders = input.orders.map<OrderEntity>((copiedOrder) => {
    const orderState = orderStore.tryGet(copiedOrder.originalOrderId);
    if (!orderState) {
      throw new Error(
        `Trying to copy an order (${copiedOrder.originalOrderId}) that does not exist.`,
      );
    }
    const { order: originalOrder, deleted } = orderState;
    if (deleted) {
      throw new Error(
        `Trying to copy an order  (${copiedOrder.originalOrderId}) that is deleted locally .`,
      );
    }
    newOrderIdsToOriginalOrderIds.set(copiedOrder.newId, copiedOrder.originalOrderId);
    return {
      id: copiedOrder.newId,
      calendar: { id: originalOrder.calendar.id },
      finishAt: new SchedulingDate(copiedOrder.finishAt),
      startAt: new SchedulingDate(copiedOrder.startAt),
      finishedAt: null,
      dryingBreak: copiedOrder.dryingBreak,
      isFixed: originalOrder.isFixed,
      name: originalOrder.name,
      progress: 0,
      status: StatusReport.NOT_SET,
      tradeSequenceInstanceId: null,
      tradeSequenceActivity: null,
      tenantTradeVariation: originalOrder.tenantTradeVariation,
      contributorGroup: originalOrder.contributorGroup,
      wbsSection: {
        id: copiedOrder.wbsSectionId!,
        __typename: NodeName.SECTION,
      },
    };
  });

  const generatedOrderDependencies = input.idMapping.dependencies
    .map<OrderDependencyEntity | null>((dependencyMapping) => {
      const originalFromId = newOrderIdsToOriginalOrderIds.get(dependencyMapping.fromOrderId);
      const originalToId = newOrderIdsToOriginalOrderIds.get(dependencyMapping.toOrderId);
      if (!originalFromId || !originalToId) return null;

      const originalOrderDependency = orderDependencyStore.getDependencyForRelation(
        originalFromId,
        originalToId,
      );
      if (!originalOrderDependency) return null;
      return {
        ...originalOrderDependency,
        id: dependencyMapping.dependencyId,
        from: { id: dependencyMapping.fromOrderId, __typename: NodeName.ORDER },
        to: { id: dependencyMapping.toOrderId, __typename: NodeName.ORDER },
      };
    })
    .filter(Boolean) as OrderDependencyEntity[];

  return {
    copiedOrders: generatedOrders,
    copiedDependencies: generatedOrderDependencies,
  };
}

export function convertOrderDeleteOperationInput(
  input: OperationInputType<'RemoveScheduleNodes'>,
): Entity[] {
  return input.orders?.map((id) => ({ id })) ?? [];
}
