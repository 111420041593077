import { gql } from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomScheduleViewFragment = gql`
  fragment CustomScheduleView on CustomScheduleViewNode {
    id
    name
    icon
    type
    position
    content {
      wbsSections
      tenantTradeVariations
      contributorGroups
      eventColorType
      hideDependencies
      range {
        absolute {
          from
          to
        }
        relative {
          last {
            amount
            unit
          }
          next {
            amount
            unit
          }
        }
      }
    }
  }
`;

export const userScheduleViewsQuery = gqlFn(/* GraphQL */ `
  query UserScheduleViews($project: ID!) {
    userScheduleViews(project: $project) {
      ...CustomScheduleView
    }
  }
`);

export const createCustomScheduleViewMutation = gqlFn(/* GraphQL */ `
  mutation CreateCustomScheduleView($input: CreateCustomScheduleViewInput!) {
    createCustomScheduleView(input: $input) {
      customScheduleView {
        ...CustomScheduleView
      }
    }
  }
`);

export const updateCustomScheduleViewMutation = gqlFn(/* GraphQL */ `
  mutation updateCustomScheduleView($input: UpdateCustomScheduleViewInput!) {
    updateCustomScheduleView(input: $input) {
      customScheduleView {
        ...CustomScheduleView
      }
    }
  }
`);

export const deleteCustomScheduleViewMutation = gqlFn(/* GraphQL */ `
  mutation DeleteCustomScheduleView($input: DeleteCustomScheduleViewInput!) {
    deleteCustomScheduleView(input: $input) {
      success
    }
  }
`);
