import { WbsSectionEntity } from '@/common/types';

/**
 * Computes the inverse selection of sections.
 *
 * - When creating or updating a custom schedule view, the backend stores hidden wbsSections
 *   based on the current filter, not the ones currently visible.
 * - When retrieving the custom schedule view, this function inverts the hidden sections
 *   to determine which sections should be visible, ensuring that newly created sections
 *   are automatically included in the filter.
 *
 * @param {string[]} sectionIds - The list of section IDs that are currently selected (hidden in the backend).
 * @param {WbsSectionEntity[]} sectionList - The full list of available WBS sections.
 * @returns {string[]} The list of section IDs that are **not** in the provided selection.
 */
export const getInverseSectionSelection = (
  sectionIds: string[],
  sectionList: WbsSectionEntity[],
): string[] => {
  const sectionSet = new Set(sectionIds);

  if (sectionSet.size === 0) return [];

  return sectionList.reduce((acc, section) => {
    if (!sectionSet.has(section.id)) {
      acc.push(section.id);
    }
    return acc;
  }, [] as string[]);
};
