import { defineStore } from 'pinia';

import { MilestoneEntity, OrderEntity, PauseEntity, WbsSectionEntity } from '@/common/types';
import { SchedulerEventColorType } from '@/services/store/schedule/types';

type ExportDateRange = {
  start: string;
  end: string;
} | null;

type ExportSettings = {
  advancedSettings: {
    // Gantt
    groupSequences: boolean;
    // PDF
    customHeadline: string;
    hideDependencies: boolean;
    hideWeekends: boolean;
    showCollisions: boolean;
    showTodayLine: boolean;
  };
  contributorGroups: string[];
  eventColorType: SchedulerEventColorType;
  range: ExportDateRange | null;
  sections: string[];
  trades: string[];
};

type OpenExportOptions = {
  milestones: MilestoneEntity[];
  orders: OrderEntity[];
  pauses: PauseEntity[];
  wbsSections: WbsSectionEntity[];
  settings?: {
    hideDependencies?: boolean;
    hideWeekends?: boolean;
    contributorGroups?: string[];
    eventColorType?: SchedulerEventColorType;
    sections?: string[];
    trades?: string[];
  };
};

const getInitialExportSettings = (): ExportSettings => ({
  advancedSettings: {
    // Gantt
    groupSequences: false,
    // PDF
    customHeadline: '',
    hideDependencies: false,
    hideWeekends: false,
    showCollisions: false,
    showTodayLine: false,
  },
  contributorGroups: [],
  eventColorType: SchedulerEventColorType.TRADE,
  range: null,
  sections: [],
  trades: [],
});

export const useScheduleExportStore = defineStore('schedule-export-store', () => {
  const exportDialogOpen = ref(false);

  const exportMilestones = ref<Map<string, MilestoneEntity>>(new Map());
  const exportOrders = ref<Map<string, OrderEntity>>(new Map());
  const exportPauses = ref<Map<string, PauseEntity>>(new Map());
  const exportWbsSections = ref<Map<string, WbsSectionEntity>>(new Map());

  const exportSettings = ref<ExportSettings>(getInitialExportSettings());

  const exportDateRange = ref<ExportDateRange>(null);

  const setExportDateRange = (dateRange: SchedulingDate[] | SchedulingDate | null) => {
    exportDateRange.value =
      Array.isArray(dateRange) && dateRange.length === 2
        ? { start: dateRange[0].toISOString(), end: dateRange[1].toISOString() }
        : null;
  };

  const openExport = ({ milestones, orders, pauses, wbsSections, settings }: OpenExportOptions) => {
    exportMilestones.value = new Map(milestones.map((milestone) => [milestone.id, milestone]));
    exportOrders.value = new Map(orders.map((order) => [order.id, order]));
    exportPauses.value = new Map(pauses.map((pause) => [pause.id, pause]));
    exportWbsSections.value = new Map(wbsSections.map((wbsSection) => [wbsSection.id, wbsSection]));

    if (settings) {
      exportSettings.value.advancedSettings.hideDependencies = settings?.hideDependencies ?? false;
      exportSettings.value.advancedSettings.hideWeekends = settings?.hideWeekends ?? false;
      exportSettings.value.contributorGroups = settings?.contributorGroups ?? [];
      exportSettings.value.eventColorType =
        settings?.eventColorType ?? SchedulerEventColorType.TRADE;
      exportSettings.value.sections = settings?.sections ?? [];
      exportSettings.value.trades = settings?.trades ?? [];
    }

    exportDialogOpen.value = true;
  };

  const closeExport = () => {
    reset();
  };

  const reset = () => {
    exportMilestones.value = new Map();
    exportOrders.value = new Map();
    exportPauses.value = new Map();
    exportWbsSections.value = new Map();
    exportDialogOpen.value = false;
    exportSettings.value = getInitialExportSettings();
  };

  return {
    exportDialogOpen,
    exportDateRange,
    setExportDateRange,
    openExport,
    closeExport,
  };
});
