import { DomClassList } from '@bryntum/schedulerpro';
import { addMinutes, differenceInMilliseconds, max, min } from 'date-fns';

import { OrderEntity, WbsSectionEntity } from '@/common/types';
import {
  BaseActualStatus,
  getBaseActualColor,
  useBasePlanStore,
  useBaseSection,
} from '@/features/basePlan';
import { getHexFromCssVariable } from '@/helpers/utils/colors';
import { NodeName } from '@/repositories/utils/cache';
import { schedulerClassConfig } from '@/services/store/schedule/parsers/base';
import {
  DurationUnit,
  SchedulerEvent,
  SchedulerEventAppearance,
  SchedulerEventColorType,
} from '@/services/store/schedule/types';

export class SectionSummaryEventParser {
  private static getAppearance(
    section: WbsSectionEntity,
    sectionOrders: OrderEntity[],
  ): Record<number, SchedulerEventAppearance> {
    const summaryStatus =
      useBaseSection(ref(section.id), sectionOrders).value?.status ?? BaseActualStatus.UNAVAILABLE;
    const stateColor = getBaseActualColor(summaryStatus);
    const hexColor = getHexFromCssVariable(stateColor);
    return {
      [SchedulerEventColorType.TRADE]: {
        cls: [],
        eventColor: getHexFromCssVariable('--color-secondary-dark'),
      },
      [SchedulerEventColorType.STATUS]: {
        cls: [],
        eventColor: getHexFromCssVariable('--color-secondary-dark'),
      },
      [SchedulerEventColorType.BASE_ACTUAL]: {
        cls: [],
        eventColor: hexColor,
      },
    };
  }

  public static sanitizeOrder(order: OrderEntity): OrderEntity & { durationMs: number } {
    const startAt = new SchedulingDate(order.startAt);
    // extend by the drying break if existent to extend the section summary bar
    const finishAt = addMinutes(
      new SchedulingDate(order.finishAt),
      order.dryingBreak?.duration ?? 0,
    );
    const durationMs = differenceInMilliseconds(finishAt, startAt);

    return { ...order, startAt, finishAt, durationMs };
  }

  public static entityToModel(
    section: WbsSectionEntity,
    sectionOrders: (OrderEntity & { durationMs: number })[],
  ): SchedulerEvent {
    const startDate = min(sectionOrders.map((order) => order.startAt));
    const endDate = max(sectionOrders.map((order) => order.finishAt));
    const duration = differenceInMilliseconds(endDate, startDate);
    // prevent potential division by zero because some orders can have a duration of 0
    const totalOrderDurationInMs = Math.max(
      1,
      sectionOrders.reduce((acc, order) => acc + order.durationMs, 0),
    );
    const percentDone = Math.floor(
      sectionOrders.reduce((acc, order) => {
        // reverse as the individual order percentages are calculated from 100 to 0
        const orderPercentDone = order.progress;
        const durationWeight = order.durationMs / totalOrderDurationInMs;
        return acc + orderPercentDone * durationWeight;
      }, 0),
    );

    return {
      appearance: this.getAppearance(section, sectionOrders),
      cls: new DomClassList([schedulerClassConfig[NodeName.SECTION]]),
      duration,
      durationUnit: DurationUnit.MILLISECOND,
      endDate,
      entity: NodeName.SECTION,
      eventStyle: null,
      id: section.id,
      isSelectable: false,
      name: '',
      percentDone,
      readOnly: true,
      resourceId: section.id,
      startDate,
      isFixed: false,
      collisionCounter: null,
    };
  }

  private static getSectionBasePlanSummary(
    section: WbsSectionEntity,
    sectionOrders: (OrderEntity & { durationMs: number })[],
  ): SchedulerEvent | null {
    const sectionBasePlan = useBasePlanStore().baseSections.get(section.id);

    if (!sectionBasePlan) return null;

    const startDate = new Date(sectionBasePlan.startAt);
    const endDate = new Date(sectionBasePlan.finishAt);
    const duration = differenceInMilliseconds(endDate, startDate);

    return {
      appearance: this.getAppearance(section, sectionOrders),
      cls: new DomClassList([schedulerClassConfig[NodeName.SECTION_BASEPLAN]]),
      duration,
      durationUnit: DurationUnit.MILLISECOND,
      endDate,
      entity: NodeName.SECTION_BASEPLAN,
      eventStyle: null,
      id: `${section.id}-baseplan`,
      isSelectable: false,
      name: '',
      readOnly: true,
      resourceId: section.id,
      startDate,
      isFixed: false,
      collisionCounter: null,
    };
  }

  public static generateSectionSummaryEvents(
    sections: WbsSectionEntity[],
    orders: OrderEntity[],
    includeBasePlan = false,
  ): Map<string, SchedulerEvent> {
    const sectionSummaryEvents = new Map<string, SchedulerEvent>();

    const ordersBySectionId: Map<string, (OrderEntity & { durationMs: number })[]> = new Map();

    orders.forEach((order) => {
      const sectionId = order.wbsSection?.id;
      if (sectionId) {
        if (!ordersBySectionId.has(sectionId)) {
          ordersBySectionId.set(sectionId, []);
        }
        ordersBySectionId.get(sectionId)!.push(this.sanitizeOrder(order));
      }
    });

    const childrenMap = new Map<string, WbsSectionEntity[]>();
    sections.forEach((section) => {
      const parentId = section.parentId;
      if (parentId) {
        if (!childrenMap.has(parentId)) {
          childrenMap.set(parentId, []);
        }
        childrenMap.get(parentId)!.push(section);
      }
    });

    const getDescendants = (
      section: WbsSectionEntity,
      childrenMap: Map<string, WbsSectionEntity[]>,
    ): WbsSectionEntity[] => {
      const children = childrenMap.get(section.id);
      if (!children?.length) return [];
      return children.flatMap((child) => [child, ...getDescendants(child, childrenMap)]);
    };

    sections.forEach((section) => {
      // no summary for bottom rows
      const children = childrenMap.get(section.id);
      if (!children?.length) return;

      const descendants = getDescendants(section, childrenMap);
      const sectionOrders = descendants.flatMap(
        (descendant) => ordersBySectionId.get(descendant.id) ?? [],
      );

      if (!sectionOrders.length) return;

      const normalEvent = this.entityToModel(section, sectionOrders);
      sectionSummaryEvents.set(normalEvent.id, normalEvent);

      if (includeBasePlan) {
        const basePlanEvent = this.getSectionBasePlanSummary(section, sectionOrders);
        if (basePlanEvent) {
          sectionSummaryEvents.set(basePlanEvent.id, basePlanEvent);
        }
      }
    });

    return sectionSummaryEvents;
  }
}
