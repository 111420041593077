import { onBeforeUnmount, onMounted } from 'vue';

import { isEnter, skipKeyboardEvent } from '@/helpers/utils/os';

const isButtonFocused = (event: KeyboardEvent): boolean => {
  return event.target instanceof HTMLElement && event.target.tagName === 'BUTTON';
};

export function useEnterListener(onEnter: () => void, isEnabledRef?: Ref<boolean>): void {
  const onKeydown = (event: KeyboardEvent): void => {
    if (
      !skipKeyboardEvent(event) &&
      !isButtonFocused(event) &&
      onEnter &&
      isEnter(event) &&
      (isEnabledRef === undefined || isEnabledRef.value)
    ) {
      event.stopPropagation();
      event.preventDefault();
      onEnter();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', onKeydown, false);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', onKeydown, false);
  });
}
