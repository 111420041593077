import { onBeforeMount, ref } from 'vue';

export enum OS {
  WIN = 'Windows',
  MAC = 'MacOS',
  LINUX = 'Linux',
  UNKNOWN = 'unknown',
}

/**
 * Returns the operating system of the user
 */
export function getOS(): OS {
  let os: OS = OS.UNKNOWN;

  if (navigator.userAgent.indexOf('Mac') !== -1) os = OS.MAC;
  if (navigator.userAgent.indexOf('Win') !== -1) os = OS.WIN;
  if (navigator.userAgent.indexOf('Linux') !== -1) os = OS.LINUX;

  return os;
}

export function isMacOS(): boolean {
  return getOS() === OS.MAC;
}

export enum KeyboardEventKey {
  SPACE = ' ',
  BACKSPACE = 'Backspace',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  A = 'a',
  C = 'c',
  DELETE = 'Delete',
  F = 'f',
  H = 'h',
  E = 'e',
  P = 'p',
  S = 's',
  ENTER = 'Enter',
  V = 'v',
  X = 'x',
  Y = 'y',
  Z = 'z',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_UP = 'ArrowUp',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_DOWN = 'ArrowDown',
  ESC = 'Escape',
}

export enum KeyboardEventCode {
  ONE = 'Digit1',
  TWO = 'Digit2',
  THREE = 'Digit3',
  FOUR = 'Digit4',
  FIVE = 'Digit5',
  SIX = 'Digit6',
  SEVEN = 'Digit7',
  EIGHT = 'Digit8',
  NINE = 'Digit9',
  ZERO = 'Digit0',
}

export function skipKeyboardEvent(event: KeyboardEvent): boolean {
  const activeElementTag = document.activeElement?.tagName.toLowerCase();
  const targetTag = (event.target as HTMLElement)?.tagName.toLowerCase();

  // don't handle keyboard events when an input has focus (e.g., because they have a CTRL+Z listener on their own)
  if (activeElementTag && ['input', 'textarea'].includes(activeElementTag)) return true;
  if (targetTag && ['input', 'textarea'].includes(targetTag)) return true;

  // don't handle keyboard event on elements with contenteditable=true (that act like inputs)
  if ((event.target as HTMLElement)?.isContentEditable) return true;

  return false;
}

export function checkModifierPressed(event: MouseEvent | KeyboardEvent): boolean {
  return event.shiftKey || event.altKey || event.ctrlKey || event.metaKey;
}

export function checkModifierExceptWinCtrlOrMacCmdPressed(
  event: MouseEvent | KeyboardEvent,
): boolean {
  return event.shiftKey || event.altKey || (isMacOS() ? event.ctrlKey : event.metaKey);
}

export function checkWinCtrlOrMacCmdKeyPressed(event: MouseEvent | KeyboardEvent): boolean {
  return isMacOS() ? event.metaKey : event.ctrlKey;
}

export function checkWinAltOrMacOptionPressed(event: KeyboardEvent): boolean {
  return event.altKey;
}

export function isTextSelected(): boolean {
  const selection = window.getSelection();
  return (selection && selection.toString().length > 0) || false;
}

export function isActiveElementButtonOrInput(): boolean {
  const activeElementTag = document.activeElement?.tagName.toLowerCase();
  const activeElementIsDsInput = document.activeElement?.classList.contains('ds-input') ?? false;
  return activeElementTag === 'button' || activeElementIsDsInput;
}

/**
 * Returns indicator, if keyboard event is copy short cut.
 *
 * CMD + C for MacOS, CTRL + C everywhere else
 */
export function isCopyShortcut(event: KeyboardEvent): boolean {
  if (checkModifierExceptWinCtrlOrMacCmdPressed(event)) return false;
  return event.key === KeyboardEventKey.C && checkWinCtrlOrMacCmdKeyPressed(event);
}

/**
 * Returns indicator, if keyboard event is paste short cut.
 *
 * CMD + V for MacOS, CTRL + V everywhere else
 */
export function isPasteShortcut(event: KeyboardEvent): boolean {
  if (checkModifierExceptWinCtrlOrMacCmdPressed(event)) return false;
  return event.key === KeyboardEventKey.V && checkWinCtrlOrMacCmdKeyPressed(event);
}

/**
 * Returns indicator, if keyboard event is cut short cut.
 *
 * CMD + X for MacOS, CTRL + X everywhere else
 */
export function isCutShortcut(event: KeyboardEvent): boolean {
  if (checkModifierExceptWinCtrlOrMacCmdPressed(event)) return false;
  return event.key === KeyboardEventKey.X && checkWinCtrlOrMacCmdKeyPressed(event);
}

/**
 * Returns indicator, if keyboard event is undo short cut.
 *
 * CMD + Z for MacOS, CTRL + Z everywhere else
 */
export function isUndoShortcut(event: KeyboardEvent): boolean {
  if (checkModifierExceptWinCtrlOrMacCmdPressed(event)) return false;
  return (
    event.key === KeyboardEventKey.Z && !event.shiftKey && checkWinCtrlOrMacCmdKeyPressed(event)
  );
}

/**
 * Returns indicator, if keyboard event is redo short cut.
 *
 * CMD + Y for MacOS, CTRL + Y everywhere else
 * or CMD + Z + shift for MacOS, CTRL + Z + shift everywhere else
 */
export function isRedoShortcut(event: KeyboardEvent): boolean {
  switch (event.key) {
    case KeyboardEventKey.Y:
      if (checkModifierExceptWinCtrlOrMacCmdPressed(event)) return false;
      return checkWinCtrlOrMacCmdKeyPressed(event);
    case KeyboardEventKey.Z:
      if (checkModifierExceptWinCtrlOrMacCmdPressed(event) && !event.shiftKey) return false;
      return event.shiftKey && checkWinCtrlOrMacCmdKeyPressed(event);
    default:
      return false;
  }
}

/**
 * Returns indicator, if keyboard event is delete short cut,
 * which is delete or backspace on each OS
 */
export function isDeleteShortcut(event: KeyboardEvent, requireShift = false): boolean {
  const isDeleteKey =
    event.key === KeyboardEventKey.BACKSPACE || event.key === KeyboardEventKey.DELETE;
  if (!requireShift) {
    if (checkModifierPressed(event) && event.shiftKey) return false;
    return isDeleteKey;
  }
  if (checkModifierPressed(event) && !event.shiftKey) return false;
  return isDeleteKey;
}

/**
 * Returns indicator, if keyboard event is edit short cut.
 */
export function isEditShortcut(event: KeyboardEvent): boolean {
  if (checkModifierPressed(event)) return false;
  return event.key === KeyboardEventKey.E;
}

export function isReportStatusShortcut(event: KeyboardEvent): boolean {
  if (checkModifierPressed(event)) return false;
  return event.key === KeyboardEventKey.S;
}

export function isSelectAllShortcut(event: KeyboardEvent): boolean {
  if (checkModifierExceptWinCtrlOrMacCmdPressed(event)) return false;
  return event.key === KeyboardEventKey.A && checkWinCtrlOrMacCmdKeyPressed(event);
}

export function isShowHistoryShortcut(event: KeyboardEvent): boolean {
  if (checkModifierPressed(event)) return false;
  return event.key === KeyboardEventKey.H;
}

export function isOpenExportMenuShortcut(event: KeyboardEvent): boolean {
  if (checkModifierPressed(event)) return false;
  return event.key === KeyboardEventKey.P;
}

/**
 * Returns indicator, if keyboard event is enter
 */
export function isEnter(event: KeyboardEvent): boolean {
  if (checkModifierPressed(event)) return false;
  return event.key === KeyboardEventKey.ENTER;
}

export function isEscape(event: KeyboardEvent): boolean {
  if (checkModifierPressed(event)) return false;
  return event.key === KeyboardEventKey.ESC;
}

export function isFilterShortcut(event: KeyboardEvent): boolean {
  if (checkModifierPressed(event)) return false;
  return event.key === KeyboardEventKey.F;
}

export function isColorByTradeShortcut(event: KeyboardEvent): boolean {
  if (checkModifierPressed(event)) return false;
  return event.key === KeyboardEventKey.ONE;
}

export function isColorByStatusShortcut(event: KeyboardEvent): boolean {
  if (checkModifierPressed(event)) return false;
  return event.key === KeyboardEventKey.TWO;
}

export function isColorByBaseActualShortcut(event: KeyboardEvent): boolean {
  if (checkModifierPressed(event)) return false;
  return event.key === KeyboardEventKey.THREE;
}

export function isJumpToTodayShortcut(event: KeyboardEvent): boolean {
  if (checkModifierPressed(event)) return false;
  return event.key === KeyboardEventKey.SPACE;
}

export function isScheduleViewShortcut(event: KeyboardEvent): boolean {
  return (
    checkWinAltOrMacOptionPressed(event) &&
    // We use code here instead of the key, because the "option" key modifies the value on Mac but not on Windows
    (event.code === KeyboardEventCode.ONE ||
      event.code === KeyboardEventCode.TWO ||
      event.code === KeyboardEventCode.THREE ||
      event.code === KeyboardEventCode.FOUR ||
      event.code === KeyboardEventCode.FIVE ||
      event.code === KeyboardEventCode.SIX ||
      event.code === KeyboardEventCode.SEVEN ||
      event.code === KeyboardEventCode.EIGHT ||
      event.code === KeyboardEventCode.NINE ||
      event.code === KeyboardEventCode.ZERO)
  );
}

export function useBrowserSupport() {
  const supportsES6 = (): boolean => {
    try {
      new Function('(a = 0) => a');
      return true;
    } catch {
      return false;
    }
  };
  /**
   * https://stackoverflow.com/a/21712356
   */
  const isInternetExplorer = (): boolean => {
    if ((window.document as Dictionary).documentMode) {
      return true;
    }
    return false;
  };
  const isSupported = (): boolean => {
    return !isInternetExplorer() && !!window.fetch && supportsES6();
  };

  const isBrowserSupported = ref(true);
  onBeforeMount(() => {
    isBrowserSupported.value = isSupported();
  });

  return { isBrowserSupported };
}
