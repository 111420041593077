import { Router } from 'vue-router';

import { useFeatureAccessStore } from '@/common/featureAccessStore';
import { useGlobalStore } from '@/common/globalStore';
import { useProjectStore } from '@/features/projects/projectStore';
import { useRTCController } from '@/features/realTimeCollaboration';
import { useScheduleViewStore } from '@/features/scheduleViews/scheduleViewStore';
import { ScheduleStore } from '@/services/store/schedule';
import {
  openObject,
  setScheduleLocale,
  updateViewPresets,
  watchProjectAddress,
} from '@/services/store/schedule/actions/initialize/base';
import { watchOrderDependencies } from '@/services/store/schedule/actions/initialize/dependencies';
import {
  setHolidays,
  watchCollisions,
  watchMilestones,
  watchOrders,
  watchPauses,
  watchSectionSummary,
  watchTrades,
} from '@/services/store/schedule/actions/initialize/events';
import {
  scrollToPersistedResource,
  watchSections,
} from '@/services/store/schedule/actions/initialize/resources';
import { watchScheduleContextMarker } from '@/services/store/schedule/actions/initialize/timeRanges';

export async function initialize(
  this: ScheduleStore,
  objectId: string,
  router: Router,
): Promise<void> {
  const globalStore = useGlobalStore();
  const featureAccessStore = useFeatureAccessStore();
  const rtcController = useRTCController();

  this.loading = true;

  const scheduleViewStore = useScheduleViewStore();

  // apply stored filter state
  if (scheduleViewStore.isFilterActive) {
    this.applyFilter();
  }

  this.tenantId = globalStore.currentTenantId!;
  this.projectId = globalStore.currentProjectId!;

  // update bryntum presets based on whether it's an hourly project or not
  const project = useProjectStore().currentProject;
  // if the user leaves the schedule shortly after entering it, the project may be null
  if (!project) return;
  setScheduleLocale(this.i18n);
  updateViewPresets(this.i18n, project.hourlyPlanningEnabled);

  const projectInitializationPromise = globalStore.projectInitializationPromise!;

  await projectInitializationPromise;

  // register watchers to populate bryntum entities based on other stores
  const entityInitializationPromises = [
    watchSections(this),
    // register watchers for orders and milestones before dependencies to ensure correct evaluation order
    watchOrders(this),
    watchMilestones(this),
    watchOrderDependencies(this),
    watchPauses(this),
    watchSectionSummary(this),
    watchTrades(this),
    watchCollisions(this),
  ];
  watchScheduleContextMarker(this);
  watchProjectAddress();

  return Promise.all([...entityInitializationPromises, rtcController.initializationPromise]).then(
    () => {
      this.loading = false;
      // add timeout to let watchers trigger first
      setTimeout(() => {
        scrollToPersistedResource();
        openObject(this, objectId, router);
        this.initialized = true;
      }, 100);

      const readOnly = computed(() => !featureAccessStore.hasWriteAccessToScheduleData);

      watch(
        [() => featureAccessStore.loading, readOnly],
        ([isStillLoading, isReadOnly]) => {
          if (!isStillLoading) {
            this.readonly = isReadOnly;
          }
        },
        { immediate: true },
      );
    },
  );
}

export { setHolidays };
