import { MilestoneStore } from '@/features/milestones';
import { MilestoneOverlapVisibilityToggled } from '@/features/milestones/analytics';
import { EVENT_LAYOUT_NONE, EVENT_LAYOUT_STACK } from '@/features/schedule/bryntum/resources/const';
import { LoggingService } from '@/interfaces/services';
import { ScheduleStore } from '@/services/store/schedule';
import { SchedulerResource } from '@/services/store/schedule/types';

export const checkAreEventsInResourceStacked = (resource: SchedulerResource) => {
  return resource.eventLayout === EVENT_LAYOUT_STACK;
};

export const stackEventsInResource = (
  resource: SchedulerResource,
  scheduleStore: ScheduleStore,
  loggingService: LoggingService | null = null,
) => {
  const entity = scheduleStore.entities.resources.get(resource.id);

  if (!entity) return;

  scheduleStore.entities.resources.set(entity.id, {
    ...entity,
    eventLayout: EVENT_LAYOUT_STACK,
    cls: getUpdatedResourceClassForLayout(resource, EVENT_LAYOUT_STACK),
  });

  loggingService?.trackEvent(
    new MilestoneOverlapVisibilityToggled({
      isExpanded: true,
    }),
  );
};

export const overlapEventsInResource = (
  resource: SchedulerResource,
  scheduleStore: ScheduleStore,
  loggingService: LoggingService | null = null,
) => {
  const entity = scheduleStore.entities.resources.get(resource.id);

  if (!entity) return;

  scheduleStore.entities.resources.set(entity.id, {
    ...entity,
    eventLayout: EVENT_LAYOUT_NONE,
    cls: getUpdatedResourceClassForLayout(resource, EVENT_LAYOUT_NONE),
  });

  loggingService?.trackEvent(
    new MilestoneOverlapVisibilityToggled({
      isExpanded: false,
    }),
  );
};

export const stackEventsInResourceIfMilestonesOverlapOnDate = (props: {
  resource: SchedulerResource;
  date: SchedulingDate;
  scheduleStore: ScheduleStore;
  milestoneStore: MilestoneStore;
  loggingService: LoggingService | null;
}) => {
  const { resource, date, scheduleStore, milestoneStore, loggingService } = props;

  const hasOverlappingMilestones = milestoneStore.checkDateHasOverlappingMilestones(date, resource);

  if (hasOverlappingMilestones) {
    stackEventsInResource(resource, scheduleStore, loggingService);
  }
};

export const getUpdatedResourceClassForLayout = (
  resource: SchedulerResource,
  layoutType: 'none' | 'stack' | 'pack' | 'mixed' | undefined,
) => {
  if (layoutType === EVENT_LAYOUT_STACK) {
    return `${resource.cls} show-row-pointer`;
  }

  if (layoutType === EVENT_LAYOUT_NONE) {
    return resource.cls?.replace('show-row-pointer', '');
  }

  return resource.cls;
};
