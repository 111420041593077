import {
  DomConfig,
  SchedulerAssignmentModel,
  SchedulerDependencyModel,
} from '@bryntum/schedulerpro';

import { useCollisionStore } from '@/features/collisions';
import { useScheduleViewStore } from '@/features/scheduleViews/scheduleViewStore';
import { DryingBreakEventParser } from '@/services/store/schedule/parsers';

interface DependencyRendererData {
  dependencyRecord: SchedulerDependencyModel;
  domConfig: DomConfig;
  fromAssignmentRecord: SchedulerAssignmentModel;
  toAssignmentRecord: SchedulerAssignmentModel;
}

export const getDependencyRenderer = (): ((data: DependencyRendererData) => void) => {
  const collisionStore = useCollisionStore();
  const scheduleViewStore = useScheduleViewStore();
  return ({ dependencyRecord, domConfig, fromAssignmentRecord, toAssignmentRecord }): void => {
    const fromId = DryingBreakEventParser.dryingBreakIdToOrderId(`${fromAssignmentRecord.eventId}`);
    if (domConfig.class) {
      domConfig.class['--violated'] = collisionStore.collisions.has(dependencyRecord.id.toString());
      domConfig.class['--highlighted'] =
        scheduleViewStore.eventToHighlightRelationsFor === fromId ||
        scheduleViewStore.eventToHighlightRelationsFor === toAssignmentRecord.eventId;
    }
  };
};
