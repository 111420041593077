import { AnalyticsBrowser } from '@segment/analytics-next';

import { AuthenticationService } from '@/interfaces/services';
import { AdditionalProperties } from '@/interfaces/services/logging';
import { AnalyticsEntity, AnalyticsEvent } from '@/utils/analyticsEvents/categories';
import { APP_VERSION } from '@/utils/config';
import { useFeatureToggles } from '@/utils/featureToggles';
import { SEGMENT_KEY } from '@/utils/keys';

export enum ProjectPermissionType {
  // values used for Segment tracking, don't change unless Segment values are expected to change
  Admin = 'tenant_admin',
  ProjectManagement = 'project_management',
  Internal = 'internal_contributor',
  External = 'external_contributor',
}

export interface AnalyticsLogger {
  trackEvent(event: AnalyticsEvent, additionals: AdditionalProperties): Promise<void>;
  setPage(
    title: string,
    companyId?: string,
    projectId?: string,
    additionals?: AdditionalProperties,
  ): Promise<void>;
  setUser(user: AnalyticsEntity<Dictionary & { hasMembership: boolean }>): Promise<void>;
  setTenantGroup(group: AnalyticsEntity): Promise<void>;
  setProjectGroup(group: AnalyticsEntity): Promise<void>;
  optIn(): void;
  reset(): void;
}

export class Segment implements AnalyticsLogger {
  private analytics: AnalyticsBrowser;

  private consentOnLogging = false;

  private companyId: string | undefined = undefined;

  private projectId: string | undefined = undefined;

  private hasMembership: boolean | undefined = undefined;

  private platform: 'Web - Browser' | 'Web - App';

  private authenticationService: AuthenticationService | undefined = undefined;

  public constructor(private appVersion = APP_VERSION) {
    this.analytics = AnalyticsBrowser.load({ writeKey: SEGMENT_KEY });
    this.platform = this.detectDisplayMode();
  }

  private getContext() {
    // anonymize the IP address of the user
    return { context: { ip: '0.0.0.0' } };
  }

  private detectDisplayMode(): 'Web - Browser' | 'Web - App' {
    const isStandalone =
      window.matchMedia('(display-mode: standalone)').matches ||
      ('standalone' in navigator && (navigator as Navigator & { standalone: boolean }).standalone);

    if (isStandalone) {
      return 'Web - App';
    }
    return 'Web - Browser';
  }

  private async isInternalUser(): Promise<boolean> {
    const ownUser = await this.authenticationService?.getUser();
    if (useFeatureToggles().ENABLE_PRODUCTION_LOGGING_ON_DEV) {
      return false;
    }
    return ownUser ? !!ownUser.email?.includes('@koppla') : false;
  }

  public setAuthenticationService(authService: AuthenticationService): void {
    this.authenticationService = authService;
  }

  public async trackEvent(
    event: AnalyticsEvent,
    additionalProperties: AdditionalProperties,
  ): Promise<void> {
    if (!this.consentOnLogging) return;

    const isInternalUser = await this.isInternalUser();
    if (isInternalUser) return;

    await this.analytics.track(
      event.identification,
      {
        ...event.properties,
        alternativeScheduleId: additionalProperties?.alternativeScheduleId,
        appVersion: this.appVersion,
        companyId: this.companyId,
        platform: this.platform,
        projectId: additionalProperties?.trackingProjectId ?? this.projectId,
        projectPermissionType: additionalProperties?.projectPermissionType,
        scheduleType: additionalProperties?.scheduleType,
      },
      this.getContext(),
    );
  }

  public async setPage(
    title: string,
    companyId?: string,
    projectId?: string,
    additionalProperties?: AdditionalProperties,
  ): Promise<void> {
    // set directly as this event fires before consent is set
    this.companyId = companyId;
    this.projectId = projectId;

    if (!this.consentOnLogging) return;

    const isInternalUser = await this.isInternalUser();
    if (isInternalUser) return;

    // Track page change, set the category arbitrarily to Webpage
    await this.analytics.page(
      'Webpage',
      title,
      {
        companyId,
        alternativeScheduleId: additionalProperties?.alternativeScheduleId,
        appVersion: this.appVersion,
        platform: this.platform,
        projectId: additionalProperties?.trackingProjectId ?? projectId,
        projectPermissionType: additionalProperties?.projectPermissionType,
        scheduleType: additionalProperties?.scheduleType,
      },
      this.getContext(),
    );
  }

  public async setUser(
    user: AnalyticsEntity<Dictionary & { hasMembership: boolean }>,
  ): Promise<void> {
    this.optIn();
    this.hasMembership = user.properties?.hasMembership;

    const isInternalUser = await this.isInternalUser();
    // Don't await as it never resolves
    this.analytics.identify(
      user.identification,
      {
        ...user.properties,
        isInternalUser,
        hasMembership: this.hasMembership,
      },
      this.getContext(),
    );
  }

  public async setTenantGroup(group: AnalyticsEntity): Promise<void> {
    if (!this.consentOnLogging) return;

    // Groups need to be identified regardless of whether it is an internal user or not, so the entries get created in Attio
    await this.analytics.group(
      group.identification,
      {
        ...group.properties,
      },
      this.getContext(),
    );
  }

  public async setProjectGroup(group: AnalyticsEntity): Promise<void> {
    if (!this.consentOnLogging) return;

    // Groups need to be identified regardless of whether it is an internal user or not, so the entries get created in Attio
    await this.analytics.group(
      group.identification,
      {
        ...group.properties,
      },
      this.getContext(),
    );
  }

  public optIn(): void {
    this.consentOnLogging = true;
  }

  public optOut(): void {
    this.consentOnLogging = false;
  }

  public reset(): void {
    this.optOut();
    this.analytics.reset();
  }
}
