import { ZoomPresetType } from '@/features/scheduleExport/pdfExport/utils/zoomPreset';
import { DateRangeObject } from '@/helpers/utils/dates';
import {
  SchedulerDependency,
  SchedulerEvent,
  SchedulerEventColorType,
  SchedulerRecurrentTimeRange,
  SchedulerResource,
} from '@/services/store/schedule/types';

import { SchedulerViewPreset } from '../../schedule/bryntum/presets';
import { PPI } from './const';

export enum DinPaperFormat {
  A0 = 'A0',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
}

export type CustomPaperFormat = { width: number; height: number };

export enum PageOrientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export enum DATE_RANGE_BUCKET {
  ONE_WEEK = 7,
  ONE_MONTH = 31,
  TWO_MONTHS = 62,
  QUARTER_YEAR = 92,
  HALF_YEAR = 183,
  YEAR = 366,
  TWO_YEAR = 732,
}

const DinPaperFormatLandscapeDimensionsInPx: Record<
  DinPaperFormat,
  { width: number; height: number }
> = {
  [DinPaperFormat.A0]: { width: Math.floor(46.8 * PPI), height: Math.floor(33.1 * PPI) },
  [DinPaperFormat.A1]: { width: Math.floor(33.1 * PPI), height: Math.floor(23.4 * PPI) },
  [DinPaperFormat.A2]: { width: Math.floor(23.4 * PPI), height: Math.floor(16.5 * PPI) },
  [DinPaperFormat.A3]: { width: Math.floor(16.5 * PPI), height: Math.floor(11.7 * PPI) },
  [DinPaperFormat.A4]: { width: Math.floor(11.7 * PPI), height: Math.floor(8.3 * PPI) },
};

const DinPaperFormatPortraitDimensionsInPx: Record<
  DinPaperFormat,
  { width: number; height: number }
> = {
  [DinPaperFormat.A0]: { width: Math.floor(33.1 * PPI), height: Math.floor(46.8 * PPI) },
  [DinPaperFormat.A1]: { width: Math.floor(23.4 * PPI), height: Math.floor(33.1 * PPI) },
  [DinPaperFormat.A2]: { width: Math.floor(16.5 * PPI), height: Math.floor(23.4 * PPI) },
  [DinPaperFormat.A3]: { width: Math.floor(11.7 * PPI), height: Math.floor(16.5 * PPI) },
  [DinPaperFormat.A4]: { width: Math.floor(8.3 * PPI), height: Math.floor(11.7 * PPI) },
};

export const DinPaperFormatDimensionsInPx: Record<
  PageOrientation,
  Record<DinPaperFormat, { width: number; height: number }>
> = {
  [PageOrientation.LANDSCAPE]: DinPaperFormatLandscapeDimensionsInPx,
  [PageOrientation.PORTRAIT]: DinPaperFormatPortraitDimensionsInPx,
};

export type ExportPage = { html: string };

export interface ExportConfig {
  resources: Map<string, SchedulerResource>;
  events: Map<string, SchedulerEvent>;
  timeRanges: Map<string, SchedulerRecurrentTimeRange>;
  dependencies: Map<string, SchedulerDependency>;
}

export interface ExportOptions {
  eventColorType: SchedulerEventColorType;
  exportRange: DateRangeObject | null;
  hideDependencies: boolean;
  logoSrc: string | null;
  orientation: PageOrientation;
  projectName: string;
  paperFormat: DinPaperFormat;
  resourceColumnWidth: number;
  splitterWidth: number;
  printMargin?: number;
  resourcesToOmit?: string[];
  getHeader?: (options: HeaderOptions, entities: ExportSchedulerEntities) => HeaderFooterReturn;
  getFooter?: (
    options: HeaderFooterOptions,
    entities: ExportSchedulerEntities,
  ) => HeaderFooterReturn;
}

export interface HeaderFooterOptions extends ExportOptions {
  width: number;
}

export interface HeaderOptions extends HeaderFooterOptions {
  totalPages?: number;
  currentPage?: number;
}

export interface HeaderTplOptions {
  currentPage: string | number;
  totalPages: string | number;
}

export type HeaderFooterReturn = {
  html: string;
  height: number;
};

// font size (11px) * n + rest to be divisible by 4
export enum MaxLinesOrderHeightThreshold {
  Two = 28,
  Three = 36,
  Five = 56,
}

export type SchedulerEventWithVirtualEndDate = SchedulerEvent & { virtualEndDate?: Date | null };

export interface ExportSchedulerEntities {
  events: SchedulerEvent[];
  resources: SchedulerResource[];
  dependencies: SchedulerDependency[];
  timeRanges: SchedulerRecurrentTimeRange[];
  startDate: Date;
  endDate: Date;
}

export interface DateRangeFormat extends ExportSchedulerEntities {
  barMargin: number;
  eventHeight: number;
  pageHeight: number;
  pageWidth: number;
  resourceMargin: number;
  rowHeight: number;
  scalingFactor: number;
  tickResolutionInDays: number;
  tickWidth: number;
  totalHeight: number;
  totalWidth: number;
  zoomPreset: SchedulerViewPreset;
  zoomPresetType: ZoomPresetType;
  numPagesXAxis?: number;
  numPagesYAxis?: number;
  numPagesTotal?: number;
}
