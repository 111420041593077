import { Composer } from 'vue-i18n';

import { ScheduleConstants } from '@/common/bryntum/constants';
import { sectionSelectors } from '@/common/selectors/section';
import { WbsSectionEntity } from '@/common/types';
import { isEmptySection } from '@/features/projectStructure/utils/emptyState';
import SectionDeleteConfirmationDialog from '@/features/sections/jsx/SectionDeleteConfirmationDialog';
import { OrderedTree, TreeNode } from '@/helpers/utils/orderedTree';
import Icon from '@/jsx/components/Icon';
import Tooltip from '@/jsx/components/Tooltip';
import { h } from '@/jsx/jsxFactory';
import { SchedulerEvent } from '@/services/store/schedule/types';

import {
  getAddTooltipText,
  getAppendixText,
  getButtonClass,
  getDeleteTooltipText,
  getDuplicateTooltipText,
  getIndentTooltipText,
  sectionIcons,
} from './Section.helpers';

type SectionToolbarProps = {
  addSection: (section: WbsSectionEntity) => void;
  copySection: (section: WbsSectionEntity) => void;
  deleteSection: (section: WbsSectionEntity) => void;
  hasScheduleSummaryEvents: boolean;
  hideSectionSummary?: () => void;
  i18n: Composer;
  indentSection: (section: WbsSectionEntity) => void;
  isReadOnly: boolean;
  hideActions: boolean;
  outdentSection: (section: WbsSectionEntity) => void;
  section: TreeNode<WbsSectionEntity>;
  sectionSummaryEvent: SchedulerEvent | undefined;
  sectionTree: OrderedTree<WbsSectionEntity>;
  shouldShowSectionSummary: boolean;
  showSectionSummary?: (section: WbsSectionEntity, event: MouseEvent) => void;
};

// eslint-disable-next-line complexity
export default function SectionToolbar({
  addSection,
  copySection,
  deleteSection,
  hasScheduleSummaryEvents,
  hideSectionSummary,
  i18n,
  indentSection,
  isReadOnly,
  hideActions,
  outdentSection,
  section,
  sectionSummaryEvent,
  sectionTree,
  shouldShowSectionSummary,
  showSectionSummary,
}: SectionToolbarProps): Element {
  const { t } = i18n;
  let dialog: Element | undefined;

  const sectionChildren = sectionTree.get(section.id)?.children ?? [];

  const canBeIndented = Boolean(!section.parentId || sectionChildren.length);
  const canBeOutdented = Boolean(section.parentId && sectionChildren.length);
  const canBeDeleted = Boolean(section.siblings.length > 1);
  const canShowSectionSummary = Boolean(
    shouldShowSectionSummary &&
      hasScheduleSummaryEvents &&
      (!!sectionSummaryEvent || (sectionChildren.length === 0 && !isEmptySection(section))),
  );

  const topLevels = sectionTree.rootEntities;
  const topLevelsRemaining = ScheduleConstants.MAX_TOP_LEVELS - sectionTree.rootEntities.length;
  const maxTopLevelsReached =
    !section.parentId && topLevels.length >= ScheduleConstants.MAX_TOP_LEVELS;
  const maxIndentationReached =
    !canBeIndented ||
    sectionTree.getMaxIndentationForSubtree(section.id) >= ScheduleConstants.MAX_ROW_INDENTATION;

  const tryOnDelete = () => {
    if (!isEmptySection(section)) {
      dialog = SectionDeleteConfirmationDialog({
        section,
        onCancel: () => {
          if (dialog) {
            document.body.removeChild(dialog);
          }
        },
        onConfirm: (section) => {
          if (dialog) {
            document.body.removeChild(dialog);
          }
          if (!section) return;
          deleteSection(section);
        },
        i18n: i18n,
      });
      if (dialog) {
        document.body.appendChild(dialog);
      }
      return;
    }
    deleteSection(section);
  };
  const showActions = !hideActions;

  return (
    <div
      id={`section-toolbar-${section.id}`}
      data-testid={sectionSelectors.toolbar.container}
      class={`section-teleporter-toolbar indent-${section.indentation}`}
    >
      {showActions && (
        <Tooltip
          position="bottom"
          disabled={false}
          content={[
            {
              text: getAddTooltipText(section, sectionChildren, maxTopLevelsReached, t),
              appendix: getAppendixText(section, maxTopLevelsReached, topLevelsRemaining, t),
            },
          ]}
        >
          <button
            type="button"
            class={getButtonClass(isReadOnly || maxTopLevelsReached)}
            tabindex="-1"
            data-testid={sectionSelectors.toolbar.addButton(section.id)}
            aria-label="Add Section Button"
            onClick={(event: MouseEvent) => {
              event.stopPropagation();
              if (!isReadOnly && !maxTopLevelsReached) addSection(section);
            }}
          >
            <Icon outlined icon="add" />
          </button>
        </Tooltip>
      )}

      {showActions && (
        <Tooltip
          position="bottom"
          disabled={false}
          content={[
            {
              text: getDuplicateTooltipText(section, sectionChildren, maxTopLevelsReached, t),
              appendix: getAppendixText(section, maxTopLevelsReached, topLevelsRemaining, t),
            },
          ]}
        >
          <button
            type="button"
            class={getButtonClass(isReadOnly || maxTopLevelsReached)}
            tabindex="-1"
            data-testid={sectionSelectors.toolbar.copyButton(section.id)}
            aria-label="Duplicate Section Button"
            onClick={(event: MouseEvent) => {
              event.stopPropagation();
              if (!isReadOnly && !maxTopLevelsReached) copySection(section);
            }}
          >
            <Icon outlined icon="content_copy" size={18} className="tw-m-auto" />
          </button>
        </Tooltip>
      )}
      {!!canBeIndented && showActions && (
        <Tooltip
          position="bottom"
          disabled={false}
          content={[{ text: getIndentTooltipText(maxIndentationReached, t) }]}
        >
          <button
            type="button"
            class={getButtonClass(isReadOnly || maxIndentationReached)}
            tabindex="-1"
            aria-label="Indent Section Button"
            data-testid={sectionSelectors.toolbar.indentButton(section.id)}
            onClick={(event: MouseEvent) => {
              event.stopPropagation();
              if (!isReadOnly && !maxIndentationReached) indentSection(section);
            }}
          >
            <Icon icon={sectionIcons.folder_plus} outlined svg />
          </button>
        </Tooltip>
      )}

      {!!canBeOutdented && showActions && (
        <Tooltip
          position="bottom"
          disabled={false}
          content={[{ text: i18n.t('objects.section.outdent') }]}
        >
          <button
            type="button"
            class={getButtonClass(isReadOnly)}
            tabindex="-1"
            aria-label="Outdent Section Button"
            data-testid={sectionSelectors.toolbar.outdentButton(section.id)}
            onClick={(event: MouseEvent) => {
              event.stopPropagation();
              if (!isReadOnly) outdentSection(section);
            }}
          >
            <Icon icon={sectionIcons.folder_minus} outlined svg />
          </button>
        </Tooltip>
      )}

      {showActions && (
        <Tooltip
          position="bottom"
          disabled={false}
          content={[{ text: getDeleteTooltipText(section, sectionChildren, canBeDeleted, t) }]}
        >
          <button
            type="button"
            class={getButtonClass(isReadOnly || !canBeDeleted, '--attention')}
            tabindex="-1"
            aria-label="Delete Section Button"
            data-testid={sectionSelectors.toolbar.deleteButton(section.id)}
            onClick={(event: MouseEvent) => {
              event.stopPropagation();
              if (!isReadOnly && canBeDeleted) tryOnDelete();
            }}
          >
            <Icon icon="delete" outlined />
          </button>
        </Tooltip>
      )}

      {!!canShowSectionSummary && (
        <button
          type="button"
          class="section-teleporter-toolbar-icon"
          tabindex="-1"
          aria-label="Show Section Summary Button"
          onClick={(event: MouseEvent) => {
            event.stopPropagation();
          }}
          onMouseenter={(event: MouseEvent) => showSectionSummary?.(section, event)}
          onMouseleave={() => hideSectionSummary?.()}
        >
          <Icon outlined={false} icon="info_outline" />
        </button>
      )}
    </div>
  );
}
