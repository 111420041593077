<template>
  <div class="project-planning-mode-banner">
    <DsTooltip
      v-slot="tooltipProps"
      :text="$t('objects.projectAlternatives.backToProject')"
      position="bottom-left"
    >
      <button
        v-bind="tooltipProps"
        class="tw-inline-flex tw-w-11 tw-h-11 tw-items-center tw-justify-center hover:tw-bg-[rgba(255,255,255,0.2)] tw-rounded-xs"
        type="button"
        :aria-label="$t('objects.projectAlternatives.backToProject')"
        @click="exitPlanningMode"
      >
        <DsSvgIcon icon="arrow-narrow-left" size="lg" />
      </button>
    </DsTooltip>
    <h4 class="project-planning-mode-banner-title">
      {{ $t('objects.projectAlternatives.title') }}
    </h4>
  </div>
</template>
<script setup lang="ts">
import { useProjectAlternativeStore } from '../store/projectAlternativeStore';

const alternativeStore = useProjectAlternativeStore();

const exitPlanningMode = () => {
  alternativeStore.exitPlanningMode();
};
</script>
<style scoped>
.project-planning-mode-banner {
  @apply tw-w-full tw-bg-attention-500 tw-text-white;
  @apply tw-flex tw-items-center tw-gap-4 tw-py-2;
}
.project-planning-mode-banner-title {
  @apply tw-ds-h4;
}
</style>
