<template>
  <FormModal
    :title="title"
    :description="subtitle"
    :submit-text="submitText || $t('actions.save')"
    :disabled="!isValid"
    horizontal
    :submit-test-id="projectTemplateSelectors.detailsModal.submit"
    :loading="loading"
    @cancel="emit('cancel')"
    @submit="submit"
  >
    <DsField
      v-model="name"
      :data-testid="projectTemplateSelectors.detailsModal.name"
      immediate-focus
      required
      :label="$t('general.name')"
    >
    </DsField>
    <DsField is="div" class="tw-mt-3" :label="$t('general.description')">
      <DsTextArea
        v-model="description"
        :data-testid="projectTemplateSelectors.detailsModal.description"
      />
    </DsField>
  </FormModal>
</template>

<script setup lang="ts">
import FormModal from '@/common/components/modals/FormModal.vue';
import { projectTemplateSelectors } from '@/common/selectors/projectTemplates';

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  submitText: {
    type: String as PropType<string | undefined>,
    default: undefined,
  },
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
});

const emit = defineEmits<{
  (ev: 'cancel'): void;
  (ev: 'submit', value: { name: string; description: string }): void;
}>();

const name = defineModel<string>('name', { default: '' });
const description = defineModel<string>('description', { default: '' });

const isValid = computed(() => {
  return name.value.length > 0;
});

const submit = () => {
  if (isValid.value) {
    emit('submit', { name: name.value, description: description.value });
  }
};
</script>
<style scoped></style>
