export const pageSelectors = {
  theHeader: {
    headline: 'page-the-header-headline',
  },
  dashboard: 'page-dashboard',
  photos: 'page-photos',
  participants: 'page-participants',
  tenantDetails: 'page-tenant-details',
  tenantTemplates: 'page-tenant-templates',
  tickets: 'page-tickets',
};
