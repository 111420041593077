<template>
  <textarea
    v-bind="$attrs"
    class="ds-input tw-px-[0.875rem] tw-py-3"
    :style="{ resize }"
    :class="{ '--destructive': destructive }"
    :rows="rows"
    :value="modelValue"
    :readonly="readonly"
    @input="onInput"
  ></textarea>
</template>

<script setup lang="ts">
import './input.css';

defineProps({
  destructive: { type: Boolean, default: false },
  modelValue: { type: String, default: '' },
  rows: { type: Number, default: 5 },
  readonly: { type: Boolean, default: false },
  resize: {
    type: String as PropType<'none' | 'horizontal' | 'vertical' | 'both'>,
    default: 'vertical',
  },
});
const emit = defineEmits<{
  (ev: 'update:model-value', value: string): void;
}>();

const onInput = (ev: Event) => {
  emit('update:model-value', (ev.target as HTMLInputElement).value);
};
</script>
