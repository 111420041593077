import kopplaLogo from '@/assets/media/img/koppla.svg?raw';
import { i18nInstance } from '@/plugins/i18n';

import { PdfCustomExportConfig } from '../../scheduleExport/types';

function getCenterPart(projectName: string | undefined, info: string): HTMLDivElement {
  const centerElement = document.createElement('div');
  centerElement.className = 'tw-flex tw-flex-col tw-items-center tw-col-start-2';

  const nameElement = document.createElement('div');
  nameElement.className = 'tw-ds-display-sm--medium';
  nameElement.appendChild(document.createTextNode(projectName ?? ''));

  const infoElement = document.createElement('div');
  infoElement.className = 'tw-ds-text-md';
  infoElement.appendChild(document.createTextNode(info));

  centerElement.appendChild(nameElement);
  centerElement.appendChild(infoElement);
  return centerElement;
}

function getLogoPart(
  logo: string | null = null,
  logoStyle: Partial<CSSStyleDeclaration> = {},
): HTMLDivElement {
  const logoElement = document.createElement('div');

  const defaultLogoStyle = {
    marginLeft: 'auto',
    marginRight: '2px',
    maxHeight: '60px',
    display: 'flex',
    alignItems: 'center',
  };

  Object.assign(logoElement.style, defaultLogoStyle, logoStyle);

  if (logo) {
    const image = document.createElement('img');
    image.src = logo;
    image.style.maxHeight = logoElement.style.maxHeight;
    logoElement.appendChild(image);
  } else {
    const logoTextElement = document.createElement('span');
    logoTextElement.style.marginRight = '12px';
    const logoText = i18nInstance.global.t('Calendar.export.pdf.exportedWith').toString();
    logoTextElement.appendChild(document.createTextNode(logoText));
    logoElement.appendChild(logoTextElement);

    const kopplaLogoEl = new DOMParser().parseFromString(kopplaLogo, 'image/svg+xml');
    kopplaLogoEl.documentElement.style.height = '20px';
    logoElement.appendChild(kopplaLogoEl.documentElement);
  }

  return logoElement;
}

export function getExportHeader(
  config: Pick<PdfCustomExportConfig, 'projectName' | 'tenantLogo'> & {
    headerHeight: number;
    logoStyle?: CSSStyleDeclaration;
  },
  info: string,
): string {
  const header = document.createElement('div');
  header.style.height = `${config.headerHeight}px`;
  header.className = 'tw-grid tw-grid-cols-[1fr_auto_1fr] tw-place-content-center tw-p-2.5';

  header.style.width = '100%';

  const centerElement = getCenterPart(config.projectName, info);
  const logoElement = getLogoPart(config.tenantLogo, config.logoStyle);
  header.appendChild(centerElement);
  header.appendChild(logoElement);
  return header.outerHTML;
}
