<template>
  <DsModal
    is="form"
    :horizontal="horizontal"
    :footer-alignment="footerAlignment"
    :z-index="zIndex"
    :data-testid="testId"
    :divide="divide"
    persist
    @close-request="cancel"
    @submit.prevent="submit"
  >
    <template #header-text>
      <slot name="title">
        {{ title }}
      </slot>
    </template>
    <template v-if="description || $slots['description']" #header-supporting-text>
      <slot name="description">
        {{ description }}
      </slot>
    </template>
    <template #header-actions>
      <slot name="header-actions" />
    </template>

    <template #default>
      <slot />
      <DsError v-if="error" class="tw-mt-6" :error="error" />
    </template>

    <template #actions>
      <template v-if="horizontal">
        <CancelButton :text="cancelText" :test-id="cancelTestId" @cancel="cancel" />
        <SubmitButton
          :text="submitText"
          :loading="loading"
          :disabled="disabled"
          :test-id="submitTestId"
          :destructive="destructive"
        />
      </template>
      <template v-else>
        <SubmitButton
          :text="submitText"
          :loading="loading"
          :disabled="disabled"
          :test-id="submitTestId"
          :destructive="destructive"
        />
        <CancelButton :text="cancelText" :test-id="cancelTestId" @cancel="cancel" />
      </template>
    </template>
  </DsModal>
</template>

<script setup lang="ts">
import CancelButton from '@/common/components/buttons/CancelButton.vue';
import SubmitButton from '@/common/components/buttons/SubmitButton.vue';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  error: {
    type: [Error, String] as PropType<Error | string | null>,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
  divide: {
    type: Boolean,
    default: false,
  },
  cancelText: {
    type: String,
    default: '',
  },
  submitText: {
    type: String,
    default: '',
  },
  zIndex: {
    type: Number,
    default: null,
  },
  testId: {
    type: String,
    default: '',
  },
  cancelTestId: {
    type: String,
    default: '',
  },
  submitTestId: {
    type: String,
    default: '',
  },
  destructive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (event: 'cancel'): void;
  (event: 'submit'): void;
}>();

const cancel = () => {
  emit('cancel');
};
const submit = () => {
  if (props.disabled || props.loading) return;
  emit('submit');
};

const footerAlignment = computed(() => (props.horizontal ? 'horizontal' : 'vertical'));
</script>
